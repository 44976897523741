import { useCohortStore } from "../store/cohort";

export function useCohort() {
  const store = useCohortStore();

  let currentAcademicYear = store.state.currentAcademicYear
  let currentAcademicSemester = store.state.currentAcademicSemester
  let currentApplicationYear = store.state.currentApplicationYear
  let currentApplicationMonth = store.state.currentApplicationMonth
  let pgcurrentApplicationYear = store.state.pgcurrentApplicationYear
  let pgcurrentApplicationMonth = store.state.pgcurrentApplicationMonth
  let  learningInstitution = store.state.learningInstitution
  let pglearningInstitution = store.state.pglearningInstitution

async function getCurrentAcademicYear() {
  try{
    await store.getCurrentAcademicYear();
  }catch(err){
    
  }
  return {
    currentAcademicYear: store.state.currentAcademicYear,
    currentAcademicSemester: store.state.currentAcademicSemester
  }
}

async function getCurrentAdmissionCohort() {
  try{
    await store.getCurrentAdmissionCohort();
  }catch(err){
    
  }
  return {
    currentApplicationYear: store.state.currentApplicationYear,
    currentApplicationMonth: store.state.currentApplicationMonth
  }
}

async function getpgCurrentAdmissionCohort() {
  try{
    await store.getpgCurrentAdmissionCohort();
  }catch(err){
    
  }
  return {
    pgcurrentApplicationYear: store.state.pgcurrentApplicationYear,
    pgcurrentApplicationMonth: store.state.pgcurrentApplicationMonth
  }
}

return {
  currentAcademicYear,
  currentAcademicSemester,
  currentApplicationYear,
  currentApplicationMonth,
  pgcurrentApplicationYear,
  pgcurrentApplicationMonth,
  learningInstitution,
  pglearningInstitution,
  getCurrentAcademicYear,
  getCurrentAdmissionCohort,
  getpgCurrentAdmissionCohort
}
}
