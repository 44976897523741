import {
  createRouter, createWebHashHistory
} from "vue-router";

import {
  useAuth
} from "src/composables/auth";

function passRouteToProps(route) {
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName,
		fieldValue: route.params.fieldValue,
	};
}

let routes = [
	//appconfig routes
	{
		path: "/appconfig/:fieldName?/:fieldValue?",
		name: "appconfiglist",
		component: () => import("./pages/appconfig/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/appconfig/add",
		name: "appconfigadd",
		component: () => import("./pages/appconfig/add.vue"),
		props: true,
	},
	{
		path: "/appconfig/edit/:id",
		name: "appconfigedit",
		component: () => import("./pages/appconfig/edit.vue"),
		props: true,
	},
	//admissionletters routes
	{
		path: "/admissionletters/:fieldName?/:fieldValue?",
		name: "admissionletterslist",
		component: () => import("./pages/admissionletters/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/admissionletters/add",
		name: "admissionlettersadd",
		component: () => import("./pages/admissionletters/add.vue"),
		props: true,
	},
	{
		path: "/admissionletters/edit/:id",
		name: "admissionlettersedit",
		component: () => import("./pages/admissionletters/edit.vue"),
		props: true,
	},
	//academicsessions routes
	{
		path: "/academicsessions/:fieldName?/:fieldValue?",
		name: "academicsessionslist",
		component: () => import("./pages/academicsessions/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/academicsessions/add",
		name: "academicsessionsadd",
		component: () => import("./pages/academicsessions/add.vue"),
		props: true,
	},
	//academicyears routes
	{
		path: "/academicyears/:fieldName?/:fieldValue?",
		name: "academicyearslist",
		component: () => import("./pages/academicyears/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/academicyears/add",
		name: "academicyearsadd",
		component: () => import("./pages/academicyears/add.vue"),
		props: true,
	},
	{
		path: "/academicyears/edit/:id",
		name: "academicyearsedit",
		component: () => import("./pages/academicyears/edit.vue"),
		props: true,
	},
  {
		path: "/academicyears/transitionsemester",
		name: "academicyearstransitionsemester",
		component: () => import("./pages/academicyears/transitionsemester.vue"),
		props: true,
	},
	//activationhistory routes
	{
		path: "/activationhistory/:fieldName?/:fieldValue?",
		name: "activationhistorylist",
		component: () => import("./pages/activationhistory/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/activationhistory/view/:id",
		name: "activationhistoryview",
		component: () => import("./pages/activationhistory/view.vue"),
		props: true,
	},
	//apps routes
	{
		path: "/apps/:fieldName?/:fieldValue?",
		name: "appslist",
		component: () => import("./pages/apps/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/apps/add",
		name: "appsadd",
		component: () => import("./pages/apps/add.vue"),
		props: true,
	},
	{
		path: "/apps/edit/:id",
		name: "appsedit",
		component: () => import("./pages/apps/edit.vue"),
		props: true,
	},
	//academiccalendar routes
	{
		path: "/academiccalendar/:fieldName?/:fieldValue?",
		name: "academiccalendarlist",
		component: () => import("./pages/academiccalendar/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/academiccalendar/add",
		name: "academiccalendaradd",
		component: () => import("./pages/academiccalendar/add.vue"),
		props: true,
	},
	{
		path: "/academiccalendar/edit/:id",
		name: "academiccalendaredit",
		component: () => import("./pages/academiccalendar/edit.vue"),
		props: true,
	},
	//academicevents routes
	{
		path: "/academicevents/:fieldName?/:fieldValue?",
		name: "academiceventslist",
		component: () => import("./pages/academicevents/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/academicevents/add",
		name: "academiceventsadd",
		component: () => import("./pages/academicevents/add.vue"),
		props: true,
	},
	{
		path: "/academicevents/edit/:id",
		name: "academiceventsedit",
		component: () => import("./pages/academicevents/edit.vue"),
		props: true,
	},
	//assigntolecturer routes
	{
		path: "/assigntolecturer/:fieldName?/:fieldValue?",
		name: "assigntolecturerlist",
		component: () => import("./pages/assigntolecturer/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/assigntolecturer/add",
		name: "assigntolectureradd",
		component: () => import("./pages/assigntolecturer/add.vue"),
		props: true,
	},
	{
		path: "/assigntolecturer/edit/:id",
		name: "assigntolectureredit",
		component: () => import("./pages/assigntolecturer/edit.vue"),
		props: true,
	},
  {
		path: "/assigntolecturer/view/:id",
		name: "assigntolecturerview",
		component: () => import("./pages/assigntolecturer/view.vue"),
		props: true,
	},
	//applicants routes
	{
		path: "/applicants/:fieldName?/:fieldValue?",
		name: "applicantslist",
		component: () => import("./pages/applicants/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/applicants/view/:id",
		name: "applicantsview",
		component: () => import("./pages/applicants/view.vue"),
		props: true,
	},
	{
		path: "/applicants/uncompleted",
		name: "applicantsuncompleted",
		component: () => import("./pages/applicants/uncompleted.vue"),
		props: true,
	},
	
	{
		path: "/applicants/accountcreation/:id",
		name: "applicantsedit",
		component: () => import("./pages/applicants/accountcreation.vue"),
		props: true,
	},
	//manualcourseregistration routes
	{
		path: "/manualcourseregistration/:fieldName?/:fieldValue?",
		name: "manualcourseregistrationlist",
		component: () => import("./pages/manualcourseregistration/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/manualcourseregistration/view/:id",
		name: "manualcourseregistrationview",
		component: () => import("./pages/manualcourseregistration/view.vue"),
		props: true,
	},

  //evaluationquestions routes
	{
		path: "/evaluationquestions/:fieldName?/:fieldValue?",
		name: "evaluationquestionslist",
		component: () => import("./pages/evaluationquestions/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/evaluationquestions/add",
		name: "evaluationquestionsadd",
		component: () => import("./pages/evaluationquestions/add.vue"),
		props: true,
	},
  {
		path: "/evaluationquestions/edit/:id",
		name: "evaluationquestionsedit",
		component: () => import("./pages/evaluationquestions/edit.vue"),
		props: true,
	},

  //evaluationquestionstypes routes
	{
		path: "/evaluationquestionstypes/:fieldName?/:fieldValue?",
		name: "evaluationquestionstypeslist",
		component: () => import("./pages/evaluationquestionstypes/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/evaluationquestionstypes/view/:id",
		name: "evaluationquestionstypesview",
		component: () => import("./pages/evaluationquestionstypes/view.vue"),
		props: true,
	},
	{
		path: "/evaluationquestionstypes/add",
		name: "evaluationquestionstypesadd",
		component: () => import("./pages/evaluationquestionstypes/add.vue"),
		props: true,
	},
	{
		path: "/evaluationquestionstypes/edit/:id",
		name: "evaluationquestionstypesedit",
		component: () => import("./pages/evaluationquestionstypes/edit.vue"),
		props: true,
	},

  //examination
  {
		path: "/examination/:fieldName?/:fieldValue?",
		name: "examinationlist",
		component: () => import("./pages/examination/list.vue"),
		props: (route) => passRouteToProps(route),
	},
  {
		path: "/examination/broadsheet/:fieldName?/:fieldValue?",
		name: "examinationbroadsheet",
		component: () => import("./pages/examination/broadsheet.vue"),
		props: (route) => passRouteToProps(route),
	},
  {
		path: "/examination/transcript/:fieldName?/:fieldValue?",
		name: "examinationtranscript",
		component: () => import("./pages/examination/transcript.vue"),
		props: (route) => passRouteToProps(route),
	},
  {
		path: "/examination/academicboardformat/:fieldName?/:fieldValue?",
		name: "examinationacademicboardformat",
		component: () => import("./pages/examination/academicboardformat.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/examination/scoresheet/:id",
		name: "eexaminationscoresheet",
		component: () => import("./pages/examination/scoresheet.vue"),
		props: true,
	},
  {
		path: "/examination/publish/:id",
		name: "examinationpublish",
		component: () => import("./pages/examination/publish.vue"),
		props: true,
	},
  {
		path: "/examination/publishsemester",
		name: "examinationpublishsemester",
		component: () => import("./pages/examination/publishsemester.vue"),
		props: true,
	},

  

//evaluations
{
  path: "/evaluations/:fieldName?/:fieldValue?",
  name: "evaluationslist",
  component: () => import("./pages/evaluations/list.vue"),
  props: (route) => passRouteToProps(route),
},
  ,{
		path: "/evaluations/view/:id",
		name: "evaluationsview",
		component: () => import("./pages/evaluations/view.vue"),
		props: true,
	},


	//buildings routes
	{
		path: "/buildings/:fieldName?/:fieldValue?",
		name: "buildingslist",
		component: () => import("./pages/buildings/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/buildings/add",
		name: "buildingsadd",
		component: () => import("./pages/buildings/add.vue"),
		props: true,
	},
	{
		path: "/buildings/edit/:id",
		name: "buildingsedit",
		component: () => import("./pages/buildings/edit.vue"),
		props: true,
	},
	//currencyrates routes
	{
		path: "/currencyrates/:fieldName?/:fieldValue?",
		name: "currencyrateslist",
		component: () => import("./pages/currencyrates/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/currencyrates/add",
		name: "currencyratesadd",
		component: () => import("./pages/currencyrates/add.vue"),
		props: true,
	},
	{
		path: "/currencyrates/edit/:id",
		name: "currencyratesedit",
		component: () => import("./pages/currencyrates/edit.vue"),
		props: true,
	},
	//campuses routes
	{
		path: "/campuses/:fieldName?/:fieldValue?",
		name: "campuseslist",
		component: () => import("./pages/campuses/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/campuses/add",
		name: "campusesadd",
		component: () => import("./pages/campuses/add.vue"),
		props: true,
	},
	{
		path: "/campuses/edit/:id",
		name: "campusesedit",
		component: () => import("./pages/campuses/edit.vue"),
		props: true,
	},
	//courses routes
	{
		path: "/courses/:fieldName?/:fieldValue?",
		name: "courseslist",
		component: () => import("./pages/courses/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/courses/view/:id",
		name: "coursesview",
		component: () => import("./pages/courses/view.vue"),
		props: true,
	},
	{
		path: "/courses/add",
		name: "coursesadd",
		component: () => import("./pages/courses/add.vue"),
		props: true,
	},
	{
		path: "/courses/edit/:id",
		name: "coursesedit",
		component: () => import("./pages/courses/edit.vue"),
		props: true,
	},

  //audits routes
	{
		path: "/audits/:fieldName?/:fieldValue?",
		name: "auditslist",
		component: () => import("./pages/audits/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/audits/view/:id",
		name: "auditsview",
		component: () => import("./pages/audits/view.vue"),
		props: true,
	},



	//pages routes
	{
		path: "/pages/:fieldName?/:fieldValue?",
		name: "pageslist",
		component: () => import("./pages/pages/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/pages/add",
		name: "pagesadd",
		component: () => import("./pages/pages/add.vue"),
		props: true,
	},
	{
		path: "/pages/edit/:id",
		name: "pagesedit",
		component: () => import("./pages/pages/edit.vue"),
		props: true,
	},
	//permissions routes
	{
		path: "/permissions/:fieldName?/:fieldValue?",
		name: "permissionslist",
		component: () => import("./pages/permissions/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/permissions/add",
		name: "permissionsadd",
		component: () => import("./pages/permissions/add.vue"),
		props: true,
	},
	//roles routes
	{
		path: "/roles/:fieldName?/:fieldValue?",
		name: "roleslist",
		component: () => import("./pages/roles/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/roles/view/:id",
		name: "rolesview",
		component: () => import("./pages/roles/view.vue"),
		props: true,
	},
	{
		path: "/roles/add",
		name: "rolesadd",
		component: () => import("./pages/roles/add.vue"),
		props: true,
	},
	{
		path: "/roles/edit/:id",
		name: "rolesedit",
		component: () => import("./pages/roles/edit.vue"),
		props: true,
	},
	{
		path: "/roles/permissions/:id",
		name: "rolespermissions",
		component: () => import("./pages/roles/permissions.vue"),
		props: true,
	},
	//users routes
	{
		path: "/users/:fieldName?/:fieldValue?",
		name: "userslist",
		component: () => import("./pages/users/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/users/view/:id",
		name: "usersview",
		component: () => import("./pages/users/view.vue"),
		props: true,
	},
	{
		path: "/account/edit",
		name: "usersaccountedit",
		component: () => import("./pages/account/accountedit.vue"),
		props: true,
	},
	{
		path: "/account",
		name: "usersaccountview",
		component: () => import("./pages/account/accountview.vue"),
		props: true,
	},
	{
		path: "/users/add",
		name: "usersadd",
		component: () => import("./pages/users/add.vue"),
		props: true,
	},
	{
		path: "/users/edit/:id",
		name: "usersedit",
		component: () => import("./pages/users/edit.vue"),
		props: true,
	},
	{
		path: "/users/resetpassword/:id",
		name: "usersresetpassword",
		component: () => import("./pages/users/resetpassword.vue"),
		props: true,
	},
	//usersappsaccess routes
	{
		path: "/usersappsaccess/:fieldName?/:fieldValue?",
		name: "usersappsaccesslist",
		component: () => import("./pages/usersappsaccess/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/usersappsaccess/add",
		name: "usersappsaccessadd",
		component: () => import("./pages/usersappsaccess/add.vue"),
		props: true,
	},
	{
		path: "/usersappsaccess/:fieldName?/:fieldValue?",
		name: "usersappsaccessuserlist",
		component: () => import("./pages/usersappsaccess/userlist.vue"),
		props: (route) => passRouteToProps(route),
	},
	//documentmanagers routes
	{
		path: "/documentmanagers/:fieldName?/:fieldValue?",
		name: "documentmanagerslist",
		component: () => import("./pages/documentmanagers/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/documentmanagers/add",
		name: "documentmanagersadd",
		component: () => import("./pages/documentmanagers/add.vue"),
		props: true,
	},
	//documentrequests routes
	{
		path: "/documentrequests/:fieldName?/:fieldValue?",
		name: "documentrequestslist",
		component: () => import("./pages/documentrequests/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/documentrequests/view/:id",
		name: "documentrequestsview",
		component: () => import("./pages/documentrequests/view.vue"),
		props: true,
	},
	{
		path: "/documentrequests/add",
		name: "documentrequestsadd",
		component: () => import("./pages/documentrequests/add.vue"),
		props: true,
	},
	{
		path: "/documentrequests/edit/:id",
		name: "documentrequestsedit",
		component: () => import("./pages/documentrequests/edit.vue"),
		props: true,
	},
	//documenttemplates routes
	{
		path: "/documenttemplates/:fieldName?/:fieldValue?",
		name: "documenttemplateslist",
		component: () => import("./pages/documenttemplates/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/documenttemplates/add",
		name: "documenttemplatesadd",
		component: () => import("./pages/documenttemplates/add.vue"),
		props: true,
	},
	{
		path: "/documenttemplates/edit/:id",
		name: "documenttemplatesedit",
		component: () => import("./pages/documenttemplates/edit.vue"),
		props: true,
	},
	//coursesenrolment routes
	{
		path: "/coursesenrolment/:fieldName?/:fieldValue?",
		name: "coursesenrolmentlist",
		component: () => import("./pages/coursesenrolment/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/coursesenrolment/view/:id",
		name: "coursesenrolmentview",
		component: () => import("./pages/coursesenrolment/view.vue"),
		props: true,
	},
	{
		path: "/coursesenrolment/add",
		name: "coursesenrolmentadd",
		component: () => import("./pages/coursesenrolment/add.vue"),
		props: true,
	},
	{
		path: "/coursesenrolment/edit/:id",
		name: "coursesenrolmentedit",
		component: () => import("./pages/coursesenrolment/edit.vue"),
		props: true,
	},
	//examscourses routes
	{
		path: '/examscourses/:fieldName?/:fieldValue?',
		name: 'examscourseslist',
		component: () => import('./pages/examscourses/list.vue'),
		props: route => passRouteToProps(route)
	},
	{
		path: '/examscourses/view/:id',
		name: 'examscoursesview',
		component: () => import('./pages/examscourses/view.vue'),
		props: true
	},
	{
		path: '/examscourses/add',
		name: 'examscoursesadd',
		component: () => import('./pages/examscourses/add.vue'),
		props: true
	},
	{
		path: '/examscourses/edit/:id',
		name: 'examscoursesedit',
		component: () => import('./pages/examscourses/edit.vue'),
		props: true
	},
	//examshalls routes
	{
		path: '/examshalls/:fieldName?/:fieldValue?',
		name: 'examshallslist',
		component: () => import('./pages/examshalls/list.vue'),
		props: route => passRouteToProps(route)
	},
	{
		path: '/examshalls/view/:id',
		name: 'examshallsview',
		component: () => import('./pages/examshalls/view.vue'),
		props: true
	},
	{
		path: '/examshalls/add',
		name: 'examshallsadd',
		component: () => import('./pages/examshalls/add.vue'),
		props: true
	},
	{
		path: '/examshalls/edit/:id',
		name: 'examshallsedit',
		component: () => import('./pages/examshalls/edit.vue'),
		props: true
	},
	//examsschedule routes
	{
		path: '/examsschedule/:fieldName?/:fieldValue?',
		name: 'examsschedulelist',
		component: () => import('./pages/examsschedule/list.vue'),
		props: route => passRouteToProps(route)
	},
	{
		path: '/examsschedule/view/:id',
		name: 'examsscheduleview',
		component: () => import('./pages/examsschedule/view.vue'),
		props: true
	},
	{
		path: '/examsschedule/add',
		name: 'examsscheduleadd',
		component: () => import('./pages/examsschedule/add.vue'),
		props: true
	},
	{
		path: '/examsschedule/edit/:id',
		name: 'examsscheduleedit',
		component: () => import('./pages/examsschedule/edit.vue'),
		props: true
	},
	//examstimetable routes
	{
		path: '/examstimetable/:fieldName?/:fieldValue?',
		name: 'examstimetablelist',
		component: () => import('./pages/examstimetable/list.vue'), 
		props: route => passRouteToProps(route)
	},
	{ 
		path: '/examstimetable/view/:id', 
		name: 'examstimetableview', 
		component: () => import('./pages/examstimetable/view.vue'), 
		props: true
	},
	{ 
		path: '/examstimetable/add', 
		name: 'examstimetableadd', 
		component: () => import('./pages/examstimetable/add.vue'), 
		props: true
	},
	{ 
		path: '/examstimetable/edit/:id', 
		name: 'examstimetableedit', 
		component: () => import('./pages/examstimetable/edit.vue'), 
		props: true
	},
	//studentsstats routes
	{
		path: "/studentsstats/:fieldName?/:fieldValue?",
		name: "studentsstatslist",
		component: () => import("./pages/studentsstats/list.vue"),
		props: (route) => passRouteToProps(route),
	},

  //studentalerts routes
	{
		path: "/studentalerts/:fieldName?/:fieldValue?",
		name: "studentalertslist",
		component: () => import("./pages/studentalerts/list.vue"),
		props: (route) => passRouteToProps(route),
	},

	{
		path: "/studentalerts/add",
		name: "studentalertsadd",
		component: () => import("./pages/studentalerts/add.vue"),
		props: true,
	},
	{
		path: "/studentalerts/edit/:id",
		name: "studentalertsedit",
		component: () => import("./pages/studentalerts/edit.vue"),
		props: true,
	},
	//fees routes
	{
		path: "/fees/:fieldName?/:fieldValue?",
		name: "feeslist",
		component: () => import("./pages/fees/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/fees/add",
		name: "feesadd",
		component: () => import("./pages/fees/add.vue"),
		props: true,
	},
	{
		path: "/fees/edit/:id",
		name: "feesedit",
		component: () => import("./pages/fees/edit.vue"),
		props: true,
	},

	//applicationfees routes
	{
		path: "/applicationfees/:fieldName?/:fieldValue?",
		name: "applicationfeeslist",
		component: () => import("./pages/applicationfees/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/applicationfees/add",
		name: "applicationfeesadd",
		component: () => import("./pages/applicationfees/add.vue"),
		props: true,
	},
	{
		path: "/applicationfees/edit/:id",
		name: "applicationfeesedit",
		component: () => import("./pages/applicationfees/edit.vue"),
		props: true,
	},
	//studentclearance routes
	{
		path: "/studentclearance/:fieldName?/:fieldValue?",
		name: "studentclearancelist",
		component: () => import("./pages/studentclearance/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/studentclearance/view/:id",
		name: "studentclearanceview",
		component: () => import("./pages/studentclearance/view.vue"),
		props: true,
	},
	{
		path: "/studentclearance/add",
		name: "studentclearanceadd",
		component: () => import("./pages/studentclearance/add.vue"),
		props: true,
	},
	{
		path: "/studentclearance/edit/:id",
		name: "studentclearanceedit",
		component: () => import("./pages/studentclearance/edit.vue"),
		props: true,
	},
	//studenttimetable routes
	{
		path: "/studenttimetable/:fieldName?/:fieldValue?",
		name: "studenttimetablelist",
		component: () => import("./pages/studenttimetable/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/studenttimetable/add",
		name: "studenttimetableadd",
		component: () => import("./pages/studenttimetable/add.vue"),
		props: true,
	},
	{
		path: "/studenttimetable/view/:id",
		name: "studenttimetableview",
		component: () => import("./pages/studenttimetable/view.vue"),
		props: true,
	},
	{
		path: "/studenttimetable/edit/:id",
		name: "studenttimetableedit",
		component: () => import("./pages/studenttimetable/edit.vue"),
		props: true,
	},
	//students routes
	{
		path: "/students/:fieldName?/:fieldValue?",
		name: "studentslist",
		component: () => import("./pages/students/list.vue"),
		props: (route) => passRouteToProps(route),
	},
  {
		path: "/students/updateresultstatus/:fieldName?/:fieldValue?",
		name: "studentsupdateresultstatus",
		component: () => import("./pages/students/updateresultstatus.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/students/unregistered/:fieldName?/:fieldValue?",
		name: "unregisteredstudentslist", 
		component: () => import("./pages/students/unregistered.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/students/registered/:fieldName?/:fieldValue?",
		name: "registeredstudentslist", 
		component: () => import("./pages/students/registered.vue"),
		props: (route) => passRouteToProps(route),
	},
  {
		path: "/students/changeprogrammelist/:fieldName?/:fieldValue?",
		name: "changeprogrammelist", 
		component: () => import("./pages/students/changeprogrammelist.vue"),
		props: (route) => passRouteToProps(route),
	},
  
  {
		path: "/students/changeprogrammeview/:id",
		name: "studentschangeprogrammeview",
		component: () => import("./pages/students/changeprogrammeview.vue"),
		props: true,
	},
  {
		path: "/students/updateresultstatus",
		name: "studentsupdateresultstatusview",
		component: () => import("./pages/students/updateresultstatus.vue"),
		props: true,
	},
  
	{
		path: "/students/view/:id",
		name: "studentsview",
		component: () => import("./pages/students/view.vue"),
		props: true,
	},
  {
		path: "/students/results/:id",
		name: "studentresults",
		component: () => import("./pages/students/results.vue"),
		props: true,
	},
	{
		path: "/students/finances/:id",
		name: "studentsfinances",
		component: () => import("./pages/students/finances.vue"),
		props: true,
	},
	
	{
		path: "/students/add",
		name: "studentsadd",
		component: () => import("./pages/students/add.vue"),
		props: true,
	},
	{
		path: "/students/activateenrollment/id",
		name: "studentsactivateenrollment",
		component: () => import("./pages/students/activateenrollment.vue"),
		props: true,
	},
	{
		path: "/students/deactivateenrollment/id",
		name: "studentsdeactivateenrollment",
		component: () => import("./pages/students/deactivateenrollment.vue"),
		props: true,
	},
	{
		path: "/students/edit/:id",
		name: "studentsedit",
		component: () => import("./pages/students/edit.vue"),
		props: true,
	},
  {
		path: "/students/changeprogramme/:id",
		name: "studentschangeprogramme",
		component: () => import("./pages/students/changeprogramme.vue"),
		props: true,
	},
  
	{
		path: "/students/resetpassword/:id",
		name: "studentsresetpassword",
		component: () => import("./pages/students/resetpassword.vue"),
		props: true,
	},
	{
		path: "/students/activation/:id",
		name: "studentsactivation",
		component: () => import("./pages/students/activation.vue"),
		props: true,
	},
	{
		path: "/students/pgadd",
		name: "studentspgadd",
		component: () => import("./pages/students/pgadd.vue"),
		props: true,
	},
	//departments routes
	{
		path: "/departments/:fieldName?/:fieldValue?",
		name: "departmentslist",
		component: () => import("./pages/departments/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/departments/view/:id",
		name: "departmentsview",
		component: () => import("./pages/departments/view.vue"),
		props: true,
	},
	{
		path: "/departments/add",
		name: "departmentsadd",
		component: () => import("./pages/departments/add.vue"),
		props: true,
	},
	{
		path: "/departments/edit/:id",
		name: "departmentsedit",
		component: () => import("./pages/departments/edit.vue"),
		props: true,
	},
	//faculties routes
	{
		path: "/faculties/:fieldName?/:fieldValue?",
		name: "facultieslist",
		component: () => import("./pages/faculties/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/faculties/view/:id",
		name: "facultiesview",
		component: () => import("./pages/faculties/view.vue"),
		props: true,
	},
	{
		path: "/faculties/add",
		name: "facultiesadd",
		component: () => import("./pages/faculties/add.vue"),
		props: true,
	},
	{
		path: "/faculties/edit/:id",
		name: "facultiesedit",
		component: () => import("./pages/faculties/edit.vue"),
		props: true,
	},
	//programmes routes
	{
		path: "/programmes/:fieldName?/:fieldValue?",
		name: "programmeslist",
		component: () => import("./pages/programmes/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/programmes/view/:id",
		name: "programmesview",
		component: () => import("./pages/programmes/view.vue"),
		props: true,
	},
	{
		path: "/programmes/add",
		name: "programmesadd",
		component: () => import("./pages/programmes/add.vue"),
		props: true,
	},
	{
		path: "/programmes/edit/:id",
		name: "programmesedit",
		component: () => import("./pages/programmes/edit.vue"),
		props: true,
	},

	//payments report routes
	{
		path: "/paymentreport/:fieldName?/:fieldValue?",
		name: "paymentreportlist",
		component: () => import("./pages/paymentreport/list.vue"),
		props: (route) => passRouteToProps(route),
	},



	//payments routes
	{
		path: "/payments/:fieldName?/:fieldValue?",
		name: "paymentslist",
		component: () => import("./pages/payments/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/payments/view/:id",
		name: "paymentsview",
		component: () => import("./pages/payments/view.vue"),
		props: true,
	},
	{
		path: "/payments/add",
		name: "paymentsadd",
		component: () => import("./pages/payments/add.vue"),
		props: true,
	},
	{
		path: "/payments/edit/:id",
		name: "paymentsedit",
		component: () => import("./pages/payments/edit.vue"),
		props: true,
	},
	//bills routes
	{
		path: "/bills/:fieldName?/:fieldValue?",
		name: "billslist",
		component: () => import("./pages/bills/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/bills/view/:id",
		name: "billsview",
		component: () => import("./pages/bills/view.vue"),
		props: true,
	},
	{
		path: "/bills/add/:id",
		name: "billsadd",
		component: () => import("./pages/bills/add.vue"),
		props: true,
	},
	
	{
		path: "/bills/billstudent",
		name: "billstudent",
		component: () => import("./pages/bills/billstudent.vue"),
		props: true,
	},
	{
		path: "/bills/billapplicants",
		name: "billapplicants",
		component: () => import("./pages/bills/billapplicants.vue"),
		props: true,
	},
	{
		path: "/bills/edit/:id",
		name: "billsedit",
		component: () => import("./pages/bills/edit.vue"),
		props: true,
	},
	//staff routes
	{
		path: "/staff/:fieldName?/:fieldValue?",
		name: "stafflist",
		component: () => import("./pages/staff/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/staff/view/:id",
		name: "staffview",
		component: () => import("./pages/staff/view.vue"),
		props: true,
	},
	{
		path: "/staff/add",
		name: "staffadd",
		component: () => import("./pages/staff/add.vue"),
		props: true,
	},
	{
		path: "/staff/edit/:id",
		name: "staffedit",
		component: () => import("./pages/staff/edit.vue"),
		props: true,
	},
	//shscourses routes
	{
		path: "/shscourses/:fieldName?/:fieldValue?",
		name: "shscourseslist",
		component: () => import("./pages/shscourses/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/shscourses/add",
		name: "shscoursesadd",
		component: () => import("./pages/shscourses/add.vue"),
		props: true,
	},
	{
		path: "/shscourses/edit/:id",
		name: "shscoursesedit",
		component: () => import("./pages/shscourses/edit.vue"),
		props: true,
	},
	//refunds
	{
		path: "/refunds/add/:id",
		name: "refundsadd",
		component: () => import("./pages/refunds/add.vue"),
		props: true,
	},
	//paymenttypes routes
	{
		path: "/paymenttypes/:fieldName?/:fieldValue?",
		name: "paymenttypeslist",
		component: () => import("./pages/paymenttypes/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/paymenttypes/add",
		name: "paymenttypesadd",
		component: () => import("./pages/paymenttypes/add.vue"),
		props: true,
	},
	{
		path: "/paymenttypes/edit/:id",
		name: "paymenttypesedit",
		component: () => import("./pages/paymenttypes/edit.vue"),
		props: true,
	},
	//vouchers routes
	{
		path: "/vouchers/:fieldName?/:fieldValue?",
		name: "voucherslist",
		component: () => import("./pages/vouchers/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	//undergraduateapplicants routes
	{
		path: "/undergraduateapplicants/:fieldName?/:fieldValue?",
		name: "undergraduateapplicantslist",
		component: () => import("./pages/undergraduateapplicants/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/undergraduateapplicants/accepted/:fieldName?/:fieldValue?",
		name: "undergraduateapplicantsaccepted",
		component: () => import("./pages/undergraduateapplicants/accepted.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/undergraduateapplicants/report/:fieldName?/:fieldValue?",
		name: "undergraduateapplicantsreport",
		component: () => import("./pages/undergraduateapplicants/report.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/undergraduateapplicants/view/:id",
		name: "undergraduateapplicantsview",
		component: () => import("./pages/undergraduateapplicants/view.vue"),
		props: true,
	},
	{
		path: "/undergraduateapplicants/add",
		name: "undergraduateapplicantsadd",
		component: () => import("./pages/undergraduateapplicants/add.vue"),
		props: true,
	},
	{
		path: "/undergraduateapplicants/edit/:id",
		name: "undergraduateapplicantsedit",
		component: () => import("./pages/undergraduateapplicants/edit.vue"),
		props: true,
	},
	{
		path: "/undergraduateapplicants/admission/:id",
		name: "undergraduateapplicantsadmission",
		component: () => import("./pages/undergraduateapplicants/admission.vue"),
		props: true,
	},
	{
		path: '/undergraduateapplicants/comments/:fieldName?/:fieldValue?',
		name: 'undergraduateapplicantsreviewchatlist',
		component: () => import('./pages/undergraduateapplicants/comments.vue'),
		props: route => passRouteToProps(route)
	},
	{
		path: '/undergraduateapplicants/addcomment',
		name: 'undergraduateapplicantsreviewchatadd',
		component: () => import('./pages/undergraduateapplicants/addcomment.vue'),
		props: true
	},
	{
		path: '/undergraduateapplicants/editcomment/:id',
		name: 'undergraduateapplicantsreviewchatedit',
		component: () => import('./pages/undergraduateapplicants/editcomment.vue'),
		props: true
	},
	//postgraduateapplicants routes
	{
		path: "/postgraduateapplicants/:fieldName?/:fieldValue?",
		name: "postgraduateapplicantslist",
		component: () => import("./pages/postgraduateapplicants/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/postgraduateapplicants/accepted/:fieldName?/:fieldValue?",
		name: "postgraduateapplicantsaccepted",
		component: () => import("./pages/postgraduateapplicants/accepted.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/postgraduateapplicants/report/:fieldName?/:fieldValue?",
		name: "postgraduateapplicantsreport",
		component: () => import("./pages/postgraduateapplicants/report.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/postgraduateapplicants/view/:id",
		name: "postgraduateapplicantsview",
		component: () => import("./pages/postgraduateapplicants/view.vue"),
		props: true,
	},
	{
		path: "/postgraduateapplicants/add",
		name: "postgraduateapplicantsadd",
		component: () => import("./pages/postgraduateapplicants/add.vue"),
		props: true,
	},
	{
		path: "/postgraduateapplicants/edit/:id",
		name: "postgraduateapplicantsedit",
		component: () => import("./pages/postgraduateapplicants/edit.vue"),
		props: true,
	},
	{
		path: "/postgraduateapplicants/admission/:id",
		name: "postgraduateapplicantsadmission",
		component: () => import("./pages/postgraduateapplicants/admission.vue"),
		props: true,
	},
	{
		path: '/postgraduateapplicants/comments/:fieldName?/:fieldValue?',
		name: 'postgraduateapplicantsreviewchatlist',
		component: () => import('./pages/postgraduateapplicants/comments.vue'),
		props: route => passRouteToProps(route)
	},
	{
		path: '/postgraduateapplicants/addcomment',
		name: 'postgraduateapplicantsreviewchatadd',
		component: () => import('./pages/postgraduateapplicants/addcomment.vue'),
		props: true
	},
	{
		path: '/postgraduateapplicants/editcomment/:id',
		name: 'postgraduateapplicantsreviewchatedit',
		component: () => import('./pages/postgraduateapplicants/editcomment.vue'),
		props: true
	},
	//leaves routes
	{
		path: "/leaves/:fieldName?/:fieldValue?",
		name: "leaveslist",
		component: () => import("./pages/leaves/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/leaves/view/:id",
		name: "leavesview",
		component: () => import("./pages/leaves/view.vue"),
		props: true,
	},
	{
		path: "/leaves/add",
		name: "leavesadd",
		component: () => import("./pages/leaves/add.vue"),
		props: true,
	},
	{
		path: "/leaves/edit/:id",
		name: "leavesedit",
		component: () => import("./pages/leaves/edit.vue"),
		props: true,
	},
	//leavetypes routes
	{
		path: "/leavetypes/:fieldName?/:fieldValue?",
		name: "leavetypeslist",
		component: () => import("./pages/leavetypes/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/leavetypes/view/:id",
		name: "leavetypesview",
		component: () => import("./pages/leavetypes/view.vue"),
		props: true,
	},
	{
		path: "/leavetypes/add",
		name: "leavetypesadd",
		component: () => import("./pages/leavetypes/add.vue"),
		props: true,
	},
	{
		path: "/leavetypes/edit/:id",
		name: "leavetypesedit",
		component: () => import("./pages/leavetypes/edit.vue"),
		props: true,
	},
	//paymentvouchers routes
	{
		path: "/paymentvouchers/:fieldName?/:fieldValue?",
		name: "paymentvoucherslist",
		component: () => import("./pages/paymentvouchers/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/paymentvouchers/view/:id",
		name: "paymentvouchersview",
		component: () => import("./pages/paymentvouchers/view.vue"),
		props: true,
	},
	{
		path: "/paymentvouchers/add",
		name: "paymentvouchersadd",
		component: () => import("./pages/paymentvouchers/add.vue"),
		props: true,
	},
	{
		path: "/paymentvouchers/edit/:id",
		name: "paymentvouchersedit",
		component: () => import("./pages/paymentvouchers/edit.vue"),
		props: true,
	},
	//payroll routes
	{
		path: "/payroll/:fieldName?/:fieldValue?",
		name: "payrolllist",
		component: () => import("./pages/payroll/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/payroll/view/:id",
		name: "payrollview",
		component: () => import("./pages/payroll/view.vue"),
		props: true,
	},
	{
		path: "/payroll/add",
		name: "payrolladd",
		component: () => import("./pages/payroll/add.vue"),
		props: true,
	},
	{
		path: "/payroll/edit/:id",
		name: "payrolledit",
		component: () => import("./pages/payroll/edit.vue"),
		props: true,
	},
	//suggestions routes
	{
		path: "/suggestions/:fieldName?/:fieldValue?",
		name: "suggestionslist",
		component: () => import("./pages/suggestions/list.vue"),
		props: (route) => passRouteToProps(route),
	},
	{
		path: "/suggestions/view/:id",
		name: "suggestionsview",
		component: () => import("./pages/suggestions/view.vue"),
		props: true,
	},
	{
		path: "/suggestions/add",
		name: "suggestionsadd",
		component: () => import("./pages/suggestions/add.vue"),
		props: true,
	},
	{
		path: "/suggestions/edit/:id",
		name: "suggestionsedit",
		component: () => import("./pages/suggestions/edit.vue"),
		props: true,
	},
	//waecresults routes
	{
		path: "/waecresults/view/:id",
		name: "waecresultsview",
		component: () => import("./pages/waecresults/view.vue"),
		props: true,
	},
	//mountedcourses routes
	{
		path: '/mountedcourses/:fieldName?/:fieldValue?',
		name: 'mountedcourseslist',
		component: () => import('./pages/mountedcourses/list.vue'),
		props: route => passRouteToProps(route)
	},
	{
		path: '/mountedcourses/view/:id',
		name: 'mountedcoursesview',
		component: () => import('./pages/mountedcourses/view.vue'),
		props: true
	},
	//Password reset routes
	{
		path: "/index/forgotpassword",
		name: "forgotpassword",
		component: () => import("./pages/index/forgotpassword.vue"),
		props: true,
	},
	{
		path: '/index/verifyotp',
		name: 'verifyotp',
		component: () => import('./pages/index/verifyotp.vue'),
		props: true
	},
	{
		path: "/index/resetpassword",
		name: "resetpassword",
		component: () => import("./pages/index/resetpassword.vue"),
		props: true,
	},
	{
		path: "/index/resetpassword_completed",
		name: "resetpassword_completed",
		component: () => import("./pages/index/resetpassword_completed.vue"),
		props: true,
	},
	{
		path: "/index/accountinactive",
		name: "accountinactive",
		component: () => import("./pages/index/accountinactive.vue"),
		props: true,
	},
	{
		path: "/index/accountblocked",
		name: "accountblocked",
		component: () => import("./pages/index/accountblocked.vue"),
		props: true,
	},
	{
		path: "/error/forbidden",
		name: "forbidden",
		component: () => import("./pages/errors/forbidden.vue"),
		props: true,
	},
	{
		path: "/error/notfound",
		name: "notfound",
		component: () => import("./pages/errors/pagenotfound.vue"),
		props: true,
	},
	{
		path: "/:catchAll(.*)",
		component: () => import("./pages/errors/pagenotfound.vue"),
	},
];

export default () => {
	const auth = useAuth();

	const user = auth.user;
	if (user) {
		routes.push({
			path: "/",
			alias: "/home",
			name: "home",
			component: () => import(`./pages/home/home.vue`),
		});
	} else {
		routes.push({
			path: "/",
			alias: "/index",
			name: "index",
			component: () => import("./pages/index/index.vue"),
		});
	}

	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion) {
			if (savedPostion) return savedPostion;
			return {
				top: 0
			};
		},
	});

	router.beforeEach(async (to, from, next) => {
		const user = auth.user;
		let path = to.path;
		let authRequired = auth.pageRequiredAuth(path);
		if (authRequired) {
			if (!user) {
				return next({
					path: "/", query: {
						nexturl: to.fullPath
					}
				});
			}
			//authorize user
			if (!auth.canView(path)) {
				return next({
					path: "/error/forbidden"
				});
			}
		}

		if (user && to.name == "index") {
			//already logged in, show home when try to access index page
			return next({
				path: "/home"
			});
		}

		if (user && to.name == "verifyotp") {
			//already logged in, show home when try to access index page
			return next({
				path: "/home"
			});
		}

		//navigate to redirect url if available
		if (to.name == "home" && to.query.nexturl) {
			return next({
				path: to.query.nexturl
			});
		}
		//close dialog from previous page
		//store.closeDialogs('app/closeDialogs');
		// if(authRequired){
		// 	await auth.getUserData()
		// 	if(await auth.getUserData()){
		// 		next();
		// 	}
		// }else{
		// 	next();
		// }
		
    next();
	});

	return router;
};
