
export const AppMenus = {

  navbarTopRight: [
    {
      "to": "/account",
      "label": "My Account",
      "icon": "pi pi-user",
      "iconcolor": "",
      "target": "",

    }
  ],
  navbarTopLeft: [],
  navbarSideLeft: [
    {
      "to": "/home",
      "label": "Home",
      "icon": "",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "",
      "label": "Account Management",
      "icon": "",
      "iconcolor": "",
      "target": "sidemenu/accountmanagement",
      "items": [
        {
          "to": "/users",
          "label": "User Accounts",
          "icon": "pi pi-users",
          "iconcolor": "",
          "target": "",
        },
        {
          "to": "/roles",
          "label": "Account Roles",
          "icon": "pi pi-unlock",
          "iconcolor": "",
          "target": "",
        },
      ]
    },
    {
      "to": "",
      "label": "Academics",
      "icon": "",
      "iconcolor": "",
      "target": "sidemenu/academics",
      "items": [
        {
          "to": "/faculties",
          "label": "Faculties",
          "icon": "pi pi-star",
          "iconcolor": "",
          "target": "",
        },
        {
          "to": "/departments",
          "label": "Departments",
          "icon": "pi pi-send",
          "iconcolor": "",
          "target": "",
        },
        {
          "to": "/programmes",
          "label": "Programmes",
          "icon": "pi pi-inbox",
          "iconcolor": "",
          "target": "",
        },
        {
          "to": "/courses",
          "label": "Courses",
          "icon": "pi pi-book",
          "iconcolor": "",
          "target": "",
        },
        {
          "to": "/assigntolecturer",
          "label": "Mounted Courses",
          "icon": "pi pi-bookmark",
          "iconcolor": "",
          "target": "",
        },
        {
          "to": "/campuses",
          "label": "Learning Centers",
          "icon": "pi pi-map",
          "iconcolor": "",
          "target": "",
        },
      ]
    },
    {
      "to": "",
      "label": "Online Admissions",
      "icon": "",
      "iconcolor": "",
      "target": "sidemenu/admissions",
      "items": [
        {
          "to": "/applicants",
          "label": "Freshmen",
          "icon": "pi pi-globe",
          "iconcolor": "",
          "target": "",
        },
        {
          "to": "",
          "label": "Undergraduate",
          "icon": "",
          "iconcolor": "",
          "target": "sidemenu/undergraduateadmissions",
          "items": [
            {
              "to": "/undergraduateapplicants/report",
              "label": "Report",
              "icon": "pi pi-chart-bar",
              "iconcolor": "",
              "target": "",
            },
            {
              "to": "/undergraduateapplicants",
              "label": "New Applicants",
              "icon": "pi pi-sign-in",
              "iconcolor": "",
              "target": "",
            },
            {
              "to": "/undergraduateapplicants/accepted",
              "label": "Accepted",
              "icon": "pi pi-check-circle",
              "iconcolor": "",
              "target": "",
            },

          ]
        },
        {
          "to": "",
          "label": "Graduate School",
          "icon": "",
          "iconcolor": "",
          "target": "sidemenu/postgraduateadmissions",
          "items": [
            {
              "to": "/postgraduateapplicants/report",
              "label": "Report",
              "icon": "pi pi-chart-bar",
              "iconcolor": "",
              "target": "",
            },
            {
              "to": "/postgraduateapplicants",
              "label": "New Applicants",
              "icon": "pi pi-sign-in",
              "iconcolor": "",
              "target": "",
            },
            {
              "to": "/postgraduateapplicants/accepted",
              "label": "Accepted",
              "icon": "pi pi-check-circle",
              "iconcolor": "",
              "target": "",
            },
          ]
        },
      ]
    },
    // {
    //   "to": "",
    //   "label": "Registration",
    //   "icon": "",
    //   "iconcolor": "",
    //   "target": "sidemenu/registration",
    //   "items": [
    //     {
    //       "to": "/applicants",
    //       "label": "Freshmen",
    //       "icon": "pi pi-globe",
    //       "iconcolor": "",
    //       "target": "",
    //     },
    //     {
    //       "to": "/assigntolecturer",
    //       "label": "Mounted Courses",
    //       "icon": "pi pi-bookmark",
    //       "iconcolor": "",
    //       "target": "",
    //     },
    //   ]
    // },
    {
      "to": "",
      "label": "Students",
      "icon": "",
      "iconcolor": "",
      "target": "sidemenu/students",
      "items": [
        
        {
          "to": "/studentsstats",
          "label": "Student Stats",
          "icon": "pi pi-th-large",
          "iconcolor": "",
          "target": "",
        },
        {
          "to": "/students",
          "label": "Find Student",
          "icon": "pi pi-search",
          "iconcolor": "",
          "target": "",
        },
        {
          "to": "/students/registered",
          "label": "Registered Student",
          "icon": "pi pi-user-plus",
          "iconcolor": "",
          "target": "",
        },
        {
          "to": "/students/unregistered",
          "label": "Unregistered Student",
          "icon": "pi pi-user-minus",
          "iconcolor": "",
          "target": "",
        },
        {
          "to": "/coursesenrolment",
          "label": "Courses Enrolment",
          "icon": "pi pi-book",
          "iconcolor": "",
          "target": "",
        },
        {
          "to": "/students/changeprogrammelist",
          "label": "Change Programme",
          "icon": "pi pi-book",
          "iconcolor": "",
          "target": "",
        },
        {
          "to": "/studenttimetable",
          "label": "Student Timetable",
          "icon": "pi pi-calendar",
          "iconcolor": "",
          "target": "",
        },
        {
          "to": "/studentalerts",
          "label": "Student Alerts",
          "icon": "pi pi-megaphone",
          "iconcolor": "",
          "target": "",
        },
        {
          "to": "/studentclearance",
          "label": "Student Clearance",
          "icon": "pi pi-check-circle",
          "iconcolor": "",
          "target": "",
        },
      ]
    },
    {
      "to": "",
      "label": "Examination",
      "icon": "",
      "iconcolor": "",
      "target": "sidemenu/examination",
      "items": [
        {
          "to": "/examination",
          "label": "Dashboard",
          "icon": "pi pi-bookmark",
          "iconcolor": "",
          "target": "",
        },
        {
          "to": "/examination/broadsheet",
          "label": "Broadsheet",
          "icon": "pi pi-file-excel",
          "iconcolor": "",
          "target": "",
        },
        {
          "to": "/examination/academicboardformat",
          "label": "Academic Board Format",
          "icon": "pi pi-file-word",
          "iconcolor": "",
          "target": "",
        },
        {
          "to": "/examination/transcript",
          "label": "Transcript",
          "icon": "pi pi-file",
          "iconcolor": "",
          "target": "",
        },
        {
          "to": "/examstimetable",
          "label": "Upload Timetable",
          "icon": "pi pi-cloud-upload",
          "iconcolor": "",
          "target": "",
        },
        {
          "to": "/examsschedule",
          "label": "Exams Schedules",
          "icon": "pi pi-calendar-plus",
          "iconcolor": "",
          "target": "",
        },
      ]
    },
    {
      "to": "",
      "label": "Evaluation",
      "icon": "",
      "iconcolor": "",
      "target": "sidemenu/evaluation",
      "items": [
        {
          "to": "/evaluations",
          "label": "Dashboard",
          "icon": "pi pi-bookmark",
          "iconcolor": "",
          "target": "",
        },
        {
          "to": "/evaluationquestions",
          "label": "Evaluation Questions",
          "icon": "pi pi-question-circle",
          "iconcolor": "",
          "target": "",
        },
        {
          "to": "/evaluationquestionstypes",
          "label": "Evaluation Questions Types",
          "icon": "pi pi-question",
          "iconcolor": "",
          "target": "",
        },
        
      ]
    },
    {
      "to": "",
      "label": "Finance Management",
      "icon": "",
      "iconcolor": "",
      "target": "sidemenu/financemanagement",
      "items": [
        {
          "to": "/fees",
          "label": "Fees",
          "icon": "pi pi-star",
          "iconcolor": "",
          "target": "",

        },
        {
          "to": "/currencyrates",
          "label": "Currency Rates",
          "icon": "pi pi-calculator",
          "iconcolor": "",
          "target": "",

        },
        {
          "to": "/vouchers",
          "label": "Vouchers",
          "icon": "pi pi-ticket",
          "iconcolor": "",
          "target": "",
        },
        {
          "to": "/paymenttypes",
          "label": "Payment Types",
          "icon": "pi pi-wallet",
          "iconcolor": "",
          "target": "",
        },
        {
          "to": "/payments",
          "label": "Payments",
          "icon": "pi pi-money-bill",
          "iconcolor": "",
          "target": "",

        },
        {
          "to": "/bills",
          "label": "Bills & Refunds",
          "icon": "pi pi-money-bill",
          "iconcolor": "",
          "target": "",
 
        },
        {
          "to": "/paymentreport",
          "label": "Payment Report",
          "icon": "pi pi-book",
          "iconcolor": "",
          "target": "",
 
        },
        {
          "to": "/manualcourseregistration",
          "label": "Finance Approved for Registrations",
          "icon": "pi pi-verified",
          "iconcolor": "",
          "target": "",
 
        },
      ]
    },
    {
      "to": "",
      "label": "HRIS",
      "icon": "",
      "iconcolor": "",
      "target": "sidemenu/hris",
      "items": [
        {
          "to": "/staff",
          "label": "Staff Records",
          "icon": "pi pi-briefcase",
          "iconcolor": "",
          "target": "",

        },
        {
          "to": "/paymentvouchers",
          "label": "Payment Vouchers",
          "icon": "pi pi-money-bill",
          "iconcolor": "",
          "target": "",
        },
        {
          "to": "/leaves",
          "label": "Leaves",
          "icon": "pi pi-flag",
          "iconcolor": "",
          "target": "",
        },
        {
          "to": "/leavetypes",
          "label": "Leave Types",
          "icon": "pi pi-sliders-v",
          "iconcolor": "",
          "target": "",
        },
        {
          "to": "/payroll",
          "label": "Payroll",
          "icon": "pi pi-shopping-bag",
          "iconcolor": "",
          "target": "",
        },
        {
          "to": "/suggestions",
          "label": "Suggestion Box",
          "icon": "pi pi-box",
          "iconcolor": "",
          "target": "",
        },
      ]
    },
    {
      "to": "",
      "label": "Documents",
      "icon": "",
      "iconcolor": "",
      "target": "sidemenu/documents",
      "items": [
        {
          "to": "/documenttemplates",
          "label": "Templates",
          "icon": "pi pi-copy",
          "iconcolor": "",
          "target": "",
        },
        {
          "to": "/documentmanagers",
          "label": "Managers",
          "icon": "pi pi-user",
          "iconcolor": "",
          "target": "",
        },
        {
          "to": "/documentrequests",
          "label": "Requests",
          "icon": "pi pi-file-import",
          "iconcolor": "",
          "target": "",
        }
      ]
    },
    {
      "to": "",
      "label": "Asset Management",
      "icon": "",
      "iconcolor": "",
      "target": "sidemenu/assetmanagement",
      "items": [
        {
          "to": "/buildings",
          "label": "Buildings",
          "icon": "pi pi-building",
          "iconcolor": "",
          "target": "",

        },
      ]
    },
    {
      "to": "",
      "label": "Hostel Management",
      "icon": "",
      "iconcolor": "",
      "target": "sidemenu/hostelmanagement",
      "items": [

      ]
    },
    {
      "to": "",
      "label": "Settings",
      "icon": "",
      "iconcolor": "",
      "target": "sidemenu/settings",
      "items": [
        {
          "to": "",
          "label": "Online Admissions",
          "icon": "",
          "iconcolor": "",
          "target": "",
          "items": [
            {
              "to": "/appconfig",
              "label": "ADM Cohorts",
              "icon": "pi pi-globe",
              "iconcolor": "",
              "target": "",
            },
            {
              "to": "/applicationfees",
              "label": "ADM Fees",
              "icon": "pi  pi-money-bill",
              "iconcolor": "",
              "target": "",
            },
            {
              "to": "/admissionletters",
              "label": "Letter Templates",
              "icon": "pi pi-file",
              "iconcolor": "",
              "target": "",
            },
            {
              "to": "/shscourses",
              "label": "SHS Courses",
              "icon": "pi pi-book",
              "iconcolor": "",
              "target": "",
            },
          ]
        },

        {
          "to": "",
          "label": "System",
          "icon": "",
          "iconcolor": "",
          "target": "",
          "items": [
            {
              "to": "/academicyears",
              "label": "Academic Years",
              "icon": "pi pi-database",
              "iconcolor": "",
              "target": "",
            },
            {
              "to": "/academicsessions",
              "label": "Sessions",
              "icon": "pi pi-sun",
              "iconcolor": "",
              "target": "",
            },
            {
              "to": "/academicevents",
              "label": "Event Labels",
              "icon": "pi pi-table",
              "iconcolor": "",
              "target": "",
            },
            {
              "to": "/academiccalendar",
              "label": "Calendar",
              "icon": "pi pi-calendar",
              "iconcolor": "",
              "target": "",
            },
            {
              "to": "/apps",
              "label": "Apps",
              "icon": "pi pi-cog",
              "iconcolor": "",
              "target": "",
            },
            {
              "to": "/pages",
              "label": "ERP Pages",
              "icon": "pi pi-globe",
              "iconcolor": "",
              "target": "",
            },
          ]
        },
      ]
    },
    {
      "to": "",
      "label": "Audit Trail",
      "icon": "",
      "iconcolor": "",
      "target": "sidemenu/audittrail",
      "items": [
        {
          "to": "/audits",
          "label": "User Logs",
          "icon": "pi pi-users",
          "iconcolor": "",
          "target": "",
        },
      ]
    },
  ],
  applicationMode: [
    { value: "Online", label: "Online" },
    { value: "Manual Entry", label: "Manual Entry" },
  ],

  activestatus: [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" }
  ],
  applicationStatusItems: [
    { value: "Pending", label: "Please check this box to allow applicant modify his/her application" },
  ],
  admissionLetterSentItems: [
    { value: "No", label: "Please check this box if you want to Re-Issue Admission Letter" },
  ],
  noneItems: [
    { value: "None", label: "None" },
  ],
  startingLevel: [
    { value: "100", label: "Level 100" },
    { value: "200", label: "Level 200" },
    { value: "300", label: "Level 300" },
    { value: "400", label: "Level 400" },
  ],
  pgstartingLevel: [
    {
      label: 'Masters (MSc)',
      items: [
        { value: '501', label: "Level 500" },
        { value: '601', label: "Level 600" },
      ]
    },
    {
      label: 'Masters (MBA)',
      items: [
        { value: '502', label: "Level 500" },
        { value: '602', label: "Level 600" },
      ]
    },
    {
      label: 'Masters (MA)',
      items: [
        { value: '503', label: "Level 500" },
        { value: '603', label: "Level 600" },
      ]
    },
    {
      label: 'Masters (MPhil)',
      items: [
        { value: '504', label: "Level 500" },
        { value: '604', label: "Level 600" },
      ]
    },
    {
      label: 'TNE',
      items: [
        { value: '505', label: "Coventry Level 500" },
        { value: '506', label: "Anhalt Level 500" },
       
      ]
    },
    {
      label: 'PhD',
      items: [
        { value: '701', label: "Year 1" },
        { value: '702', label: "Year 2" },
        { value: '703', label: "Year 3" },
        { value: '704', label: "Year 4" },
      ]
    },
  ],
  undergraduateStudyLevel: [
    { value: "Diploma", label: "Diploma" },
    { value: "Bachelors", label: "Bachelor's" },
    { value: "Topup", label: "Topup" },
    { value: "Mature", label: "Mature" },
  ],
  postgraduateStudyLevel: [
    { value: "Masters", label: "Master's" },
    { value: "Doctoral (PhD)", label: "Doctoral (Ph.D)" },
  ],
  studentStudyLevel: [
    { value: "Diploma", label: "Diploma" },
    { value: "Bachelors", label: "Bachelor's" },
    { value: "Topup", label: "Topup" },
    { value: "Mature", label: "Mature" },
    { value: "Masters", label: "Master's" },
    { value: "Doctoral (PhD)", label: "Doctoral (Ph.D)" },
  ],
  designation: [
    { value: "GCTU", label: "GCTU" },
    { value: "External", label: "External" },
  ],
  feesStudentType: [
    { value: "Regular", label: "Regular" },
    { value: "International", label: "International" },
  ],
  processing_duration: [
    { value: "1 Working Day", label: "1 Working Day" },
    { value: "2 Working Days", label: "2 Working Days" },
    { value: "3 Working Days", label: "3 Working Days" },
    { value: "4 Working Days", label: "4 Working Days" },
    { value: "5 Working Days", label: "5 Working Days" },
  ],
  collaborationWith: [
    { value: "Ghana Communication Technology University", label: "Ghana Communication Technology University" },
    { value: "Coventry University", label: "Coventry University" },
    { value: "Anhalt University", label: "Anhalt University" },
    { value: "Ramaiah University", label: "Ramaiah University" },
    { value: "Aalborg University", label: "Aalborg University" },
    { value: "Kwame Nkrumah University of Science and Technology", label: "Kwame Nkrumah University of Science and Technology" },
  ],
  programmeDurations: [
    { value: "Full Time [1 Year]", label: "Full Time [1 Year]" },
    { value: "Part Time [2-3 Years]", label: "Part Time [2-3 Years]" },
    { value: "Full Time [2 Years]", label: "Full Time [2 Years]" },
    { value: "Full Time [4 Years]", label: "Full Time [4 Years]" },
  ],
  studylevelOptions: [
    { value: "Undergraduate", label: "Undergraduate" },
    { value: "Topup", label: "Topup" },
    { value: "Mature", label: "Mature" },
    { value: "Diploma", label: "Diploma" },
    { value: "Postgraduate", label: "Postgraduate" },
    { value: "Masters", label: "Master's" },
    { value: "PhD", label: "PhD" },
  ],

  studentstudylevelOptions: [
    { value: "Topup", label: "Topup" },
    { value: "Bachelors", label: "Bachelors" },
    { value: "Mature", label: "Mature" },
    { value: "Diploma", label: "Diploma" },
    { value: "Masters", label: "Master's" },
    { value: "PhD", label: "PhD" },
  ],

  DegreeToAward: [

  ],

  qualification: [
    { "value": "BECE", "label": "BECE" },
    { "value": "WASSCE", "label": "WASSCE" },
    { "value": "BSc", "label": "BSc" },
    { "value": "BA", "label": "BA" },
    { "value": "MSc", "label": "MSc" },
    { "value": "MA", "label": "MA" },
    { "value": "BFA", "label": "BFA" },
    { "value": "BMus", "label": "BMus" },
    { "value": "BSc Admin", "label": "BSc Admin" },
    { "value": "MBA", "label": "MBA" },
    { "value": "MPhil", "label": "MPhil" },
    { "value": "BEd", "label": "BEd" },
    { "value": "PGDE", "label": "PGDE" },
    { "value": "B.Com", "label": "B.Com" },
    { "value": "Bachelor of Divinity", "label": "Bachelor of Divinity" },
    { "value": "Post Graduate Diploma", "label": "Post Graduate Diploma" },
    { "value": "HND", "label": "HND" },
    { "value": "MPA", "label": "MPA" },
    { "value": "MPH", "label": "MPH" },
    { "value": "Diploma", "label": "Diploma" },
    { "value": "B.Pharm", "label": "B.Pharm" },
    { "value": "D.Pharm", "label": "D.Pharm" },
    { "value": "LLB", "label": "LLB" },
    { "value": "LLM", "label": "LLM" },
    { "value": "MBChB", "label": "MBChB" },
    { "value": "Ph.D", "label": "Ph.D" },
    { "value": "Other", "label": "Other" }
  ],
  approval: [
    { value: "Approved", label: "Approve" },
    { value: "Declined", label: "Decline" }
  ],
  pvType: [
    { value: "Petty Cash", label: "Petty Cash" }
  ],
  feesCurrency: [
    { value: "GHS", label: "GHS" },
    { value: "USD", label: "USD" },
    { value: "GBP", label: "GBP" },
  ],
  requestType: [
    { value: "Express", label: "Express" },
    { value: "Regular", label: "Regular" }
  ],
  requestStatus: [
    { value: "Pending", label: "Pending" },
    { value: "Processing", label: "Processing" },
    { value: "Ready", label: "Ready" },
    { value: "Closed", label: "Closed" },
  ],
  paymentStatus: [
    { value: "Pending", label: "Pending" },
    { value: "Success", label: "Success" },
    { value: "Failed", label: "Failed" },
  ],
  accountStatus: [
    { value: "Active", label: "Active" },
    { value: "Blocked", label: "Blocked" },
  ],
  studentStatus: [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
    { label: "Suspended", value: "Suspended" },
    { label: "Dismissed", value: "Dismissed" },
    { label: "Deceased", value: "Deceased" },
    { label: "Defered", value: "Defered" },
    { label: "Probation", value: "Probation" },
    { label: "Repeated", value: "Repeated" },
    { label: "Withdrawn", value: "Withdrawn" },
  ],
  maritalStatus: [
    { value: "Married", label: "Married" },
    { value: "Single", label: "Single" },
    { value: "Divorced", label: "Divorced" },
    { value: "Separated", label: "Separated" }
  ],
  religiousDenomination: [
    { "value": "Catholic", "label": "Catholic" },
    { "value": "Protestants (Anglican, Lutheran, Presbyterian, Methodist, etc.)", "label": "Protestants (Anglican, Lutheran, Presbyterian, Methodist, etc.)" },
    { "value": "Pentecostal/Charismatic (Apostolic, Pentecost, Assemblies, etc.)", "label": "Pentecostal/Charismatic (Apostolic, Pentecost, Assemblies, etc.)" },
    { "value": "Other Christian", "label": "Other Christian" },
    { "value": "Islam/Ahmadis", "label": "Islam/Ahmadis" },
    { "value": "Traditionalist", "label": "Traditionalist" },
    { "value": "No Region", "label": "No Region" },
    { "value": "Other", "label": "Other" }
  ],
  employeeCategory: [
    { value: "Teaching Staff", label: "Teaching Staff" },
    { value: "Non-Teaching Staff", label: "Non-Teaching Staff" }
  ],
  categoryOfStaff: [
    { value: "Senior Member", label: "Senior Member" },
    { value: "Senior Staff", label: "Senior Staff" },
    { value: "Junior Staff", label: "Junior Staff" },
    { value: "Academic Staff", label: "Academic Staff" },
    { value: "Admin. Snr. Member", label: "Admin. Snr. Member" },
    { value: "Academic Staff", label: "Academic Staff" },
  ],
  division: [
    { value: "Academic", label: "Academic" },
    { value: "Administrative", label: "Administrative" },
  ],
  status: [
    { value: "Pending", label: "Pending" },
    { value: "Processed", label: "Processed" },
    { value: "Accepted", label: "Accepted" },
    { value: "Rejected", label: "Rejected" },
    { value: "Uncompleted", label: "Uncompleted" },
    { value: "Completed", label: "Completed" },
    { value: "Closed", label: "Closed" },
    { value: "Paid", label: "Paid" },
    { value: "Approved", label: "Approved" },
    { value: "Declined", label: "Declined" },
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
    { value: "Opened", label: "Opened" },
    { value: "Processing", label: "Processing" },
    { value: "Ready", label: "Ready" },
    { value: "Sent", label: "Sent" },
    { value: "Read", label: "Read" },
    { value: "Failed", label: "Failed" },
    { value: "Reversed", label: "Reversed" },
    { value: "Refunded", label: "Refunded" },
    { value: "Success", label: "Success" },
    { label: "Suspended", value: "Suspended" },
    { label: "Dismissed", value: "Dismissed" },
    { label: "Deceased", value: "Deceased" },
    { label: "Defered", value: "Defered" },
    { label: "Probation", value: "Probation" },
    { label: "Repeated", value: "Repeated" },
    { label: "Withdrawn", value: "Withdrawn" },
  ],

  results_status: [
    { value: "Pending", label: "Pending" },
    { value: "Completed", label: "Submitted" },
    { value: "Suspended", label: "Suspended" },
  ],

  academicMonth: [
    { value: "January", label: "January" },
    { value: "May", label: "May" },
    { value: "June", label: "June" },
    { value: "September", label: "September" }
  ],

  learning_institution: [
    { value: "Regular", label: "Regular" },
    { value: "Distance", label: "Distance" }
],
educational_level: [
  { value: "Postgraduate", label: "Postgraduate" },
  { value: "Undergraduate", label: "Undergraduate" }
  
],
  title: [
    { value: "Mr.", label: "Mr." },
    { value: "Mrs.", label: "Mrs." },
    { value: "Miss.", label: "Miss." },
    { value: "Dr.", label: "Dr." },
    { value: "Prof.", label: "Prof." },
    { value: "Associate Professor", label: "Associate Professor" },
    { value: "Ing.", label: "Ing." },
    { value: "Sys.", label: "Sys." },
    { value: "Sr.", label: "Sr." },
    { value: "Jr.", label: "Jr." },
  ],
  gender: [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" }
  ],
  student_type: [
    { value: "International", label: "International" },
    { value: "Regular", label: "Regular" }
  ],
  countryOfOrigin: [
    { value: "Ghana", label: "Ghana" },
    { value: "Nigeria", label: "Nigeria" },
    { value: "Algeria", label: "Algeria" },
    { value: "Angola", label: "Angola" },
    { value: "Anguilla", label: "Anguilla" },
    { value: "Argentina", label: "Argentina" },
    { value: "Armenia", label: "Armenia" },
    { value: "Aruba", label: "Aruba" },
    { value: "Australia", label: "Australia" },
    { value: "Austria", label: "Austria" },
    { value: "Azerbaijan", label: "Azerbaijan" },
    { value: "Bahamas", label: "Bahamas" },
    { value: "Bahrain", label: "Bahrain" },
    { value: "Bangladesh", label: "Bangladesh" },
    { value: "Barbados", label: "Barbados" },
    { value: "Belarus", label: "Belarus" },
    { value: "Belgium", label: "Belgium" },
    { value: "Belize", label: "Belize" },
    { value: "Benin", label: "Benin" },
    { value: "Bermuda", label: "Bermuda" },
    { value: "Bhutan", label: "Bhutan" },
    { value: "Bolivia", label: "Bolivia" },
    { value: "Botswana", label: "Botswana" },
    { value: "Bouvet Island", label: "Bouvet Island" },
    { value: "Brazil", label: "Brazil" },
    { value: "Bulgaria", label: "Bulgaria" },
    { value: "Burkina Faso", label: "Burkina Faso" },
    { value: "Burundi", label: "Burundi" },
    { value: "Cambodia", label: "Cambodia" },
    { value: "Cameroon", label: "Cameroon" },
    { value: "Canada", label: "Canada" },
    { value: "Cape Verde", label: "Cape Verde" },
    { value: "Chad", label: "Chad" },
    { value: "Chile", label: "Chile" },
    { value: "China", label: "China" },
    { value: "Christmas Island", label: "Christmas Island" },
    { value: "Colombia", label: "Colombia" },
    { value: "Comoros", label: "Comoros" },
    { value: "Congo", label: "Congo" },
    { value: "Cook Islands", label: "Cook Islands" },
    { value: "Costa Rica", label: "Costa Rica" },
    { value: "Cote D'Ivoire", label: "Cote D'Ivoire" },
    { value: "Croatia", label: "Croatia" },
    { value: "Cuba", label: "Cuba" },
    { value: "Cyprus", label: "Cyprus" },
    { value: "Czech Republic", label: "Czech Republic" },
    { value: "Denmark", label: "Denmark" },
    { value: "Djibouti", label: "Djibouti" },
    { value: "Dominica", label: "Dominica" },
    { value: "Dominican Republic", label: "Dominican Republic" },
    { value: "Ecuador", label: "Ecuador" },
    { value: "Egypt", label: "Egypt" },
    { value: "El Salvador", label: "El Salvador" },
    { value: "Equatorial Guinea", label: "Equatorial Guinea" },
    { value: "Eritrea", label: "Eritrea" },
    { value: "Estonia", label: "Estonia" },
    { value: "Ethiopia", label: "Ethiopia" },
    { value: "Faroe Islands", label: "Faroe Islands" },
    { value: "Fiji", label: "Fiji" },
    { value: "Finland", label: "Finland" },
    { value: "France", label: "France" },
    { value: "French Guiana", label: "French Guiana" },
    { value: "French Polynesia", label: "French Polynesia" },
    { value: "Gabon", label: "Gabon" },
    { value: "Gambia", label: "Gambia" },
    { value: "Georgia", label: "Georgia" },
    { value: "Germany", label: "Germany" },
    { value: "Gibraltar", label: "Gibraltar" },
    { value: "Greece", label: "Greece" },
    { value: "Greenland", label: "Greenland" },
    { value: "Grenada", label: "Grenada" },
    { value: "Guadeloupe", label: "Guadeloupe" },
    { value: "Guam", label: "Guam" },
    { value: "Guatemala", label: "Guatemala" },
    { value: "Guernsey", label: "Guernsey" },
    { value: "Guinea", label: "Guinea" },
    { value: "Guinea-Bissau", label: "Guinea-Bissau" },
    { value: "Guyana", label: "Guyana" },
    { value: "Haiti", label: "Haiti" },
    { value: "Honduras", label: "Honduras" },
    { value: "Hong Kong", label: "Hong Kong" },
    { value: "Hungary", label: "Hungary" },
    { value: "Iceland", label: "Iceland" },
    { value: "India", label: "India" },
    { value: "Indonesia", label: "Indonesia" },
    { value: "Iran", label: "Iran" },
    { value: "Iraq", label: "Iraq" },
    { value: "Ireland", label: "Ireland" },
    { value: "Isle of Man", label: "Isle of Man" },
    { value: "Israel", label: "Israel" },
    { value: "Italy", label: "Italy" },
    { value: "Jamaica", label: "Jamaica" },
    { value: "Japan", label: "Japan" },
    { value: "Jersey", label: "Jersey" },
    { value: "Jordan", label: "Jordan" },
    { value: "Kazakhstan", label: "Kazakhstan" },
    { value: "Kenya", label: "Kenya" },
    { value: "Kiribati", label: "Kiribati" },
    { value: "Korea", label: "Korea" },
    { value: "Kuwait", label: "Kuwait" },
    { value: "Kyrgyzstan", label: "Kyrgyzstan" },
    { value: "Latvia", label: "Latvia" },
    { value: "Lebanon", label: "Lebanon" },
    { value: "Lesotho", label: "Lesotho" },
    { value: "Liberia", label: "Liberia" },
    { value: "Lithuania", label: "Lithuania" },
    { value: "Luxembourg", label: "Luxembourg" },
    { value: "Macao", label: "Macao" },
    { value: "Macedonia", label: "Macedonia" },
    { value: "Madagascar", label: "Madagascar" },
    { value: "Malawi", label: "Malawi" },
    { value: "Malaysia", label: "Malaysia" },
    { value: "Maldives", label: "Maldives" },
    { value: "Mali", label: "Mali" },
    { value: "Malta", label: "Malta" },
    { value: "Marshall Islands", label: "Marshall Islands" },
    { value: "Martinique", label: "Martinique" },
    { value: "Mauritania", label: "Mauritania" },
    { value: "Mauritius", label: "Mauritius" },
    { value: "Mayotte", label: "Mayotte" },
    { value: "Mexico", label: "Mexico" },
    { value: "Micronesia", label: "Micronesia" },
    { value: "Moldova", label: "Moldova" },
    { value: "Monaco", label: "Monaco" },
    { value: "Mongolia", label: "Mongolia" },
    { value: "Montserrat", label: "Montserrat" },
    { value: "Morocco", label: "Morocco" },
    { value: "Mozambique", label: "Mozambique" },
    { value: "Myanmar", label: "Myanmar" },
    { value: "Namibia", label: "Namibia" },
    { value: "Nauru", label: "Nauru" },
    { value: "Nepal", label: "Nepal" },
    { value: "Netherlands", label: "Netherlands" },
    { value: "Netherlands Antilles", label: "Netherlands Antilles" },
    { value: "New Caledonia", label: "New Caledonia" },
    { value: "New Zealand", label: "New Zealand" },
    { value: "Nicaragua", label: "Nicaragua" },
    { value: "Niger", label: "Niger" },
    { value: "Niue", label: "Niue" },
    { value: "Norfolk Island", label: "Norfolk Island" },
    { value: "Norway", label: "Norway" },
    { value: "Oman", label: "Oman" },
    { value: "Pakistan", label: "Pakistan" },
    { value: "Palau", label: "Palau" },
    { value: "Panama", label: "Panama" },
    { value: "Papua New Guinea", label: "Papua New Guinea" },
    { value: "Paraguay", label: "Paraguay" },
    { value: "Peru", label: "Peru" },
    { value: "Philippines", label: "Philippines" },
    { value: "Pitcairn", label: "Pitcairn" },
    { value: "Poland", label: "Poland" },
    { value: "Portugal", label: "Portugal" },
    { value: "Puerto Rico", label: "Puerto Rico" },
    { value: "Qatar", label: "Qatar" },
    { value: "Reunion", label: "Reunion" },
    { value: "Romania", label: "Romania" },
    { value: "Russian Federation", label: "Russian Federation" },
    { value: "RWANDA", label: "RWANDA" },
    { value: "Saint Helena", label: "Saint Helena" },
    { value: "Saint Lucia", label: "Saint Lucia" },
    { value: "Samoa", label: "Samoa" },
    { value: "San Marino", label: "San Marino" },
    { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
    { value: "Saudi Arabia", label: "Saudi Arabia" },
    { value: "Senegal", label: "Senegal" },
    { value: "Serbia and Montenegro", label: "Serbia and Montenegro" },
    { value: "Seychelles", label: "Seychelles" },
    { value: "Sierra Leone", label: "Sierra Leone" },
    { value: "Singapore", label: "Singapore" },
    { value: "Slovakia", label: "Slovakia" },
    { value: "Slovenia", label: "Slovenia" },
    { value: "Solomon Islands", label: "Solomon Islands" },
    { value: "Somalia", label: "Somalia" },
    { value: "South Africa", label: "South Africa" },
    { value: "Spain", label: "Spain" },
    { value: "Sri Lanka", label: "Sri Lanka" },
    { value: "Sudan", label: "Sudan" },
    { value: "Suriname", label: "Suriname" },
    { value: "Swaziland", label: "Swaziland" },
    { value: "Sweden", label: "Sweden" },
    { value: "Switzerland", label: "Switzerland" },
    { value: "Taiwan", label: "Taiwan" },
    { value: "Tajikistan", label: "Tajikistan" },
    { value: "Tanzania", label: "Tanzania" },
    { value: "Thailand", label: "Thailand" },
    { value: "Timor-Leste", label: "Timor-Leste" },
    { value: "Togo", label: "Togo" },
    { value: "Tokelau", label: "Tokelau" },
    { value: "Tonga", label: "Tonga" },
    { value: "Tunisia", label: "Tunisia" },
    { value: "Turkey", label: "Turkey" },
    { value: "Turkmenistan", label: "Turkmenistan" },
    { value: "Tuvalu", label: "Tuvalu" },
    { value: "Uganda", label: "Uganda" },
    { value: "Ukraine", label: "Ukraine" },
    { value: "United Arab Emirates", label: "United Arab Emirates" },
    { value: "United Kingdom", label: "United Kingdom" },
    { value: "United States", label: "United States" },
    { value: "Uruguay", label: "Uruguay" },
    { value: "Uzbekistan", label: "Uzbekistan" },
    { value: "Vanuatu", label: "Vanuatu" },
    { value: "Venezuela", label: "Venezuela" },
    { value: "Viet Nam", label: "Viet Nam" },
    { value: "Virgin Islands", label: "Virgin Islands" },
    { value: "Virgin Islands", label: "Virgin Islands" },
    { value: "Wallis and Futuna", label: "Wallis and Futuna" },
    { value: "Western Sahara", label: "Western Sahara" },
    { value: "Yemen", label: "Yemen" },
    { value: "Zambia", label: "Zambia" },
    { value: "Zimbabwe", label: "Zimbabwe" }
  ],
  dialCode:[
    { "value": "233", "label": "+233" },
    { "value": "234", "label": "+234" },
    { "value": "1", "label": "+1" },
    { "value": "20", "label": "+20" },
    { "value": "211", "label": "+211" },
    { "value": "212", "label": "+212" },
    { "value": "213", "label": "+213" },
    { "value": "216", "label": "+216" },
    { "value": "218", "label": "+218" },
    { "value": "220", "label": "+220" },
    { "value": "221", "label": "+221" },
    { "value": "222", "label": "+222" },
    { "value": "223", "label": "+223" },
    { "value": "224", "label": "+224" },
    { "value": "225", "label": "+225" },
    { "value": "226", "label": "+226" },
    { "value": "227", "label": "+227" },
    { "value": "228", "label": "+228" },
    { "value": "229", "label": "+229" },
    { "value": "230", "label": "+230" },
    { "value": "231", "label": "+231" },
    { "value": "232", "label": "+232" },
    { "value": "235", "label": "+235" },
    { "value": "236", "label": "+236" },
    { "value": "237", "label": "+237" },
    { "value": "238", "label": "+238" },
    { "value": "239", "label": "+239" },
    { "value": "240", "label": "+240" },
    { "value": "241", "label": "+241" },
    { "value": "242", "label": "+242" },
    { "value": "243", "label": "+243" },
    { "value": "244", "label": "+244" },
    { "value": "245", "label": "+245" },
    { "value": "246", "label": "+246" },
    { "value": "247", "label": "+247" },
    { "value": "248", "label": "+248" },
    { "value": "249", "label": "+249" },
    { "value": "250", "label": "+250" },
    { "value": "251", "label": "+251" },
    { "value": "252", "label": "+252" },
    { "value": "253", "label": "+253" },
    { "value": "254", "label": "+254" },
    { "value": "255", "label": "+255" },
    { "value": "256", "label": "+256" },
    { "value": "257", "label": "+257" },
    { "value": "258", "label": "+258" },
    { "value": "260", "label": "+260" },
    { "value": "261", "label": "+261" },
    { "value": "262", "label": "+262" },
    { "value": "263", "label": "+263" },
    { "value": "264", "label": "+264" },
    { "value": "265", "label": "+265" },
    { "value": "266", "label": "+266" },
    { "value": "267", "label": "+267" },
    { "value": "268", "label": "+268" },
    { "value": "269", "label": "+269" },
    { "value": "290", "label": "+290" },
    { "value": "291", "label": "+291" },
    { "value": "297", "label": "+297" },
    { "value": "298", "label": "+298" },
    { "value": "299", "label": "+299" },
    { "value": "350", "label": "+350" },
    { "value": "351", "label": "+351" },
    { "value": "352", "label": "+352" },
    { "value": "353", "label": "+353" },
    { "value": "354", "label": "+354" },
    { "value": "355", "label": "+355" },
    { "value": "356", "label": "+356" },
    { "value": "357", "label": "+357" },
    { "value": "358", "label": "+358" },
    { "value": "359", "label": "+359" },
    { "value": "370", "label": "+370" },
    { "value": "371", "label": "+371" },
    { "value": "372", "label": "+372" },
    { "value": "373", "label": "+373" },
    { "value": "374", "label": "+374" },
    { "value": "375", "label": "+375" },
    { "value": "376", "label": "+376" },
    { "value": "377", "label": "+377" },
    { "value": "378", "label": "+378" },
    { "value": "379", "label": "+379" },
    { "value": "380", "label": "+380" },
    { "value": "381", "label": "+381" },
    { "value": "382", "label": "+382" },
    { "value": "383", "label": "+383" },
    { "value": "385", "label": "+385" },
    { "value": "386", "label": "+386" },
    { "value": "387", "label": "+387" },
    { "value": "389", "label": "+389" },
    { "value": "420", "label": "+420" },
    { "value": "421", "label": "+421" },
    { "value": "423", "label": "+423" },
    { "value": "500", "label": "+500" },
    { "value": "501", "label": "+501" },
    { "value": "502", "label": "+502" },
    { "value": "503", "label": "+503" },
    { "value": "504", "label": "+504" },
    { "value": "505", "label": "+505" },
    { "value": "506", "label": "+506" },
    { "value": "507", "label": "+507" },
    { "value": "508", "label": "+508" },
    { "value": "509", "label": "+509" },
    { "value": "590", "label": "+590" },
    { "value": "591", "label": "+591" },
    { "value": "592", "label": "+592" },
    { "value": "593", "label": "+593" },
    { "value": "594", "label": "+594" },
    { "value": "595", "label": "+595" },
    { "value": "596", "label": "+596" },
    { "value": "597", "label": "+597" },
    { "value": "598", "label": "+598" },
    { "value": "599", "label": "+599" },
    { "value": "670", "label": "+670" },
    { "value": "672", "label": "+672" },
    { "value": "673", "label": "+673" },
    { "value": "674", "label": "+674" },
    { "value": "675", "label": "+675" },
    { "value": "676", "label": "+676" },
    { "value": "677", "label": "+677" },
    { "value": "678", "label": "+678" },
    { "value": "679", "label": "+679" },
    { "value": "680", "label": "+680" },
    { "value": "681", "label": "+681" },
    { "value": "682", "label": "+682" },
    { "value": "683", "label": "+683" },
    { "value": "684", "label": "+684" },
    { "value": "685", "label": "+685" },
    { "value": "686", "label": "+686" },
    { "value": "687", "label": "+687" },
    { "value": "688", "label": "+688" },
    { "value": "689", "label": "+689" },
    { "value": "690", "label": "+690" },
    { "value": "691", "label": "+691" },
    { "value": "692", "label": "+692" },
    { "value": "850", "label": "+850" },
    { "value": "852", "label": "+852" },
    { "value": "853", "label": "+853" },
    { "value": "855", "label": "+855" },
    { "value": "856", "label": "+856" },
    { "value": "880", "label": "+880" },
    { "value": "886", "label": "+886" },
    { "value": "960", "label": "+960" },
    { "value": "961", "label": "+961" },
    { "value": "962", "label": "+962" },
    { "value": "963", "label": "+963" },
    { "value": "964", "label": "+964" },
    { "value": "965", "label": "+965" },
    { "value": "966", "label": "+966" },
    { "value": "967", "label": "+967" },
    { "value": "968", "label": "+968" },
    { "value": "970", "label": "+970" },
    { "value": "971", "label": "+971" },
    { "value": "972", "label": "+972" },
    { "value": "973", "label": "+973" },
    { "value": "974", "label": "+974" },
    { "value": "975", "label": "+975" },
    { "value": "976", "label": "+976" },
    { "value": "977", "label": "+977" },
    { "value": "992", "label": "+992" },
    { "value": "993", "label": "+993" },
    { "value": "994", "label": "+994" },
    { "value": "995", "label": "+995" },
    { "value": "996", "label": "+996" },
    { "value": "998", "label": "+998" }
  ],
  
  allMonths: [
    { value: "January", label: "January" },
    { value: "February", label: "February" },
    { value: "March", label: "March" },
    { value: "April", label: "April" },
    { value: "May", label: "May" },
    { value: "June", label: "June" },
    { value: "July", label: "July" },
    { value: "August", label: "August" },
    { value: "September", label: "September" },
    { value: "October", label: "October" },
    { value: "November", label: "November" },
    { value: "Decembcer", label: "Decembcer" }
  ],
  region: [
    { "value": "Ashanti Region", "label": "Ashanti Region" },
    { "value": "Brong Ahafo Region", "label": "Brong Ahafo Region" },
    { "value": "Central Region", "label": "Central Region" },
    { "value": "Eastern Region", "label": "Eastern Region" },
    { "value": "Greater Accra Region", "label": "Greater Accra Region" },
    { "value": "Northern Region", "label": "Northern Region" },
    { "value": "Upper East Region", "label": "Upper East Region" },
    { "value": "Upper West Region", "label": "Upper West Region" },
    { "value": "Volta Region", "label": "Volta Region" },
    { "value": "Western Region", "label": "Western Region" },
    { "value": "Ahafo Region", "label": "Ahafo Region" },
    { "value": "Bono East Region", "label": "Bono East Region" },
    { "value": "Bono Region", "label": "Bono Region" },
    { "value": "North East Region", "label": "North East Region" },
    { "value": "Oti Region", "label": "Oti Region" },
    { "value": "Savannah Region", "label": "Savannah Region" },
    { "value": "Other", "label": "Other" }
  ],
  yesNo: [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" }
  ],
  ResultsStatus: [
    { value: "Pending", label: "Pending" },
    { value: "Completed", label: "Completed" },
    { value: "Uncompleted", label: "Uncompleted" },
  ],
  creditHours: [
    {
      label: 'Institution',
      items: [
        { value: 1, label: "1" },
        { value: 2, label: "2" },
        { value: 3, label: "3" },
        { value: 4, label: "4" },
        { value: 5, label: "5" },
        { value: 6, label: "6" },
        { value: 15, label: "15" },
        { value: 0, label: "None Scoring" },
      ]
    },
    {
      label: 'Coventry',
      items: [
        { value: 10, label: "10" },
        { value: 20, label: "20" },
        { value: 30, label: "30" },
        { value: 40, label: "40" },
        { value: 50, label: "50" },
        { value: 60, label: "60" },
      ]
    },
  ],
  courseType: [
    { value: "Core", label: "Core" },
    { value: "Elective", label: "Elective" },
    { value: "None Scoring", label: "None Scoring" }
  ],
  session: [
    { value: "Morning", label: "Morning" },
    { value: "Evening", label: "Evening" },
    { value: "Weekend", label: "Weekend" }
  ],
  studentType: [
    { value: "Regular", label: "Regular" },
    { value: "International", label: "International" }
  ],
  userType: [
    { value: "Staff", label: "Staff" },
    { value: "API", label: "API User" },
  ],
  currentSemester: [
    { value: 1, label: "First Semester" },
    { value: 2, label: "Second Semester" }
  ],
  currency: [
    { value: "GHS", label: "GHS" },
    { value: "USD", label: "USD" },
    { value: "GBP", label: "GBP" }
  ],
  studentType1: [
    { value: "Degree/Diploma", label: "Degree/Diploma" },
    { value: "Topup", label: "Topup" },
    { value: "Mature", label: "Mature" },
  ],
  studentType2: [
    { value: "Masters", label: "Master's" },
    { value: "PhD", label: "PhD" }
  ],
  resultsStatus: [
    { value: "Sent", label: "Sent" },
    { label: "Withdrawn", value: "Withdrawn" },
    { label: "Pending", value: "Pending" },
  ],
  
  // entry_level: [
  //   { value: 100, label: 100 },
  //   { value: 200, label: 200 },
  //   { value: 300, label: 300 },
  //   { value: 400, label: 400 },
  //   { value: 500, label: 500 },
  //   { value: 600, label: 600 },
  // ],

  entry_level: [
    {
      label: 'Institution',
      items: [
        { value: '', label: "All" },
      ]
    },
    {
      label: 'Undergraduate',
      items: [
        { value: '100', label: "L100" },
        { value: '200', label: "L200" },
        { value: '300', label: "L300" },
        { value: '400', label: "L400" },
      ]
    },
    {
      label: 'Masters (MSc)',
      items: [
        { value: '501', label: "L500" },
        { value: '601', label: "L600" },
      ]
    },
    {
      label: 'Masters (MBA)',
      items: [
        { value: '502', label: "L500" },
        { value: '602', label: "L600" },
      ]
    },
    {
      label: 'Masters (MA)',
      items: [
        { value: '503', label: "L500" },
        { value: '603', label: "L600" },
      ]
    },
    {
      label: 'Masters (MPhil)',
      items: [
        { value: '504', label: "L500" },
        { value: '604', label: "L600" },
      ]
    },
    {
      label: 'TNE',
      items: [
        { value: '505', label: "Coventry L500" },
        { value: '506', label: "Anhalt L500" },
       
      ]
    },
    {
      label: 'PhD',
      items: [
        { value: '701', label: "Year 1" },
        { value: '702', label: "Year 2" },
        { value: '703', label: "Year 3" },
        { value: '704', label: "Year 4" },
      ]
    },
   
  ],

  numeric_level: [
    // {
    //   label: 'Institution',
    //   items: [
    //     { value: '', label: "All" },
    //   ]
    // },
    {
      label: 'Undergraduate',
      items: [
        { value: 100, label: "L100" },
        { value: 200, label: "L200" },
        { value: 300, label: "L300" },
        { value: 400, label: "L400" },
      ]
    },
    {
      label: 'Masters (MSc)',
      items: [
        { value: 501, label: "L500" },
        { value: 601, label: "L600" },
      ]
    },
    {
      label: 'Masters (MBA)',
      items: [
        { value: 502, label: "L500" },
        { value: 602, label: "L600" },
      ]
    },
    {
      label: 'Masters (MA)',
      items: [
        { value: 503, label: "L500" },
        { value: 603, label: "L600" },
      ]
    },
    {
      label: 'Masters (MPhil)',
      items: [
        { value: 504, label: "L500" },
        { value: 604, label: "L600" },
      ]
    },
    {
      label: 'TNE',
      items: [
        { value: 505, label: "Coventry L500" },
        { value: 506, label: "Anhalt L500" },
       
      ]
    },
    {
      label: 'PhD',
      items: [
        { value: 701, label: "Year 1" },
        { value: 702, label: "Year 2" },
        { value: 703, label: "Year 3" },
        { value: 704, label: "Year 4" },
      ]
    },
   
  ],

  fees_entry_level: [
    { value: 'Degree/Diploma', label: 'Degree/Diploma' },
    { value: 'Topup', label: 'Topup' },
    { value: 'Mature', label: 'Mature' },
    { value: 'Masters', label: 'Masters' },
    { value: 'PhD', label: 'PhD' },
  ],

  formtype: [
    {value: 'Postgraduate', label: 'Postgraduate'},
    {value: 'Undergraduate', label: 'Undergraduate'}
  ],

 
   
  
  level: [
    // {
    //   label: 'Institution',
    //   items: [
    //     { value: '', label: "All" },
    //   ]
    // },
    {
      label: 'Undergraduate',
      items: [
        { value: '100', label: "L100" },
        { value: '200', label: "L200" },
        { value: '300', label: "L300" },
        { value: '400', label: "L400" },
      ]
    },
    {
      label: 'Masters (MSc)',
      items: [
        { value: '501', label: "L500" },
        { value: '601', label: "L600" },
      ]
    },
    {
      label: 'Masters (MBA)',
      items: [
        { value: '502', label: "L500" },
        { value: '602', label: "L600" },
      ]
    },
    {
      label: 'Masters (MA)',
      items: [
        { value: '503', label: "L500" },
        { value: '603', label: "L600" },
      ]
    },
    {
      label: 'Masters (MPhil)',
      items: [
        { value: '504', label: "L500" },
        { value: '604', label: "L600" },
      ]
    },
    {
      label: 'TNE',
      items: [
        { value: '505', label: "Coventry L500" },
        { value: '506', label: "Anhalt L500" },
       
      ]
    },
    {
      label: 'PhD',
      items: [
        { value: '701', label: "Year 1" },
        { value: '702', label: "Year 2" },
        { value: '703', label: "Year 3" },
        { value: '704', label: "Year 4" },
      ]
    },
   
  ],
  ug_applicationtypes: [
    { value: "Topup", label: "Topup" },
    { value: "Mature", label: "Mature" },
    { value: "Undergraduate", label: "Degree/Diploma" },
  ],
  pg_applicationtypes: [
    { value: "Postgraduate", label: "Postgraduate" },
  ],
  national_id_types: [
    { value: "Ghana Card", label: "Ghana Card" },
    { value: "Passport", label: "Passport" },
    { value: "Birth Certificate", label: "Birth Certificate" },
    { value: "NHIS", label: "NHIS" }
  ],
  application_titles: [
    { "value": "Mr", "label": "Mr." },
    { "value": "Mrs", "label": "Mrs." },
    { "value": "Ms", "label": "Ms." },
    { "value": "Messrs", "label": "Messrs." },
    { "value": "Dr", "label": "Dr." },
    { "value": "Prof", "label": "Prof." },
    { "value": "Esq", "label": "Esq." },
    { "value": "Hon", "label": "Hon." },
    { "value": "Jr", "label": "Jr. (Junior)" },
    { "value": "Rev", "label": "Rev." },
    { "value": "Rt. Hon", "label": "Rt. Hon." },
    { "value": "Sr", "label": "Sr." },
    { "value": "St", "label": "St." },
    { "value": "Other", "label": "Other" }
  ],
  disability: [
    {
      label: "Hearing Impairment",
      items: [
        { value: "Hearing Impaired", label: "Hearing Impaired" },
        { value: "Deaf", label: "Deaf" },
      ],
    },
    {
      label: "Mobility Physical Impairment",
      items: [
        { value: "Physically Handicapped", label: "Physically Handicapped" },
        { value: "Wheelchair Bound", label: "Wheelchair Bound" },
        { value: "Lame (In Crutches)", label: "Lame (In Crutches)" },
        { value: "Lame (On Crutches)", label: "Lame (On Crutches)" },
      ],
    },
    {
      label: "Speech or Language Impairment",
      items: [{ value: "Speech Impaired", label: "Speech Impaired" }],
    },
    {
      label: "Visual Impairment",
      items: [
        { value: "Blind", label: "Blind" },
        { value: "Partially Sighted", label: "Partially Sighted" },
      ],
    },
    {
      label: "Other Impairment",
      items: [{ value: "Other", label: "Other" }],
    },
  ],
  sponsorTypes: [
    { value: "Self", label: "Self" },
    { value: "Other", label: "Other" },
  ],
  shs_or_tertiary_countryItems: [
    { value: "In Ghana", label: "In Ghana" },
    { value: "Outside Ghana", label: "Outside Ghana" }
  ],
  shs_list: [
    {
      "region": "AHAFO",
      "district": "ASUNAFO NORTH MUNICIPAL",
      "label": "MIM SENIOR HIGH SCHOOL",
      "value": "MIM SENIOR HIGH SCHOOL",
      "location": "MIM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "mimshs@ges.gov.gh"
    },
    {
      "region": "AHAFO",
      "district": "ASUNAFO NORTH\r\nMUNICIPAL",
      "label": "AHAFOMAN SENIOR HIGH/TECH SCHOOL",
      "value": "AHAFOMAN SENIOR HIGH/TECH SCHOOL",
      "location": "GOASO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "ahafomanshst@ges.gov.gh"
    },
    {
      "region": "AHAFO",
      "district": "ASUNAFO SOUTH",
      "label": "KUKUOM AGRIC SENIOR HIGH SCHOOL",
      "value": "KUKUOM AGRIC SENIOR HIGH SCHOOL",
      "location": "KUKUOM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "kukuomagricshs@ges.gov.gh"
    },
    {
      "region": "AHAFO",
      "district": "ASUNAFO SOUTH",
      "label": "SANKORE SENIOR HIGH SCHOOL",
      "value": "SANKORE SENIOR HIGH SCHOOL",
      "location": "SANKORE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "sankoreshs@ges.gov.gh"
    },
    {
      "region": "AHAFO",
      "district": "ASUTIFI NORTH",
      "label": "GYAMFI KUMANINI SENIOR HIGH/TECH SCHOOL",
      "value": "GYAMFI KUMANINI SENIOR HIGH/TECH SCHOOL",
      "location": "WAMAHINSO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "gyamfikumaninishst@ges.gov.gh"
    },
    {
      "region": "AHAFO",
      "district": "ASUTIFI NORTH",
      "label": "OLA GIRLS SENIOR HIGH SCHOOL, KENYASI",
      "value": "OLA GIRLS SENIOR HIGH SCHOOL, KENYASI",
      "location": "KENYASI",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "olagirlsshs@ges.gov.gh"
    },
    {
      "region": "AHAFO",
      "district": "ASUTIFI SOUTH",
      "label": "ACHERENSUA SENIOR HIGH SCHOOL",
      "value": "ACHERENSUA SENIOR HIGH SCHOOL",
      "location": "ACHERENSUA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "acherensuashs@ges.gov.gh"
    },
    {
      "region": "AHAFO",
      "district": "ASUTIFI SOUTH",
      "label": "HWIDIEM SENIOR HIGH SCHOOL",
      "value": "HWIDIEM SENIOR HIGH SCHOOL",
      "location": "HWIDIEM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "hwidiemshs@ges.gov.gh"
    },
    {
      "region": "AHAFO",
      "district": "TANO NORTH",
      "label": "TERCHIRE SENIOR HIGH SCHOOL",
      "value": "TERCHIRE SENIOR HIGH SCHOOL",
      "location": "TERCHIRE",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "terchireshs@ges.gov.gh"
    },
    {
      "region": "AHAFO",
      "district": "TANO NORTH",
      "label": "YAMFO ANGLICAN SENIOR HIGH SCHOOL",
      "value": "YAMFO ANGLICAN SENIOR HIGH SCHOOL",
      "location": "YAMFO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "yamfoanglicanshs@ges.gov.gh"
    },
    {
      "region": "AHAFO",
      "district": "TANO NORTH",
      "label": "SERWAA KESSE GIRLS SENIOR HIGH SCHOOL",
      "value": "SERWAA KESSE GIRLS SENIOR HIGH SCHOOL",
      "location": "DUAYAW\r\nNKWANTA",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "serwaakessegirlsshs@ges.gov.gh"
    },
    {
      "region": "AHAFO",
      "district": "TANO NORTH",
      "label": "BOAKYE TROMO SENIOR HIGH/TECH SCHOOL",
      "value": "BOAKYE TROMO SENIOR HIGH/TECH SCHOOL",
      "location": "DUAYAW\r\nNKWANTA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "boakyetromoshst@ges.gov.gh"
    },
    {
      "region": "AHAFO",
      "district": "TANO NORTH",
      "label": "BOMAA COMM. SENIOR HIGH SCHOOL",
      "value": "BOMAA COMM. SENIOR HIGH SCHOOL",
      "location": "BOMAA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "bomaacommshs@ges.gov.gh"
    },
    {
      "region": "AHAFO",
      "district": "TANO SOUTH",
      "label": "DERMA COMM. DAY SENIOR HIGH SCHOOL",
      "value": "DERMA COMM. DAY SENIOR HIGH SCHOOL",
      "location": "DERMA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "dermacommshs@ges.gov.gh"
    },
    {
      "region": "AHAFO",
      "district": "TANO SOUTH",
      "label": "SAMUEL OTU PRESBY SENIOR HIGH SCHOOL",
      "value": "SAMUEL OTU PRESBY SENIOR HIGH SCHOOL",
      "location": "TECHIMANTIA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "samuelotupresbyshs@ges.gov.gh"
    },
    {
      "region": "AHAFO",
      "district": "TANO SOUTH",
      "label": "BECHEM PRESBY SENIOR HIGH SCHOOL",
      "value": "BECHEM PRESBY SENIOR HIGH SCHOOL",
      "location": "BECHEM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "bechempresbyshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "ADANSI NORTH",
      "label": "FOMENA T.I. AHMAD SENIOR HIGH SCHOOL",
      "value": "FOMENA T.I. AHMAD SENIOR HIGH SCHOOL",
      "location": "FOMENA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "t.i.ahmadiyyafomenashs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "ADANSI NORTH",
      "label": "ASARE BEDIAKO SENIOR HIGH SCHOOL",
      "value": "ASARE BEDIAKO SENIOR HIGH SCHOOL",
      "location": "AKROKERRI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "asarebediakoshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "ADANSI NORTH",
      "label": "DOMPOASE SENIOR HIGH SCHOOL",
      "value": "DOMPOASE SENIOR HIGH SCHOOL",
      "location": "DOMPOASE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "dompoaseshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "ADANSI NORTH",
      "label": "BODWESANGO SENIOR HIGH SCHOOL",
      "value": "BODWESANGO SENIOR HIGH SCHOOL",
      "location": "BODWESANGO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "bodwesangoshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "ADANSI SOUTH",
      "label": "AKROFUOM SENIOR HIGH/TECH SCHOOL",
      "value": "AKROFUOM SENIOR HIGH/TECH SCHOOL",
      "location": "AKROFUOM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "akrofuomshst@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "ADANSI SOUTH",
      "label": "NEW EDUBIASE SENIOR HIGH SCHOOL",
      "value": "NEW EDUBIASE SENIOR HIGH SCHOOL",
      "location": "NEW EDUBIASE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "newedubiaseshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "AFIGYA-KWABERE",
      "label": "OTUMFUO OSEI TUTU II COLLEGE",
      "value": "OTUMFUO OSEI TUTU II COLLEGE",
      "location": "TETREM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "otumfuooseitutu11shs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "AFIGYA-KWABERE",
      "label": "ADUMAN SENIOR HIGH SCHOOL",
      "value": "ADUMAN SENIOR HIGH SCHOOL",
      "location": "ADUMAN",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "adumanshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "AFIGYA-KWABRE",
      "label": "AFIGYAMAN SENIOR HIGH SCHOOL",
      "value": "AFIGYAMAN SENIOR HIGH SCHOOL",
      "location": "KYEKYEWERE",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "afigyamanshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "AFIGYA-KWABRE",
      "label": "ST. MICHAEL'S SENIOR HIGH SCHOOL, AHENKRO",
      "value": "ST. MICHAEL'S SENIOR HIGH SCHOOL, AHENKRO",
      "location": "AHENKRO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "st.michaelahenkroshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "AHAFO ANO NORTH",
      "label": "TEPA SENIOR HIGH SCHOOL",
      "value": "TEPA SENIOR HIGH SCHOOL",
      "location": "TEPA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "tepashs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "AHAFO ANO NORTH",
      "label": "MAABANG SENIOR HIGH/TECH SCHOOL",
      "value": "MAABANG SENIOR HIGH/TECH SCHOOL",
      "location": "MAABANG",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "maabangshst@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "AHAFO ANO SOUTH",
      "label": "SABRONUM METHODIST SENIOR HIGH/TECH\r\nSCHOOL",
      "value": "SABRONUM METHODIST SENIOR HIGH/TECH\r\nSCHOOL",
      "location": "SABRONUM/HABI\r\nTAT",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "sabronummethodistshst@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "AHAFO ANO SOUTH",
      "label": "ADUGYAMA COMM. SENIOR HIGH SCHOOL",
      "value": "ADUGYAMA COMM. SENIOR HIGH SCHOOL",
      "location": "ADUGYAMA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "adugyamacommshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "AHAFO ANO SOUTH",
      "label": "MANKRANSO SENIOR HIGH SCHOOL",
      "value": "MANKRANSO SENIOR HIGH SCHOOL",
      "location": "MANKRANSO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "mankransoshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "AMANSIE CENTRAL",
      "label": "TWEAPEASE SENIOR HIGH SCHOOL",
      "value": "TWEAPEASE SENIOR HIGH SCHOOL",
      "location": "TWEAPEASE",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "tweapeaseshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "AMANSIE CENTRAL",
      "label": "JACOBU SENIOR HIGH/TECH. SCHOOL",
      "value": "JACOBU SENIOR HIGH/TECH. SCHOOL",
      "location": "JACOBU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "jacobushst@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "AMANSIE WEST",
      "label": "ESAASE BONTEFUFUO SNR. HIGH/TECH. SCHOOL",
      "value": "ESAASE BONTEFUFUO SNR. HIGH/TECH. SCHOOL",
      "location": "ESAASE\r\nBONTEFUFUO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "esaasebontefufuoshst@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "AMANSIE WEST",
      "label": "MANSOMAN SENIOR HIGH SCHOOL",
      "value": "MANSOMAN SENIOR HIGH SCHOOL",
      "location": "MANSO ATWERE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "mansomanshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "AMANSIE WEST",
      "label": "MANSO-ADUBIA SENIOR HIGH SCHOOL",
      "value": "MANSO-ADUBIA SENIOR HIGH SCHOOL",
      "location": "ADUBIA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "manso-adubiashs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "ASANTE AKIM CENTRAL\r\nMUNICIPAL",
      "label": "WESLEY SENIOR HIGH SCHOOL, KONONGO",
      "value": "WESLEY SENIOR HIGH SCHOOL, KONONGO",
      "location": "KONONGO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "wesleykonongoshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "ASANTE AKIM CENTRAL\r\nMUNICIPAL",
      "label": "ST. MARY'S GIRL'S SENIOR HIGH, KONONGO",
      "value": "ST. MARY'S GIRL'S SENIOR HIGH, KONONGO",
      "location": "KONONGO",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "st.marygirlkonongoshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "ASANTE AKIM CENTRAL\r\nMUNICIPAL",
      "label": "KONONGO ODUMASE SENIOR HIGH SCHOOL",
      "value": "KONONGO ODUMASE SENIOR HIGH SCHOOL",
      "location": "ODUMASE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "konongoodumaseshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "ASANTE AKIM NORTH",
      "label": "AGOGO STATE COLLEGE",
      "value": "AGOGO STATE COLLEGE",
      "location": "AGOGO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "agogostatecollegeshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "ASANTE AKIM NORTH",
      "label": "COLLINS SENIOR HIGH/COMMERCIAL SCHOOL,\r\nAGOGO",
      "value": "COLLINS SENIOR HIGH/COMMERCIAL SCHOOL,\r\nAGOGO",
      "location": "AGOGO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "collincomshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "ASANTE AKIM NORTH",
      "label": "OWERRIMAN SENIOR HIGH SCHOOL",
      "value": "OWERRIMAN SENIOR HIGH SCHOOL",
      "location": "DOMEABRA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "owerrimanshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "ASANTE AKIM SOUTH",
      "label": "KUROFA METHODIST SENIOR HIGH SCHOOL",
      "value": "KUROFA METHODIST SENIOR HIGH SCHOOL",
      "location": "KUROFA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "kurofamethodistshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "ASANTE AKIM SOUTH",
      "label": "BOMPATA PRESBY SENIOR HIGH SCHOOL",
      "value": "BOMPATA PRESBY SENIOR HIGH SCHOOL",
      "location": "BOMPATA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "presbybompatashs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "ASANTE AKIM SOUTH",
      "label": "JUASO SENIOR HIGH/TECH SCHOOL",
      "value": "JUASO SENIOR HIGH/TECH SCHOOL",
      "location": "JUASO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "juasoshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "ASANTE AKIM SOUTH",
      "label": "JUBILEE SENIOR HIGH SCHOOL",
      "value": "JUBILEE SENIOR HIGH SCHOOL",
      "location": "DAMPONG",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "jubileeshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "ASANTE AKIM SOUTH",
      "label": "OFOASE SENIOR HIGH/TECH SCHOOL",
      "value": "OFOASE SENIOR HIGH/TECH SCHOOL",
      "location": "OFOASE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "ofoaseshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "ASANTE AKIM SOUTH",
      "label": "BANKA COMM. SENIOR HIGH SCHOOL",
      "value": "BANKA COMM. SENIOR HIGH SCHOOL",
      "location": "BANKA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "bankacommsshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "ASOKORE MAMPONG\r\nMUNICIPAL",
      "label": "PARKOSO COMM. SENIOR HIGH SCHOOL",
      "value": "PARKOSO COMM. SENIOR HIGH SCHOOL",
      "location": "PARKOSO",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "parkosocommshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "ASOKORE MAMPONG\r\nMUNICIPAL",
      "label": "KUMASI ACADEMY",
      "value": "KUMASI ACADEMY",
      "location": "ASOKORE\r\nMAMPONG",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "kumasiacademyshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "ASOKORE MAMPONG\r\nMUNICIPAL",
      "label": "SAKAFIA ISLAMIC SENIOR HIGH SCHOOL",
      "value": "SAKAFIA ISLAMIC SENIOR HIGH SCHOOL",
      "location": "SAWABA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "sakafiaislamicshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "ATWIMA KWANWOMA",
      "label": "AFUA KOBI AMPEM GIRLS' SENIOR HIGH SCHOOL",
      "value": "AFUA KOBI AMPEM GIRLS' SENIOR HIGH SCHOOL",
      "location": "TRABUOM",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "afuakobiampemgirlsshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "ATWIMA KWANWOMA",
      "label": "ATWIMA KWANWOMA SENIOR HIGH/TECH SCHOOL",
      "value": "ATWIMA KWANWOMA SENIOR HIGH/TECH SCHOOL",
      "location": "TREDE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "atwimakwanwomashst@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "ATWIMA KWANWOMA",
      "label": "KWANWOMA SENIOR HIGH SCHOOL",
      "value": "KWANWOMA SENIOR HIGH SCHOOL",
      "location": "ADUM\r\nKWANWOMA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "kwanwomashst@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "ATWIMA MPONUA",
      "label": "MPASATIA SENIOR HIGH/TECH SCHOOL",
      "value": "MPASATIA SENIOR HIGH/TECH SCHOOL",
      "location": "MPASATIA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "mpasatiashst@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "ATWIMA MPONUA",
      "label": "NYINAHIN CATH. SENIOR HIGH SCHOOL",
      "value": "NYINAHIN CATH. SENIOR HIGH SCHOOL",
      "location": "NYINAHIN",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "nyinahincathshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "ATWIMA MPONUA",
      "label": "ADOBEWORA COMM. SENIOR HIGH SCHOOL",
      "value": "ADOBEWORA COMM. SENIOR HIGH SCHOOL",
      "location": "ADOBEWORA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "adobeworacommshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "ATWIMA NWABIAGYA",
      "label": "BAREKESE SENIOR HIGH SCHOOL",
      "value": "BAREKESE SENIOR HIGH SCHOOL",
      "location": "BAREKESE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "barekeseshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "ATWIMA NWABIAGYA",
      "label": "OSEI TUTU SENIOR HIGH SCHOOL, AKROPONG",
      "value": "OSEI TUTU SENIOR HIGH SCHOOL, AKROPONG",
      "location": "AKROPONG",
      "gender": "MALE",
      "residency": "DAY/BOARDING",
      "email": "oseitutuakropongshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "ATWIMA NWABIAGYA",
      "label": "NKAWIE SENIOR HIGH/TECH SCHOOL",
      "value": "NKAWIE SENIOR HIGH/TECH SCHOOL",
      "location": "NKAWIE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "nkawieshst@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "ATWIMA NWABIAGYA",
      "label": "TOASE SENIOR HIGH SCHOOL",
      "value": "TOASE SENIOR HIGH SCHOOL",
      "location": "TOASE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "toaseshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "BEKWAI MUNICIPAL",
      "label": "OPPONG MEM. SENIOR HIGH SCHOOL",
      "value": "OPPONG MEM. SENIOR HIGH SCHOOL",
      "location": "KOKOFU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "oppongmemshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "BEKWAI MUNICIPAL",
      "label": "OFOASE KOKOBEN SENIOR HIGH SCHOOL",
      "value": "OFOASE KOKOBEN SENIOR HIGH SCHOOL",
      "location": "OFOASE\r\nKOKOBIN",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "ofoasekokobenshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "BEKWAI MUNICIPAL",
      "label": "S.D.A. SENIOR HIGH SCHOOL, BEKWAI",
      "value": "S.D.A. SENIOR HIGH SCHOOL, BEKWAI",
      "location": "BEKWAI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "sdabekwaishs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "BEKWAI MUNICIPAL",
      "label": "WESLEY HIGH SCHOOL, BEKWAI",
      "value": "WESLEY HIGH SCHOOL, BEKWAI",
      "location": "BEKWAI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "wesleyhighbekwaishs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "BEKWAI MUNICIPAL",
      "label": "ST. JOSEPH SENIOR HIGH/TECH SCHOOL, AHWIREN",
      "value": "ST. JOSEPH SENIOR HIGH/TECH SCHOOL, AHWIREN",
      "location": "AHWIREN",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "st.josephahwirenshst@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "BEKWAI MUNICIPAL",
      "label": "DENYASEMAN CATH.SENIOR HIGH SCHOOL",
      "value": "DENYASEMAN CATH.SENIOR HIGH SCHOOL",
      "location": "POANO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "denyasemancathshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "BOSOME FREHO",
      "label": "BOSOME SENIOR HIGH/TECH. SCHOOL",
      "value": "BOSOME SENIOR HIGH/TECH. SCHOOL",
      "location": "ASIWA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "bosomeshst@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "BOSOMTWE",
      "label": "ST. MICHAEL TECH/VOC INST",
      "value": "ST. MICHAEL TECH/VOC INST",
      "location": "PRAMSO",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "st.michaeltechvocinst@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "BOSOMTWE",
      "label": "JACHIE PRAMSO SENIOR HIGH SCHOOL",
      "value": "JACHIE PRAMSO SENIOR HIGH SCHOOL",
      "location": "JACHIE PRAMSO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "jachiepramsoshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "BOSOMTWE",
      "label": "ST. GEORGE'S SENIOR HIGH TECH SCHOOL",
      "value": "ST. GEORGE'S SENIOR HIGH TECH SCHOOL",
      "location": "KUNTANASE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "st.georgesshst@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "BOSOMTWE",
      "label": "BEPOSO SENIOR HIGH SCHOOL",
      "value": "BEPOSO SENIOR HIGH SCHOOL",
      "location": "BEPOSO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "bepososhs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "BOSOMTWE",
      "label": "OSEI ADUTWUM SENIOR HIGH SCHOOL",
      "value": "OSEI ADUTWUM SENIOR HIGH SCHOOL",
      "location": "JACHIE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "oseiadutwumshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "BOSOMTWE",
      "label": "BOSOMTWE OYOKO COMM. SENIOR HIGH SCHOOL",
      "value": "BOSOMTWE OYOKO COMM. SENIOR HIGH SCHOOL",
      "location": "BOSOMTWE\r\nOYOKO",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "bosomtweoyokocommshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "EJISU JUABEN",
      "label": "CHURCH OF CHRIST SENIOR HIGH SCHOOL",
      "value": "CHURCH OF CHRIST SENIOR HIGH SCHOOL",
      "location": "[NEAR] WOE",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "churchofchristshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "EJISU JUABEN",
      "label": "ST. SEBASTIAN CATH. SENIOR HIGH SCHOOL",
      "value": "ST. SEBASTIAN CATH. SENIOR HIGH SCHOOL",
      "location": "ADUMASA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "st.sebastiancathshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "EJISU JUABEN MUNICIPAL",
      "label": "JUABEN SENIOR HIGH SCHOOL",
      "value": "JUABEN SENIOR HIGH SCHOOL",
      "location": "JUABEN",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "juabenshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "EJISU JUABEN MUNICIPAL",
      "label": "BONWIRE SENIOR HIGH/TECH SCHOOL",
      "value": "BONWIRE SENIOR HIGH/TECH SCHOOL",
      "location": "BONWIRE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "bonwireshst@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "EJISU JUABEN MUNICIPAL",
      "label": "EJISUMAN SENIOR HIGH SCHOOL",
      "value": "EJISUMAN SENIOR HIGH SCHOOL",
      "location": "EJISU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "ejisumanshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "EJISU JUABEN MUNICIPAL",
      "label": "EJISU SENIOR HIGH/TECH SCHOOL",
      "value": "EJISU SENIOR HIGH/TECH SCHOOL",
      "location": "EJISU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "ejisushst@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "EJISU JUABEN MUNICIPAL",
      "label": "ACHINAKROM SENIOR HIGH SCHOOL",
      "value": "ACHINAKROM SENIOR HIGH SCHOOL",
      "location": "ACHINAKROM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "achinakromshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "EJISU ONWE",
      "label": "ONWE SENIOR HIGH SCHOOL",
      "value": "ONWE SENIOR HIGH SCHOOL",
      "location": "EJISU ONWE",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "onweshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "EJURA/SEKYEDUMASE",
      "label": "EJURAMAN ANGLICAN SENIOR HIGH SCHOOL",
      "value": "EJURAMAN ANGLICAN SENIOR HIGH SCHOOL",
      "location": "EJURA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "ejuramananglicanshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "EJURA/SEKYEDUMASE",
      "label": "SEKYEDUMASE SENIOR HIGH/TECH SCHOOL",
      "value": "SEKYEDUMASE SENIOR HIGH/TECH SCHOOL",
      "location": "SEKYEDUMASE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "sekyedumashst@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "EJURA/SEKYEDUMASE",
      "label": "AKWESI AWOBAA SENIOR HIGH SCHOOL",
      "value": "AKWESI AWOBAA SENIOR HIGH SCHOOL",
      "location": "ANYINASU",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "akwesiawobaashs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KUMASI METRO",
      "label": "PENTECOST SENIOR HIGH SCHOOL, KUMASI",
      "value": "PENTECOST SENIOR HIGH SCHOOL, KUMASI",
      "location": "BREMAN WEST,\r\nKUMASI",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "pentecostkumasishs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KUMASI METRO",
      "label": "OSEI KYERETWIE SENIOR HIGH SCHOOL",
      "value": "OSEI KYERETWIE SENIOR HIGH SCHOOL",
      "location": "OLD TAFO -\r\nKUMASI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "oseikyeretwieshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KUMASI METRO",
      "label": "AL-AZARIYA ISLAMIC SENIOR HIGH SCHOOL,\r\nKUMASI",
      "value": "AL-AZARIYA ISLAMIC SENIOR HIGH SCHOOL,\r\nKUMASI",
      "location": "OLD TAFO,\r\nKUMASI",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "al-azariyaislamicshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KUMASI METRO",
      "label": "KUMASI GIRLS SENIOR HIGH SCHOOL",
      "value": "KUMASI GIRLS SENIOR HIGH SCHOOL",
      "location": "ABREPO",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "kumasigirlsshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KUMASI METRO",
      "label": "UTHMANIYA SENIOR HIGH SCHOOL, TAFO",
      "value": "UTHMANIYA SENIOR HIGH SCHOOL, TAFO",
      "location": "TAFO",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "uthmaniyashs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KUMASI METRO",
      "label": "KUMASI WESLEY GIRLS HIGH SCHOOL",
      "value": "KUMASI WESLEY GIRLS HIGH SCHOOL",
      "location": "OLD TAFO -\r\nKUMASI",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "kumasiwesleygirlsshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KUMASI METRO",
      "label": "ASANTEMAN SENIOR HIGH SCHOOL",
      "value": "ASANTEMAN SENIOR HIGH SCHOOL",
      "location": "BANTAMA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "asantemanshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KUMASI METRO",
      "label": "ADVENTIST  SENIOR HIGH SCHOOL, KUMASI",
      "value": "ADVENTIST  SENIOR HIGH SCHOOL, KUMASI",
      "location": "BANTAMA-\r\nKUMASI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "adventistkumasishs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KUMASI METRO",
      "label": "SERWAAH NYARKO GIRLS' SENIOR HIGH SCHOOL",
      "value": "SERWAAH NYARKO GIRLS' SENIOR HIGH SCHOOL",
      "location": "DICHEMSO",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "serwaahnyarkogirlsshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KUMASI METRO",
      "label": "PREMPEH COLLEGE",
      "value": "PREMPEH COLLEGE",
      "location": "SOFOLINE-\r\nKUMASI",
      "gender": "MALE",
      "residency": "DAY/BOARDING",
      "email": "prempehshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KUMASI METRO",
      "label": "NURU-AMEEN ISLAMIC SENIOR HIGH SCHOOL,\r\nASEWASE",
      "value": "NURU-AMEEN ISLAMIC SENIOR HIGH SCHOOL,\r\nASEWASE",
      "location": "ASEWASE",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "nuru-ameenislamicshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KUMASI METRO",
      "label": "TAWHEED SENIOR HIGH SCHOOL",
      "value": "TAWHEED SENIOR HIGH SCHOOL",
      "location": "KUMASI-\r\nAKWATIA LINE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "tawheedshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KUMASI METRO",
      "label": "YAA ASANTEWAA GIRLS SENIOR HIGH SCHOOL",
      "value": "YAA ASANTEWAA GIRLS SENIOR HIGH SCHOOL",
      "location": "TANOSO-KUMASI",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "yaaasantewaagirlsshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KUMASI METRO",
      "label": "PRINCE OF PEACE GIRLS",
      "value": "PRINCE OF PEACE GIRLS",
      "location": "KWADASO\r\nESTATES",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "princeofpeacegirlsshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KUMASI METRO",
      "label": "ST. LOUIS SENIOR HIGH SCHOOL, KUMASI",
      "value": "ST. LOUIS SENIOR HIGH SCHOOL, KUMASI",
      "location": "ODUOM-KUMASI",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "st.louiskumasishs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KUMASI METRO",
      "label": "ARMED FORCES SENIOR HIGH/TECH SCHOOL,\r\nKUMASI",
      "value": "ARMED FORCES SENIOR HIGH/TECH SCHOOL,\r\nKUMASI",
      "location": "BANTAMA-\r\nKUMASI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "armedforceskumasishst@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KUMASI METRO",
      "label": "KUMASI TECH. INST.",
      "value": "KUMASI TECH. INST.",
      "location": "KUMASI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "kumasitechinst@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KUMASI METRO",
      "label": "ANGLICAN SENIOR HIGH SCHOOL, KUMASI",
      "value": "ANGLICAN SENIOR HIGH SCHOOL, KUMASI",
      "location": "ASEM-KUMASI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "anglicankumasishs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KUMASI METRO",
      "label": "KNUST SENIOR HIGH SCHOOL",
      "value": "KNUST SENIOR HIGH SCHOOL",
      "location": "KNUST",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "knustshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KUMASI METRO",
      "label": "T. I. AHMADIYYA SENIOR HIGH SCHOOL, KUMASI",
      "value": "T. I. AHMADIYYA SENIOR HIGH SCHOOL, KUMASI",
      "location": "KUMASI -\r\nSTADIUM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "t.i.ahmadiyyakumasishs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KUMASI METRO",
      "label": "KUMASI SENIOR HIGH/TECH SCHOOL",
      "value": "KUMASI SENIOR HIGH/TECH SCHOOL",
      "location": "PATASI-KUMASI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "kumasishst@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KUMASI METRO",
      "label": "AGRIC NZEMA SENIOR HIGH SCHOOL, KUMASI",
      "value": "AGRIC NZEMA SENIOR HIGH SCHOOL, KUMASI",
      "location": "KUMASI, AGRIC\r\nNZEMA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "agricnzemashs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KUMASI METRO",
      "label": "OPOKU WARE SENIOR HIGH SCHOOL",
      "value": "OPOKU WARE SENIOR HIGH SCHOOL",
      "location": "SANTASI-KUMASI",
      "gender": "MALE",
      "residency": "DAY/BOARDING",
      "email": "opokuwareshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KUMASI METRO",
      "label": "ST. HUBERT SEM/SENIOR HIGH SCHOOL, KUMASI",
      "value": "ST. HUBERT SEM/SENIOR HIGH SCHOOL, KUMASI",
      "location": "SANTASI-KUMASI",
      "gender": "MALE",
      "residency": "DAY/BOARDING",
      "email": "st.hubertsemshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KUMASI METRO",
      "label": "KUMASI SENIOR HIGH SCHOOL",
      "value": "KUMASI SENIOR HIGH SCHOOL",
      "location": "GYINYASE-\r\nKUMASI",
      "gender": "MALE",
      "residency": "DAY/BOARDING",
      "email": "kumasishs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KUMASI METRO.",
      "label": "ISLAMIC SENIOR HIGH SCHOOL, AMPABAME",
      "value": "ISLAMIC SENIOR HIGH SCHOOL, AMPABAME",
      "location": "AMPABAME",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "islamicampabameshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KUMASI METRO.",
      "label": "METHODIST TECHNINCAL  INSTITUTE",
      "value": "METHODIST TECHNINCAL  INSTITUTE",
      "location": "KWADASO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "methodisttechinst@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KWABRE EAST",
      "label": "ADVENTIST GIRLS SENIOR HIGH SCHOOL, NTONSO",
      "value": "ADVENTIST GIRLS SENIOR HIGH SCHOOL, NTONSO",
      "location": "NTONSO",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "adventistgirlsshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KWABRE EAST",
      "label": "ADANWOMASE SENIOR HIGH SCHOOL",
      "value": "ADANWOMASE SENIOR HIGH SCHOOL",
      "location": "ADANWOMASE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "adanwomaseshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KWABRE EAST",
      "label": "GYAAMA PENSAN SENIOR HIGH/TECH SCHOOL",
      "value": "GYAAMA PENSAN SENIOR HIGH/TECH SCHOOL",
      "location": "ABOASO-\r\nASHANTI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "gyaamapensanshst@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KWABRE EAST",
      "label": "KOFI ADJEI SENIOR HIGH/TECH SCHOOL",
      "value": "KOFI ADJEI SENIOR HIGH/TECH SCHOOL",
      "location": "BAMPENASE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "kofiadjeishst@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KWABRE EAST",
      "label": "SIMMS SENIOR HIGH/COM. SCHOOL",
      "value": "SIMMS SENIOR HIGH/COM. SCHOOL",
      "location": "FAWOADE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "simmscomshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "KWABRE EAST",
      "label": "ANTOA SENIOR HIGH SCHOOL",
      "value": "ANTOA SENIOR HIGH SCHOOL",
      "location": "ANTOA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "antoashs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "MAMPONG MUNICIPAL",
      "label": "OPOKU AGYEMAN SENIOR HIGH/TECH SCHOOL",
      "value": "OPOKU AGYEMAN SENIOR HIGH/TECH SCHOOL",
      "location": "APAAH",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "opokuagyemanshst@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "MAMPONG MUNICIPAL",
      "label": "KOFIASE ADVENTIST SENIOR HIGH/TECH. SCHOOL",
      "value": "KOFIASE ADVENTIST SENIOR HIGH/TECH. SCHOOL",
      "location": "KOFIASE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "kofiaseadventistshs.@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "MAMPONG MUNICIPAL",
      "label": "AMANIAMPONG SENIOR HIGH SCHOOL",
      "value": "AMANIAMPONG SENIOR HIGH SCHOOL",
      "location": "MAMPONG",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "amaniampongshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "MAMPONG MUNICIPAL",
      "label": "ST. JOSEPH SEM/SENIOR HIGH SCHOOL, MAMPONG",
      "value": "ST. JOSEPH SEM/SENIOR HIGH SCHOOL, MAMPONG",
      "location": "MAMPONG",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "st.josephsemshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "MAMPONG MUNICIPAL",
      "label": "ST. MONICA'S SENIOR HIGH SCHOOL, MAMPONG",
      "value": "ST. MONICA'S SENIOR HIGH SCHOOL, MAMPONG",
      "location": "MAMPONG",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "st.monicasmampongshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "NKAWIE",
      "label": "SPIRITAN SENIOR HIGH SCHOOL",
      "value": "SPIRITAN SENIOR HIGH SCHOOL",
      "location": "ADANKWAME",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "spiritanshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "OBUASI MUNICIPAL",
      "label": "CHRIST THE KING CATH., OBUASI",
      "value": "CHRIST THE KING CATH., OBUASI",
      "location": "OBUASI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "christthekingcathshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "OBUASI MUNICIPAL",
      "label": "OBUASI SENIOR HIGH/TECH SCHOOL",
      "value": "OBUASI SENIOR HIGH/TECH SCHOOL",
      "location": "OBUASI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "obuasishst@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "OFFINSO MUNICIPAL",
      "label": "ST. JEROME SENIOR HIGH SCHOOL, ABOFOUR",
      "value": "ST. JEROME SENIOR HIGH SCHOOL, ABOFOUR",
      "location": "ABOFOUR",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "st.jeromeshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "OFFINSO MUNICIPAL",
      "label": "NAMONG SENIOR HIGH/TECH SCHOOL",
      "value": "NAMONG SENIOR HIGH/TECH SCHOOL",
      "location": "NAMONG",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "namongshst@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "OFFINSO MUNICIPAL",
      "label": "DWAMENA AKENTEN SENIOR HIGH SCHOOL",
      "value": "DWAMENA AKENTEN SENIOR HIGH SCHOOL",
      "location": "OFFINSO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "dwamenaakentenshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "OFFINSO NORTH",
      "label": "WIAFE AKENTEN PRESBY SENIOR HIGH SCHOOL",
      "value": "WIAFE AKENTEN PRESBY SENIOR HIGH SCHOOL",
      "location": "AFRANCHO",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "wiafeakentenpresbyshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "OFFINSO NORTH",
      "label": "AKUMADAN SENIOR HIGH SCHOOL",
      "value": "AKUMADAN SENIOR HIGH SCHOOL",
      "location": "AKUMADAN",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "akumadanshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "OFFINSO NORTH",
      "label": "NKENKANSU COMMUNITY SENIOR HIGH SCHOOL",
      "value": "NKENKANSU COMMUNITY SENIOR HIGH SCHOOL",
      "location": "NKENKANSU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "nkenkansucommshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "OFFINSO NORTH",
      "label": "ASUOSO COMM. SENIOR HIGH SCHOOL",
      "value": "ASUOSO COMM. SENIOR HIGH SCHOOL",
      "location": "ASUOSO",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "asuosocommshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "SEKYERE CENTRAL",
      "label": "NSUTAMAN CATH. SENIOR HIGH SCHOOL",
      "value": "NSUTAMAN CATH. SENIOR HIGH SCHOOL",
      "location": "NSUTA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "nsutamancathshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "SEKYERE CENTRAL",
      "label": "GHANA MUSLIM MISSION SENIOR HIGH SCHOOL",
      "value": "GHANA MUSLIM MISSION SENIOR HIGH SCHOOL",
      "location": "BEPOSO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "ghanamuslimmissionshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "SEKYERE CENTRAL",
      "label": "PRESBY SENIOR HIGH/TECH SCHOOL, KWAMANG",
      "value": "PRESBY SENIOR HIGH/TECH SCHOOL, KWAMANG",
      "location": "KWAMANG",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "presbykwamangshst@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "SEKYERE EAST",
      "label": "TIJJANIYA SENIOR HIGH SCHOOL",
      "value": "TIJJANIYA SENIOR HIGH SCHOOL",
      "location": "ASOKORE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "tijjaniyashs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "SEKYERE EAST",
      "label": "T. I. AHMADIYYA GIRL'S SENIOR HIGH SCHOOL,\r\nASOKORE",
      "value": "T. I. AHMADIYYA GIRL'S SENIOR HIGH SCHOOL,\r\nASOKORE",
      "location": "ASOKORE",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "t.i.ahmadiyyagirlsshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "SEKYERE EAST",
      "label": "KROBEA ASANTE TECH/VOC SCHOOL",
      "value": "KROBEA ASANTE TECH/VOC SCHOOL",
      "location": "ASOKORE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "krobeaasantetechvoc@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "SEKYERE EAST",
      "label": "EFFIDUASE SENIOR HIGH/COM SCHOOL",
      "value": "EFFIDUASE SENIOR HIGH/COM SCHOOL",
      "location": "EFFIDUASE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "effiduaseshst@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "SEKYERE EAST",
      "label": "EFFIDUASE SENIOR HIGH/TECH SCHOOL",
      "value": "EFFIDUASE SENIOR HIGH/TECH SCHOOL",
      "location": "AKYAWKROM",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "effiduaseshst@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "SEKYERE KUMAWU",
      "label": "BANKOMAN SENIOR HIGH SCHOOL",
      "value": "BANKOMAN SENIOR HIGH SCHOOL",
      "location": "BANKO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "bankomanshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "SEKYERE KUMAWU",
      "label": "TWENEBOA KODUA SENIOR HIGH SCHOOL",
      "value": "TWENEBOA KODUA SENIOR HIGH SCHOOL",
      "location": "KUMAWU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "tweneboakoduashs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "SEKYERE KUMAWU",
      "label": "DADEASE AGRIC SENIOR HIGH SCHOOL",
      "value": "DADEASE AGRIC SENIOR HIGH SCHOOL",
      "location": "DADEASE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "dadeaseagricshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "SEKYERE KUMAWU",
      "label": "BODOMASE SENIOR HIGH/TECH SCHOOL",
      "value": "BODOMASE SENIOR HIGH/TECH SCHOOL",
      "location": "BODOMASE",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "bodomaseshst@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "SEKYERE SOUTH",
      "label": "OKOMFO ANOKYE SENIOR HIGH SCHOOL",
      "value": "OKOMFO ANOKYE SENIOR HIGH SCHOOL",
      "location": "WIAMOASE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "okomfoanokyeshs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "SEKYERE SOUTH",
      "label": "ADU GYAMFI SENIOR HIGH SCHOOL",
      "value": "ADU GYAMFI SENIOR HIGH SCHOOL",
      "location": "JAMASI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "adugyamfishs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "SEKYERE SOUTH",
      "label": "S.D.A. SENIOR HIGH SCHOOL, AGONA",
      "value": "S.D.A. SENIOR HIGH SCHOOL, AGONA",
      "location": "AGONA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "sdaagonashs@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "SEKYERE SOUTH",
      "label": "AGONA SENIOR HIGH/TECH SCHOOL",
      "value": "AGONA SENIOR HIGH/TECH SCHOOL",
      "location": "AGONA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "agonashst@ges.gov.gh"
    },
    {
      "region": "ASHANTI",
      "district": "SEKYERE SOUTH",
      "label": "KONADU YIADOM CATHOLIC SENIOR HIGH SCHOOL",
      "value": "KONADU YIADOM CATHOLIC SENIOR HIGH SCHOOL",
      "location": "ASAMANG",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "konaduyiadomcatholicshs@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "BANDA",
      "label": "BANDAMAN SENIOR HIGH SCHOOL",
      "value": "BANDAMAN SENIOR HIGH SCHOOL",
      "location": "BANDA\r\nAHENKRO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "bandamanshs@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "BEREKUM-MUNICIPAL",
      "label": "BEREKUM PRESBY SENIOR HIGH SCHOOL",
      "value": "BEREKUM PRESBY SENIOR HIGH SCHOOL",
      "location": "BEREKUM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "presbyberekumshs@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "BEREKUM-MUNICIPAL",
      "label": "BEREKUM SENIOR HIGH SCHOOL",
      "value": "BEREKUM SENIOR HIGH SCHOOL",
      "location": "BEREKUM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "berekumshs@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "BEREKUM-MUNICIPAL",
      "label": "JINIJINI SENIOR HIGH SCHOOL",
      "value": "JINIJINI SENIOR HIGH SCHOOL",
      "location": "JINIJINI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "jinijinishs@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "BEREKUM-MUNICIPAL",
      "label": "ST. AUGUSTINE SENIOR HIGH SCHOOL, NSAPOR-\r\nBEREKUM",
      "value": "ST. AUGUSTINE SENIOR HIGH SCHOOL, NSAPOR-\r\nBEREKUM",
      "location": "NSAPOR-\r\nBEREKUM",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "st.augustineberekumshs@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "BEREKUM-MUNICIPAL",
      "label": "METHODIST SENIOR HIGH/TECH SCHOOL,BIADAN",
      "value": "METHODIST SENIOR HIGH/TECH SCHOOL,BIADAN",
      "location": "BIADAN",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "methodistbiadanshst@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "DORMAA CENTRAL\r\nMUNICIPAL",
      "label": "DORMAA SENIOR HIGH SCHOOL",
      "value": "DORMAA SENIOR HIGH SCHOOL",
      "location": "DORMAA\r\nAHENKRO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "dormaashs@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "DORMAA CENTRAL\r\nMUNICIPAL",
      "label": "SALVATION ARMY SENIOR HIGH SCHOOL, ABOABO\r\nDORMAA",
      "value": "SALVATION ARMY SENIOR HIGH SCHOOL, ABOABO\r\nDORMAA",
      "location": "ABOABO-\r\nDORMAA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "salvationarmydormaashs@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "DORMAA EAST",
      "label": "MANSEN SENIOR HIGH SCHOOL",
      "value": "MANSEN SENIOR HIGH SCHOOL",
      "location": "WAMFIE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "mansenshs@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "DORMAA EAST",
      "label": "WAMANAFO SENIOR HIGH/TECH SCHOOL",
      "value": "WAMANAFO SENIOR HIGH/TECH SCHOOL",
      "location": "WAMANAFO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "wamanafoshst@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "DORMAA WEST",
      "label": "NKRANKWANTA COMM SENIOR HIGH SCHOOL",
      "value": "NKRANKWANTA COMM SENIOR HIGH SCHOOL",
      "location": "NKRANKWANTA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "nkrankwantacommshs@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "JAMAN NORTH",
      "label": "ST. ANN'S GIRLS SENIOR HIGH SCHOOL, SAMPA",
      "value": "ST. ANN'S GIRLS SENIOR HIGH SCHOOL, SAMPA",
      "location": "SAMPA",
      "gender": "FEMALE",
      "residency": "DAY",
      "email": "st.annsgirlsshs@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "JAMAN NORTH",
      "label": "NAFANA SENIOR HIGH SCHOOL",
      "value": "NAFANA SENIOR HIGH SCHOOL",
      "location": "SAMPA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "nafanashs@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "JAMAN NORTH",
      "label": "SUMAMAN SENIOR HIGH SCHOOL",
      "value": "SUMAMAN SENIOR HIGH SCHOOL",
      "location": "SUMA-AHENKRO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "sumamanshs@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "JAMAN NORTH",
      "label": "DIAMONO SENIOR HIGH SCHOOL",
      "value": "DIAMONO SENIOR HIGH SCHOOL",
      "location": "DUADASO NO.2",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "diamonoshs@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "JAMAN NORTH",
      "label": "GOKA SENIOR HIGH/TECH SCHOOL",
      "value": "GOKA SENIOR HIGH/TECH SCHOOL",
      "location": "GOKA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "gokashst@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "JAMAN NORTH",
      "label": "DUADASO NO. 1 SENIOR HIGH/TECH SCHOOL",
      "value": "DUADASO NO. 1 SENIOR HIGH/TECH SCHOOL",
      "location": "DUADASO",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "duadasono.1shs@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "JAMAN SOUTH",
      "label": "DROBO SENIOR HIGH SCHOOL",
      "value": "DROBO SENIOR HIGH SCHOOL",
      "location": "DROBO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "droboshs@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "JAMAN SOUTH",
      "label": "OUR LADY OF PROVIDENCE SENIOR HIGH SCHOOL",
      "value": "OUR LADY OF PROVIDENCE SENIOR HIGH SCHOOL",
      "location": "KWASI\r\nBUOKROM",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "ourladyofprovidenceshs@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "SUNYANI MUNICIPAL",
      "label": "S.D.A SENIOR HIGH SCHOOL, SUNYANI",
      "value": "S.D.A SENIOR HIGH SCHOOL, SUNYANI",
      "location": "SUNYANI",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "sdasunyanishs@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "SUNYANI MUNICIPAL",
      "label": "TWENE AMANFO SENIOR HIGH/TECH SCHOOL",
      "value": "TWENE AMANFO SENIOR HIGH/TECH SCHOOL",
      "location": "SUNYANI",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "tweneamanfoshst@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "SUNYANI MUNICIPAL",
      "label": "SUNYANI METHODIST TECHNICAL INST.",
      "value": "SUNYANI METHODIST TECHNICAL INST.",
      "location": "SUNYANI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "sunyanimethodisttechinst@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "SUNYANI MUNICIPAL",
      "label": "ST. JAMES SEM & SENIOR HIGH SCHOOL, ABESIM",
      "value": "ST. JAMES SEM & SENIOR HIGH SCHOOL, ABESIM",
      "location": "ABESIM",
      "gender": "MALE",
      "residency": "DAY/BOARDING",
      "email": "st.jamessemshs@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "SUNYANI MUNICIPAL",
      "label": "SUNYANI SENIOR HIGH SCHOOL",
      "value": "SUNYANI SENIOR HIGH SCHOOL",
      "location": "SUNYANI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "sunyanishs@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "SUNYANI WEST",
      "label": "SACRED HEART SENIOR HIGH SCHOOL, NSOATRE",
      "value": "SACRED HEART SENIOR HIGH SCHOOL, NSOATRE",
      "location": "NSOATRE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "sacredheartshs@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "SUNYANI WEST",
      "label": "CHIRAA SENIOR HIGH SCHOOL",
      "value": "CHIRAA SENIOR HIGH SCHOOL",
      "location": "CHIRAA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "chiraashs@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "SUNYANI WEST",
      "label": "ODOMASEMAN SENIOR HIGH SCHOOL",
      "value": "ODOMASEMAN SENIOR HIGH SCHOOL",
      "location": "ODOMASE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "odomasemanshs@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "SUNYANI WEST",
      "label": "DON BOSCO VOC./TECH. INST.",
      "value": "DON BOSCO VOC./TECH. INST.",
      "location": "ODUMASI",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "donboscotechvocinst@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "SUNYANI WEST",
      "label": "NOTRE DAME GIRLS SENIOR HIGH SCHOOL,\r\nSUNYANI",
      "value": "NOTRE DAME GIRLS SENIOR HIGH SCHOOL,\r\nSUNYANI",
      "location": "SUNYANI",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "notredamegirlsshs@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "TAIN",
      "label": "MENJI SENIOR HIGH SCHOOL",
      "value": "MENJI SENIOR HIGH SCHOOL",
      "location": "MENJI",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "menjishs@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "TAIN",
      "label": "NSAWKAW STATE SENIOR HIGH SCHOOL",
      "value": "NSAWKAW STATE SENIOR HIGH SCHOOL",
      "location": "NSAWKAW",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "nsawkawstateshs@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "TAIN",
      "label": "NKORANMAN SENIOR HIGH SCHOOL",
      "value": "NKORANMAN SENIOR HIGH SCHOOL",
      "location": "SEIKWA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "nkoranmanshs@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "TAIN",
      "label": "BADU SENIOR HIGH/TECH SCHOOL",
      "value": "BADU SENIOR HIGH/TECH SCHOOL",
      "location": "BADU/WENCHI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "badushst@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "WENCHI MUNICIPAL",
      "label": "NKYERAA SENIOR HIGH SCHOOL",
      "value": "NKYERAA SENIOR HIGH SCHOOL",
      "location": "NCHIRAA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "nkyeraashs@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "WENCHI MUNICIPAL",
      "label": "WENCHI METH. SENIOR HIGH SCHOOL",
      "value": "WENCHI METH. SENIOR HIGH SCHOOL",
      "location": "WENCHI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "wenchimethshs@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "WENCHI MUNICIPAL",
      "label": "KOASE SENIOR HIGH/TECH SCHOOL",
      "value": "KOASE SENIOR HIGH/TECH SCHOOL",
      "location": "KOASE/WENCHI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "koaseshst@ges.gov.gh"
    },
    {
      "region": "BONO",
      "district": "WENCHI MUNICIPAL",
      "label": "ISTIQUAAMA SENIOR HIGH SCHOOL",
      "value": "ISTIQUAAMA SENIOR HIGH SCHOOL",
      "location": "WENCHI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "istiquaamashs@ges.gov.gh"
    },
    {
      "region": "BONO EAST",
      "district": "ATEBUBU-AMANTIN",
      "label": "ATEBUBU SENIOR HIGH SCHOOL",
      "value": "ATEBUBU SENIOR HIGH SCHOOL",
      "location": "ATEBUBU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "atebubushs@ges.gov.gh"
    },
    {
      "region": "BONO EAST",
      "district": "ATEBUBU-AMANTIN",
      "label": "NEW KROKOMPE COMM. SENIOR HIGH SCHOOL",
      "value": "NEW KROKOMPE COMM. SENIOR HIGH SCHOOL",
      "location": "NEW\r\nKONKROMPE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "newkrokompecommshs@ges.gov.gh"
    },
    {
      "region": "BONO EAST",
      "district": "ATEBUBU-AMANTIN",
      "label": "AMANTEN SENIOR HIGH SCHOOL",
      "value": "AMANTEN SENIOR HIGH SCHOOL",
      "location": "AMANTEN",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "amantenshs@ges.gov.gh"
    },
    {
      "region": "BONO EAST",
      "district": "KINTAMPO NORTH",
      "label": "NEW LONGORO COMM SENIOR HIGH SCHOOL\r\n(DEGA)",
      "value": "NEW LONGORO COMM SENIOR HIGH SCHOOL\r\n(DEGA)",
      "location": "NEW LONGORO",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "newlongorocommshs@ges.gov.gh"
    },
    {
      "region": "BONO EAST",
      "district": "KINTAMPO NORTH\r\nMUNICIPAL",
      "label": "KINTAMPO SENIOR HIGH SCHOOL",
      "value": "KINTAMPO SENIOR HIGH SCHOOL",
      "location": "KINTAMPO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "kintamposhs@ges.gov.gh"
    },
    {
      "region": "BONO EAST",
      "district": "KINTAMPO SOUTH",
      "label": "JEMA SENIOR HIGH SCHOOL",
      "value": "JEMA SENIOR HIGH SCHOOL",
      "location": "JEMA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "jemashs@ges.gov.gh"
    },
    {
      "region": "BONO EAST",
      "district": "NKORANZA",
      "label": "NKORANZA TECH INST.",
      "value": "NKORANZA TECH INST.",
      "location": "NKORANZA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "nkoranzatechinst@ges.gov.gh"
    },
    {
      "region": "BONO EAST",
      "district": "NKORANZA NORTH",
      "label": "OSEI BONSU SENIOR HIGH SCHOOL",
      "value": "OSEI BONSU SENIOR HIGH SCHOOL",
      "location": "YEFRI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "oseibonsushs@ges.gov.gh"
    },
    {
      "region": "BONO EAST",
      "district": "NKORANZA NORTH",
      "label": "BUSUNYA SENIOR HIGH SCHOOL",
      "value": "BUSUNYA SENIOR HIGH SCHOOL",
      "location": "BUSUNYA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "busunyashs@ges.gov.gh"
    },
    {
      "region": "BONO EAST",
      "district": "NKORANZA SOUTH",
      "label": "KWABRE SENIOR HIGH SCHOOL",
      "value": "KWABRE SENIOR HIGH SCHOOL",
      "location": "AKUMA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "kwabreshs@ges.gov.gh"
    },
    {
      "region": "BONO EAST",
      "district": "NKORANZA SOUTH",
      "label": "NKORANZA SENIOR HIGH/TECH SCHOOL",
      "value": "NKORANZA SENIOR HIGH/TECH SCHOOL",
      "location": "NKORANZA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "nkoranzashst@ges.gov.gh"
    },
    {
      "region": "BONO EAST",
      "district": "PRU",
      "label": "YEJI SENIOR HIGH/TECH SCHOOL",
      "value": "YEJI SENIOR HIGH/TECH SCHOOL",
      "location": "YEJI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "yejishst@ges.gov.gh"
    },
    {
      "region": "BONO EAST",
      "district": "PRU",
      "label": "PRANG SENIOR HIGH",
      "value": "PRANG SENIOR HIGH",
      "location": "PRANG",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "prangshs@ges.gov.gh"
    },
    {
      "region": "BONO EAST",
      "district": "PRU",
      "label": "ABEASEMAN COMM. DAY SENIOR HIGH SCHOOL",
      "value": "ABEASEMAN COMM. DAY SENIOR HIGH SCHOOL",
      "location": "ABEASE",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "abeasemancommshs@ges.gov.gh"
    },
    {
      "region": "BONO EAST",
      "district": "SENE EAST",
      "label": "BASSA COMMUNITY SENIOR HIGH SCHOOL",
      "value": "BASSA COMMUNITY SENIOR HIGH SCHOOL",
      "location": "BODINKA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "bassacommunityshs@ges.gov.gh"
    },
    {
      "region": "BONO EAST",
      "district": "SENE EAST",
      "label": "KAJAJI SENIOR HIGH SCHOOL",
      "value": "KAJAJI SENIOR HIGH SCHOOL",
      "location": "KAJAJI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "kajajishs@ges.gov.gh"
    },
    {
      "region": "BONO EAST",
      "district": "SENE WEST",
      "label": "KWAME DANSO SENIOR HIGH/TECH SCHOOL",
      "value": "KWAME DANSO SENIOR HIGH/TECH SCHOOL",
      "location": "KWAME DANSO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "kwamedansoshst@ges.gov.gh"
    },
    {
      "region": "BONO EAST",
      "district": "TECHIMAN MUNICIPAL",
      "label": "GYARKO COMM. DAY SENIOR HIGH SCHOOL",
      "value": "GYARKO COMM. DAY SENIOR HIGH SCHOOL",
      "location": "TECHIMAN",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "gyarkocommshs@ges.gov.gh"
    },
    {
      "region": "BONO EAST",
      "district": "TECHIMAN MUNICIPAL",
      "label": "KESSE BASAHYIA SENIOR HIGH SCHOOL",
      "value": "KESSE BASAHYIA SENIOR HIGH SCHOOL",
      "location": "TECHIMAN\r\nOFORIKROM",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "kessebasahyiashs@ges.gov.gh"
    },
    {
      "region": "BONO EAST",
      "district": "TECHIMAN MUNICIPAL",
      "label": "TECHIMAN SENIOR HIGH SCHOOL",
      "value": "TECHIMAN SENIOR HIGH SCHOOL",
      "location": "TECHIMAN",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "techimanshs@ges.gov.gh"
    },
    {
      "region": "BONO EAST",
      "district": "TECHIMAN MUNICIPAL",
      "label": "OUR LADY OF MOUNT CARMEL GIRLS SENIOR HIGH\r\nSCHOOL, TECHIMAN",
      "value": "OUR LADY OF MOUNT CARMEL GIRLS SENIOR HIGH\r\nSCHOOL, TECHIMAN",
      "location": "TECHIMAN",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "ourladyofmountcarmelgirlsshs@ges.gov.gh"
    },
    {
      "region": "BONO EAST",
      "district": "TECHIMAN MUNICIPAL",
      "label": "YEBOAH ASUAMAH SENIOR HIGH SCHOOL",
      "value": "YEBOAH ASUAMAH SENIOR HIGH SCHOOL",
      "location": "NSUTA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "yeboahasuamahshs@ges.gov.gh"
    },
    {
      "region": "BONO EAST",
      "district": "TECHIMAN MUNICIPAL",
      "label": "KWARTENG ANKOMAH SENIOR HIGH SCHOOL",
      "value": "KWARTENG ANKOMAH SENIOR HIGH SCHOOL",
      "location": "TANOSO-\r\nTECHMIAN",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "kwartengankomahshs@ges.gov.gh"
    },
    {
      "region": "BONO EAST",
      "district": "TECHIMAN NORTH",
      "label": "GUAKRO EFFAH SENIOR HIGH SCHOOL",
      "value": "GUAKRO EFFAH SENIOR HIGH SCHOOL",
      "location": "OFFUMAN",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "guakroeffahshs@ges.gov.gh"
    },
    {
      "region": "BONO EAST",
      "district": "TECHIMAN NORTH",
      "label": "ST. FRANCIS SEMINARY/SENIOR HIGH SCHOOL,\r\nBUOYEM",
      "value": "ST. FRANCIS SEMINARY/SENIOR HIGH SCHOOL,\r\nBUOYEM",
      "location": "BUOYEM",
      "gender": "MALE",
      "residency": "DAY/BOARDING",
      "email": "st.francisseminaryshs@ges.gov.gh"
    },
    {
      "region": "BONO EAST",
      "district": "TECHIMAN NORTH",
      "label": "TUOBODOM SENIOR HIGH/TECH SCHOOL",
      "value": "TUOBODOM SENIOR HIGH/TECH SCHOOL",
      "location": "TUOBODOM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "tuobodomshst@ges.gov.gh"
    },
    {
      "region": "BONO EAST",
      "district": "TECHIMAN NORTH",
      "label": "AMEYAW AKUMFI SENIOR HIGH/TECH SCHOOL",
      "value": "AMEYAW AKUMFI SENIOR HIGH/TECH SCHOOL",
      "location": "AWOROWA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "ameyawakumfishst@ges.gov.gh"
    },
    {
      "region": "BONO EAST",
      "district": "TECHIMAN NORTH",
      "label": "KROBO COMM.SENIOR HIGH SCHOOL",
      "value": "KROBO COMM.SENIOR HIGH SCHOOL",
      "location": "KROBO",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "krobocommshs@ges.gov.gh"
    },
    {
      "region": "BONO EAST",
      "district": "TANO NORTH",
      "label": "ABRAFI SENIOR HIGH SCHOOL",
      "value": "ABRAFI SENIOR HIGH SCHOOL",
      "location": "TECHIMAN",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "abrafishs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "ABURA/ASEBU/ KWAMAN",
      "label": "ASUANSI TECH. INST.",
      "value": "ASUANSI TECH. INST.",
      "location": "ASUANSI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "asuansitechinst@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "ABURA/ASEBU/\r\nKWAMANKESE",
      "label": "ABURAMAN SENIOR HIGH SCHOOL",
      "value": "ABURAMAN SENIOR HIGH SCHOOL",
      "location": "ABURA DUNKWA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "aburamanshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "ABURA/ASEBU/\r\nKWAMANKESE",
      "label": "ABAKRAMPA SENIOR HIGH/TECH SCHOOL",
      "value": "ABAKRAMPA SENIOR HIGH/TECH SCHOOL",
      "location": "ABAKRAMPA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "abakrampashst@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "ABURA/ASEBU/\r\nKWAMANKESE",
      "label": "MOREE COMM. SENIOR HIGH SCHOOL",
      "value": "MOREE COMM. SENIOR HIGH SCHOOL",
      "location": "MOREE",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "moreecommshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "ABURA/ASEBU/\r\nKWAMANKESE",
      "label": "AGGREY MEM. A.M.E.ZION SENIOR HIGH SCHOOL",
      "value": "AGGREY MEM. A.M.E.ZION SENIOR HIGH SCHOOL",
      "location": "CAPE COAST",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "aggreymemamezionshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "AGONA EAST",
      "label": "NSABA PRESBY SENIOR HIGH SCHOOL",
      "value": "NSABA PRESBY SENIOR HIGH SCHOOL",
      "location": "NSABA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "presbynsabashs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "AGONA EAST",
      "label": "AGONA NAMONWORA COMM.SENIOR HIGH SCHOOL",
      "value": "AGONA NAMONWORA COMM.SENIOR HIGH SCHOOL",
      "location": "AGONA\r\nNAMONWORA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "agonanamonworacommshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "AGONA EAST",
      "label": "KWANYAKO SENIOR HIGH SCHOOL",
      "value": "KWANYAKO SENIOR HIGH SCHOOL",
      "location": "KWANYAKO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "kwanyakoshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "AGONA EAST",
      "label": "SWEDRU SENIOR HIGH SCHOOL",
      "value": "SWEDRU SENIOR HIGH SCHOOL",
      "location": "SWEDRU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "swedrushs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "AGONA WEST MUNICIPAL",
      "label": "SIDDIQ SENIOR HIGH SCHOOL",
      "value": "SIDDIQ SENIOR HIGH SCHOOL",
      "location": "AGONA\r\nNYAKROM",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "siddiqshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "AGONA WEST MUNICIPAL",
      "label": "NYAKROM SENIOR HIGH TECH SCHOOL",
      "value": "NYAKROM SENIOR HIGH TECH SCHOOL",
      "location": "NYAKROM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "nyakromshst@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "AGONA WEST MUNICIPAL",
      "label": "AGONA FANKOBAA SENIOR HIGH SCHOOL",
      "value": "AGONA FANKOBAA SENIOR HIGH SCHOOL",
      "location": "AGONA\r\nFANKOBAA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "agonafankobaashs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "AGONA WEST MUNICIPAL",
      "label": "SWEDRU SCH. OF BUSINESS",
      "value": "SWEDRU SCH. OF BUSINESS",
      "location": "SWEDRU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "swedruschofbusiness@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "AJUMAKO/ ENYAN/ESIAM",
      "label": "BISEASE SENIOR HIGH/COMM. SCHOOL",
      "value": "BISEASE SENIOR HIGH/COMM. SCHOOL",
      "location": "BISEASE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "biseaseshscomshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "AJUMAKO/ ENYAN/ESIAM",
      "label": "ENYAN DENKYIRA SENIOR HIGH SCHOOL",
      "value": "ENYAN DENKYIRA SENIOR HIGH SCHOOL",
      "location": "DENKYIRA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "enyandenkyirashs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "AJUMAKO/ ENYAN/ESIAM",
      "label": "MANDO SENIOR HIGH/TECH SCHOOL",
      "value": "MANDO SENIOR HIGH/TECH SCHOOL",
      "location": "MANDO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "mandoshst@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "AJUMAKO/ ENYAN/ESIAM",
      "label": "ENYAN MAIM COMM. DAY SENIOR HIGH SCHOOL",
      "value": "ENYAN MAIM COMM. DAY SENIOR HIGH SCHOOL",
      "location": "ENYAN MAIM",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "enyanmaimcommshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "AJUMAKO/ENYAN /ESSIAM",
      "label": "ENYAN-ABAASA TECHNICAL INSTITUTE",
      "value": "ENYAN-ABAASA TECHNICAL INSTITUTE",
      "location": "ENYAN-ABAASA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "enyanabaasatechinst@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "ASIKUMA/ODOBEN/\r\nBRAKWA",
      "label": "BRAKWA SENIOR HIGH/TECH SCHOOL",
      "value": "BRAKWA SENIOR HIGH/TECH SCHOOL",
      "location": "BRAKWA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "brakwashst@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "ASIKUMA/ODOBEN/\r\nBRAKWA",
      "label": "ODOBEN SENIOR HIGH SCHOOL",
      "value": "ODOBEN SENIOR HIGH SCHOOL",
      "location": "ODOBEN",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "odobenshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "ASIKUMA/ODOBEN/\r\nBRAKWA",
      "label": "BREMAN ASIKUMA SENIOR HIGH SCHOOL",
      "value": "BREMAN ASIKUMA SENIOR HIGH SCHOOL",
      "location": "BREMAN\r\nASIKUMA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "bremanasikumashs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "ASSIN NORTH MUNICIPAL",
      "label": "ASSIN NORTH SENIOR HIGH/TECH SCHOOL",
      "value": "ASSIN NORTH SENIOR HIGH/TECH SCHOOL",
      "location": "ASSIN\r\nASEMPANEYE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "assinnorthshst@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "ASSIN NORTH MUNICIPAL",
      "label": "ASSIN STATE COLLEGE",
      "value": "ASSIN STATE COLLEGE",
      "location": "ASSIN BEREKU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "assinstateshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "ASSIN NORTH MUNICIPAL",
      "label": "GYAASE COMMUNITY SENIOR HIGH SCHOOL",
      "value": "GYAASE COMMUNITY SENIOR HIGH SCHOOL",
      "location": "ASSIN-\r\nAKONFUDI",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "gyaasecommunityshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "ASSIN NORTH MUNICIPAL",
      "label": "OBIRI YEBOAH SENIOR HIGH/TECHNICAL SCHOOL",
      "value": "OBIRI YEBOAH SENIOR HIGH/TECHNICAL SCHOOL",
      "location": "ASSIN FOSU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "obiriyeboahshst@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "ASSIN SOUTH",
      "label": "ASSIN NSUTA SENIOR HIGH SCHOOL",
      "value": "ASSIN NSUTA SENIOR HIGH SCHOOL",
      "location": "ASSIN NSUTA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "assinnsutaagricshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "ASSIN SOUTH",
      "label": "ASSIN MANSO SENIOR HIGH SCHOOL",
      "value": "ASSIN MANSO SENIOR HIGH SCHOOL",
      "location": "ASSIN MANSO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "assinmansoshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "ASSIN SOUTH",
      "label": "ADANKWAMAN SENIOR HIGH SCHOOL",
      "value": "ADANKWAMAN SENIOR HIGH SCHOOL",
      "location": "ASSIN DARMANG",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "adankwamanshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "ASSIN SOUTH",
      "label": "NYANKUMASE AHENKRO SENIOR HIGH SCHOOL",
      "value": "NYANKUMASE AHENKRO SENIOR HIGH SCHOOL",
      "location": "NYANKUMASE\r\nAHENKRO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "nyankumaseahenkroshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "AWUTU SENYA",
      "label": "BONTRASE SENIOR HIGH TECH. SCHOOL",
      "value": "BONTRASE SENIOR HIGH TECH. SCHOOL",
      "location": "JEI KRODUA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "bontraseshst@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "AWUTU/SENYA",
      "label": "AWUTU BAWJIASE COMM SENIOR HIGH SCHOOL",
      "value": "AWUTU BAWJIASE COMM SENIOR HIGH SCHOOL",
      "location": "BAWJIASE",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "awutubawjiasecommshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "AWUTU/SENYA",
      "label": "OBRACHIRE SENIOR HIGH/TECH SCHOOL",
      "value": "OBRACHIRE SENIOR HIGH/TECH SCHOOL",
      "location": "OBRAKYERE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "obrachireshst@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "AWUTU/SENYA",
      "label": "ODUPONG COMM. DAY SCHOOL",
      "value": "ODUPONG COMM. DAY SCHOOL",
      "location": "ODUPONG-\r\nKASOA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "odupongcommshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "AWUTU/SENYA",
      "label": "AWUTU WINTON SENIOR HIGH SCHOOL",
      "value": "AWUTU WINTON SENIOR HIGH SCHOOL",
      "location": "AWUTU",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "awutuwintonshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "AWUTU/SENYA",
      "label": "SENYA SENIOR HIGH SCHOOL",
      "value": "SENYA SENIOR HIGH SCHOOL",
      "location": "SENYA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "senyashs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "CAPE COAST",
      "label": "CAPE COAST TECH. INST.",
      "value": "CAPE COAST TECH. INST.",
      "location": "CAPE COAST",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "capecoasttechinst@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "CAPE COAST METRO",
      "label": "EFFUTU SENIOR HIGH/TECH SCHOOL",
      "value": "EFFUTU SENIOR HIGH/TECH SCHOOL",
      "location": "CAPE COAST",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "effutushst@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "CAPE COAST METRO",
      "label": "ACADEMY OF CHRIST THE KING, CAPE COAST",
      "value": "ACADEMY OF CHRIST THE KING, CAPE COAST",
      "location": "CAPE COAST",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "academyofchristthekingshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "CAPE COAST METRO",
      "label": "WESLEY GIRLS SENIOR HIGH SCHOOL, CAPE COAST",
      "value": "WESLEY GIRLS SENIOR HIGH SCHOOL, CAPE COAST",
      "location": "CAPE COAST",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "wesleygirlscapecoastshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "CAPE COAST METRO",
      "label": "GHANA NATIONAL COLLEGE",
      "value": "GHANA NATIONAL COLLEGE",
      "location": "CAPE COAST",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "ghananationalshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "CAPE COAST METRO",
      "label": "UNIVERSITY PRACTICE SENIOR HIGH SCHOOL",
      "value": "UNIVERSITY PRACTICE SENIOR HIGH SCHOOL",
      "location": "CAPE COAST",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "universitypracticeshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "CAPE COAST METRO",
      "label": "HOLY CHILD SCHOOL, CAPE COAST",
      "value": "HOLY CHILD SCHOOL, CAPE COAST",
      "location": "CAPE COAST",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "holychildsch@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "CAPE COAST METRO",
      "label": "ADISADEL COLLEGE",
      "value": "ADISADEL COLLEGE",
      "location": "CAPE COAST",
      "gender": "MALE",
      "residency": "DAY/BOARDING",
      "email": "adisadelshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "CAPE COAST METRO",
      "label": "OGUAA SENIOR HIGH/TECH SCHOOL",
      "value": "OGUAA SENIOR HIGH/TECH SCHOOL",
      "location": "CAPE COAST",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "oguaashst@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "CAPE COAST METRO",
      "label": "MFANTSIPIM SCHOOL",
      "value": "MFANTSIPIM SCHOOL",
      "location": "CAPE COAST",
      "gender": "MALE",
      "residency": "DAY/BOARDING",
      "email": "mfantsipimshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "CAPE COAST METRO",
      "label": "ST. AUGUSTINE'S COLLEGE, CAPE COAST",
      "value": "ST. AUGUSTINE'S COLLEGE, CAPE COAST",
      "location": "CAPE COAST",
      "gender": "MALE",
      "residency": "DAY/BOARDING",
      "email": "st.augustinesshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "EFFUTU MUNICIPAL",
      "label": "WINNEBA SENIOR HIGH SCHOOL",
      "value": "WINNEBA SENIOR HIGH SCHOOL",
      "location": "WINNEBA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "winnebashs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "EKUMFI",
      "label": "EKUMFI T. I. AHMADIIYYA SENIOR HIGH SCHOOL",
      "value": "EKUMFI T. I. AHMADIIYYA SENIOR HIGH SCHOOL",
      "location": "ESAKYIR",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "ekumfittiahmadiyyashs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "EKUMFI",
      "label": "J.E.A. MILLS SENIOR HIGH SCHOOL",
      "value": "J.E.A. MILLS SENIOR HIGH SCHOOL",
      "location": "OTUAM",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "jeamillsshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "EKUMFI CENTRAL",
      "label": "AKYIN SENIOR HIGH SCHOOL",
      "value": "AKYIN SENIOR HIGH SCHOOL",
      "location": "MBROBOTO",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "akyinshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "GOMOA CENTRAL",
      "label": "OGYEEDOM COMM SENIOR HIGH/TECH SCHOOL",
      "value": "OGYEEDOM COMM SENIOR HIGH/TECH SCHOOL",
      "location": "GOMOA AFRANSI",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "ogyeedomcommshst@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "GOMOA EAST",
      "label": "ST. GREGORY CATHOLIC SENIOR HIGH SCHOOL",
      "value": "ST. GREGORY CATHOLIC SENIOR HIGH SCHOOL",
      "location": "BUDUBURAM",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "stgregorycatholicshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "GOMOA EAST",
      "label": "GOMOA GYAMAN SENIOR HIGH SCHOOL",
      "value": "GOMOA GYAMAN SENIOR HIGH SCHOOL",
      "location": "GOMOA GYAMAN",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "gomoagyamanshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "GOMOA EAST",
      "label": "POTSIN T.I. AHM. SENIOR HIGH SCHOOL",
      "value": "POTSIN T.I. AHM. SENIOR HIGH SCHOOL",
      "location": "POTSIN",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "potsintiahmadiyyashs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "GOMOA EAST",
      "label": "FETTEHMAN SENIOR HIGH SCHOOL",
      "value": "FETTEHMAN SENIOR HIGH SCHOOL",
      "location": "GOMOA FETTEH",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "fettehmanshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "GOMOA WEST",
      "label": "COLLEGE OF MUSIC SENIOR HIGH SCHOOL,\r\nMOZANO",
      "value": "COLLEGE OF MUSIC SENIOR HIGH SCHOOL,\r\nMOZANO",
      "location": "MOZANO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "collegeofmusicshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "GOMOA WEST",
      "label": "MOZANO SENIOR HIGH SCHOOL",
      "value": "MOZANO SENIOR HIGH SCHOOL",
      "location": "MOZANO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "mozanoshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "GOMOA WEST",
      "label": "GOMOA SENIOR HIGH/TECH SCHOOL",
      "value": "GOMOA SENIOR HIGH/TECH SCHOOL",
      "location": "DAWURAMPON",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "gomoashst@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "GOMOA WEST",
      "label": "APAM SENIOR HIGH SCHOOL",
      "value": "APAM SENIOR HIGH SCHOOL",
      "location": "APAM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "apamshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "KOMENDA/EDINA/EGUAFO/A\r\nBIREM MUNICIPAL",
      "label": "EGUAFO-ABREM SENIOR HIGH SCHOOL",
      "value": "EGUAFO-ABREM SENIOR HIGH SCHOOL",
      "location": "EGUAFO ABIREM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "eguafoabremshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "KOMENDA/EDINA/EGUAFO/A\r\nBIREM MUNICIPAL",
      "label": "EDINAMAN SENIOR HIGH SCHOOL",
      "value": "EDINAMAN SENIOR HIGH SCHOOL",
      "location": "ELMINA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "edinamanshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "KOMENDA/EDINA/EGUAFO/A\r\nBIREM MUNICIPAL",
      "label": "KOMENDA SENIOR HIGH/TECH SCHOOL",
      "value": "KOMENDA SENIOR HIGH/TECH SCHOOL",
      "location": "KOMENDA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "komendashst@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "MFANTSIMAN MUNICIPAL",
      "label": "ABEADZE STATE COLLEGE",
      "value": "ABEADZE STATE COLLEGE",
      "location": "ABEADZE\r\nDOMEABRA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "abeadzestateshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "MFANTSIMAN MUNICIPAL",
      "label": "MANKESSIM SENIOR HIGH/TECH SCHOOL",
      "value": "MANKESSIM SENIOR HIGH/TECH SCHOOL",
      "location": "MANKESIM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "mankessimshst@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "MFANTSIMAN MUNICIPAL",
      "label": "MFANTSIMAN GIRLS SENIOR HIGH SCHOOL",
      "value": "MFANTSIMAN GIRLS SENIOR HIGH SCHOOL",
      "location": "SALTPOND",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "mfantsimangirlsshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "MFANTSIMAN MUNICIPAL",
      "label": "METHODIST HIGH SCHOOL,SALTPOND",
      "value": "METHODIST HIGH SCHOOL,SALTPOND",
      "location": "SALTPOND",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "methodisthighsaltpondshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "MFANTSIMAN MUNICIPAL",
      "label": "KWEGYIR AGGREY SENIOR HIGH SCHOOL",
      "value": "KWEGYIR AGGREY SENIOR HIGH SCHOOL",
      "location": "ANOMABO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "kwegyiraggreyshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "TWIFO ATI-MORKWA",
      "label": "TWIFO PRASO SENIOR HIGH SCHOOL",
      "value": "TWIFO PRASO SENIOR HIGH SCHOOL",
      "location": "TWIFO PRASO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "twifoprasoshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "TWIFO HEMANG LOWER\r\nDENKYIRA",
      "label": "TWIFO HEMANG SENIOR HIGH/TECH SCHOOL",
      "value": "TWIFO HEMANG SENIOR HIGH/TECH SCHOOL",
      "location": "HEMAN",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "twifohemangshst@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "TWIFO HEMANG LOWER\r\nDENKYIRA",
      "label": "JUKWA SENIOR HIGH SCHOOL",
      "value": "JUKWA SENIOR HIGH SCHOOL",
      "location": "JUKWA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "jukwashs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "TWIFU ATTI-MOKWAA",
      "label": "MOKWAA SENIOR HIGH SCHOOL",
      "value": "MOKWAA SENIOR HIGH SCHOOL",
      "location": "NAMOO",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "mokwaashs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "UPPER DENKYIRA EAST\r\nMUNICIPAL",
      "label": "DUNKWA SENIOR HIGH/TECH SCHOOL",
      "value": "DUNKWA SENIOR HIGH/TECH SCHOOL",
      "location": "DUNKWA-ON-\r\nOFFIN",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "dunkwaseshst@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "UPPER DENKYIRA EAST\r\nMUNICIPAL",
      "label": "BOA-AMPONSEM SENIOR HIGH SCHOOL",
      "value": "BOA-AMPONSEM SENIOR HIGH SCHOOL",
      "location": "DUNKWA-ON-\r\nOFFIN",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "boa-amponsemshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "UPPER DENKYIRA WEST",
      "label": "DIASO SENIOR HIGH SCHOOL",
      "value": "DIASO SENIOR HIGH SCHOOL",
      "location": "DIASO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "diasoshs@ges.gov.gh"
    },
    {
      "region": "CENTRAL",
      "district": "UPPER DENKYIRA WEST",
      "label": "AYANFURI SENIOR HIGH SCHOOL",
      "value": "AYANFURI SENIOR HIGH SCHOOL",
      "location": "AYANFURI",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "ayanfurishs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "AFRAM PLAINS (KWAHU\r\nNORTH)",
      "label": "DONKORKROM AGRIC SENIOR HIGH SCHOOL",
      "value": "DONKORKROM AGRIC SENIOR HIGH SCHOOL",
      "location": "DONKORKROM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "donkorkromagricshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "AFRAM PLAINS (KWAHU\r\nNORTH)",
      "label": "MEM-CHEMFRE COMM. SENIOR HIGH SCHOOL",
      "value": "MEM-CHEMFRE COMM. SENIOR HIGH SCHOOL",
      "location": "MEM-CHEMFRE",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "memchemfrecommshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "AFRAM PLAINS (KWAHU\r\nSOUTH)",
      "label": "MAAME KROBO COMM. SENIOR HIGH SCHOOL",
      "value": "MAAME KROBO COMM. SENIOR HIGH SCHOOL",
      "location": "MAAME KROBO",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "maamekrobocommshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "AFRAM PLAINS (KWAHU\r\nSOUTH)",
      "label": "ST. FIDELIS SENIOR HIGH/TECH SCHOOL",
      "value": "ST. FIDELIS SENIOR HIGH/TECH SCHOOL",
      "location": "TEASE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "stfidelisshst@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "AKUAPEM NORTH",
      "label": "J.G. KNOL VOC. TECH. INST.",
      "value": "J.G. KNOL VOC. TECH. INST.",
      "location": "ADUKROM AKW.",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "jgknoltechvocinst@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "AKWAPIM NORTH",
      "label": "NIFA SENIOR HIGH SCHOOL",
      "value": "NIFA SENIOR HIGH SCHOOL",
      "location": "ADUKROM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "nifashs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "AKWAPIM NORTH",
      "label": "PRESBY SENIOR HIGH/TECH SCHOOL, ADUKROM",
      "value": "PRESBY SENIOR HIGH/TECH SCHOOL, ADUKROM",
      "location": "ADUKROM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "presbyadukromshst@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "AKWAPIM NORTH",
      "label": "H'MOUNT SINAI SENIOR HIGH SCHOOL",
      "value": "H'MOUNT SINAI SENIOR HIGH SCHOOL",
      "location": "AKROPONG",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "hmountsinaishs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "AKWAPIM NORTH",
      "label": "OKUAPEMAN SENIOR HIGH SCHOOL",
      "value": "OKUAPEMAN SENIOR HIGH SCHOOL",
      "location": "AKROPONG",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "okuapemanshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "AKWAPIM NORTH",
      "label": "MANGOASE SENIOR HIGH SCHOOL",
      "value": "MANGOASE SENIOR HIGH SCHOOL",
      "location": "MANGOASE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "mangoaseshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "AKWAPIM NORTH",
      "label": "BENKUM SENIOR HIGH SCHOOL",
      "value": "BENKUM SENIOR HIGH SCHOOL",
      "location": "LARTEH-\r\nAKWAPIM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "benkumshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "AKWAPIM NORTH",
      "label": "METHODIST GIRLS SENIOR HIGH SCHOOL, MAMFE",
      "value": "METHODIST GIRLS SENIOR HIGH SCHOOL, MAMFE",
      "location": "MAMFE",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "methodistgirlsmamfeshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "AKWAPIM NORTH",
      "label": "PRESBY  SENIOR HIGH/TECH SCHOOL, LARTEH",
      "value": "PRESBY  SENIOR HIGH/TECH SCHOOL, LARTEH",
      "location": "LARTEH-\r\nKUBEASE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "presbylartehshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "AKWAPIM NORTH",
      "label": "MAMPONG/AKW SENIOR HIGH/TECH SCHOOL FOR\r\nTHE DEAF",
      "value": "MAMPONG/AKW SENIOR HIGH/TECH SCHOOL FOR\r\nTHE DEAF",
      "location": "MAMPONG",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "mampongakwshstforthedeaf@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "AKWAPIM NORTH",
      "label": "PRESBY SENIOR HIGH SCHOOL, MAMPONG\r\nAKWAPIM",
      "value": "PRESBY SENIOR HIGH SCHOOL, MAMPONG\r\nAKWAPIM",
      "location": "MAMPONG-\r\nAKWAPIM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "presbymampongakwshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "AKWAPIM SOUTH",
      "label": "PRESBY SENIOR HIGH/TECH SCHOOL, ABURI",
      "value": "PRESBY SENIOR HIGH/TECH SCHOOL, ABURI",
      "location": "ABURI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "presbytaburishs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "AKWAPIM SOUTH",
      "label": "ADONTEN SENIOR HIGH SCHOOL",
      "value": "ADONTEN SENIOR HIGH SCHOOL",
      "location": "ABURI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "adontenshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "AKWAPIM SOUTH",
      "label": "ABURI GIRLS SENIOR HIGH SCHOOL",
      "value": "ABURI GIRLS SENIOR HIGH SCHOOL",
      "location": "ABURI",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "aburigirlsshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "AKWAPIM SOUTH",
      "label": "DIASPORA GIRLS' SENIOR HIGH SCHOOL",
      "value": "DIASPORA GIRLS' SENIOR HIGH SCHOOL",
      "location": "OBODAN-\r\nNSAWAM",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "diasporagirlsshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "AKYEMANSA",
      "label": "AKOKOASO SENIOR HIGH/TECH SCHOOL",
      "value": "AKOKOASO SENIOR HIGH/TECH SCHOOL",
      "location": "AKIM AKOKOASO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "akokoasoshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "AKYEMANSA",
      "label": "AYIREBI SENIOR HIGH SCHOOL",
      "value": "AYIREBI SENIOR HIGH SCHOOL",
      "location": "AYIREBI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "ayirebishs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "ASUOGYAMAN",
      "label": "BOSO SENIOR HIGH TECHNICAL SCHOOL",
      "value": "BOSO SENIOR HIGH TECHNICAL SCHOOL",
      "location": "BOSO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "bososhst@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "ASUOGYAMAN",
      "label": "ANUM PRESBY SENIOR HIGH SCHOOL",
      "value": "ANUM PRESBY SENIOR HIGH SCHOOL",
      "location": "ANUM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "presbyanumshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "ASUOGYAMAN",
      "label": "ADJENA SENIOR HIGH/TECH SCHOOL",
      "value": "ADJENA SENIOR HIGH/TECH SCHOOL",
      "location": "ADJENA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "adjenashst@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "ASUOGYAMAN",
      "label": "APEGUSO SENIOR HIGH SCHOOL",
      "value": "APEGUSO SENIOR HIGH SCHOOL",
      "location": "APEGUSO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "apegusoshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "ASUOGYAMAN",
      "label": "AKWAMUMAN SENIOR HIGH SCHOOL",
      "value": "AKWAMUMAN SENIOR HIGH SCHOOL",
      "location": "ATIMPOKU-\r\nAKOSOMBO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "akwamumanshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "ATIWA",
      "label": "S.D.A. SENIOR HIGHSCHOOL, AKIM SEKYERE",
      "value": "S.D.A. SENIOR HIGHSCHOOL, AKIM SEKYERE",
      "location": "AKIM SEKYERE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "sdasksakimsekyereshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "ATIWA",
      "label": "KWABENG ANGLICAN SENIOR HIGH/TECH SCHOOL",
      "value": "KWABENG ANGLICAN SENIOR HIGH/TECH SCHOOL",
      "location": "KWABENG",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "kwabenganglicanshst@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "AYENSUANO",
      "label": "ANUM APAPAM COMM. DAY SENIOR HIGH SCHOOL",
      "value": "ANUM APAPAM COMM. DAY SENIOR HIGH SCHOOL",
      "location": "ANUM APAPAM",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "anumapapamcommshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "AYENSUANO",
      "label": "KRABOA-COALTAR PRESBY SENIOR HIGH SCHOOL\r\nHIGH/TECH.",
      "value": "KRABOA-COALTAR PRESBY SENIOR HIGH SCHOOL\r\nHIGH/TECH.",
      "location": "KRABOA-\r\nCOALTAR",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "presbykraboacoaltarshst@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "BIRIM CENTRAL MUNICIPAL",
      "label": "ODA SENIOR HIGH SCHOOL",
      "value": "ODA SENIOR HIGH SCHOOL",
      "location": "AKIM ODA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "odashs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "BIRIM CENTRAL MUNICIPAL",
      "label": "ST. FRANCIS SENIOR HIGH/TECH SCHOOL, AKIM\r\nODA",
      "value": "ST. FRANCIS SENIOR HIGH/TECH SCHOOL, AKIM\r\nODA",
      "location": "AKIM ODA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "stfrancisshst@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "BIRIM CENTRAL MUNICIPAL",
      "label": "ATTAFUAH SENIOR HIGH/TECH SCHOOL",
      "value": "ATTAFUAH SENIOR HIGH/TECH SCHOOL",
      "location": "AKIM ODA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "attafuahshst@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "BIRIM CENTRAL MUNICIPAL",
      "label": "ATWEAMAN SENIOR HIGH SCHOOL",
      "value": "ATWEAMAN SENIOR HIGH SCHOOL",
      "location": "AKIM MANSO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "atweamanshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "BIRIM CENTRAL MUNICIPAL",
      "label": "AKROSO SENIOR HIGH/TECH SCHOOL",
      "value": "AKROSO SENIOR HIGH/TECH SCHOOL",
      "location": "AKROSO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "akrososhst@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "BIRIM NORTH",
      "label": "AMOANA PRASO SENIOR HIGH SCHOOL",
      "value": "AMOANA PRASO SENIOR HIGH SCHOOL",
      "location": "AMOANA PRASO",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "amoanaprasoshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "BIRIM NORTH",
      "label": "ST. MICHAEL'S SENIOR HIGH SCHOOL, AKOASE\r\n(NKAWKAW)",
      "value": "ST. MICHAEL'S SENIOR HIGH SCHOOL, AKOASE\r\n(NKAWKAW)",
      "location": "AKOASE,\r\nNKAWKAW",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "st.michaelsakoaseshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "BIRIM NORTH",
      "label": "NEW ABIREM/AFOSU SENIOR HIGH SCHOOL",
      "value": "NEW ABIREM/AFOSU SENIOR HIGH SCHOOL",
      "location": "NEW ABIREM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "newabiremafosushs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "BIRIM SOUTH",
      "label": "AKIM SWEDRU SENIOR HIGH SCHOOL",
      "value": "AKIM SWEDRU SENIOR HIGH SCHOOL",
      "location": "AKIM SWEDRU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "akimswedrushs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "BIRIM SOUTH",
      "label": "ACHIASE SENIOR HIGH SCHOOL",
      "value": "ACHIASE SENIOR HIGH SCHOOL",
      "location": "ACHIASE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "achiaseshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "BIRIM SOUTH",
      "label": "APERADE SENIOR  HIGH/TECH SCHOOL",
      "value": "APERADE SENIOR  HIGH/TECH SCHOOL",
      "location": "AKIM APERADE",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "aperadeshst@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "DENKYEMBOUR",
      "label": "AKWATIA TECH. INST.",
      "value": "AKWATIA TECH. INST.",
      "location": "AKWATIA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "akwatiatechinst@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "DENKYEMBOUR",
      "label": "ST. ROSE'S SENIOR HIGH SCHOOL, AKWATIA",
      "value": "ST. ROSE'S SENIOR HIGH SCHOOL, AKWATIA",
      "location": "AKWATIA",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "strosesshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "DENKYEMBOUR",
      "label": "TARKROSI COMM. SENIOR HIGH SCHOOL",
      "value": "TARKROSI COMM. SENIOR HIGH SCHOOL",
      "location": "TARKROSI",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "tarkrosicommshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "DENKYEMBOUR",
      "label": "SALVATION ARMY SENIOR HIGH SCHOOL, AKIM\r\nWENCHI",
      "value": "SALVATION ARMY SENIOR HIGH SCHOOL, AKIM\r\nWENCHI",
      "location": "AKIM WENCHI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "salvationarmywenchishs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "EAST AKIM",
      "label": "ST. PAUL'S TECH. SCHOOL",
      "value": "ST. PAUL'S TECH. SCHOOL",
      "location": "KUKURANTUMI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "st.paulstechshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "EAST AKIM MUNICIPAL",
      "label": "W.B.M. ZION SENIOR HIGH SCHOOL, OLD TAFO",
      "value": "W.B.M. ZION SENIOR HIGH SCHOOL, OLD TAFO",
      "location": "OLD TAFO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "wbmzionshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "EAST AKIM MUNICIPAL",
      "label": "ST. STEPHEN'S PRESBY SENIOR HIGH/TECH\r\nSCHOOL, ASIAKWA",
      "value": "ST. STEPHEN'S PRESBY SENIOR HIGH/TECH\r\nSCHOOL, ASIAKWA",
      "location": "ASIAKWA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "ststephenspresbyshst@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "EAST AKIM MUNICIPAL",
      "label": "SAVIOUR SENIOR HIGH SCHOOL, OSIEM",
      "value": "SAVIOUR SENIOR HIGH SCHOOL, OSIEM",
      "location": "OSIEM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "saviourshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "EAST AKIM MUNICIPAL",
      "label": "OFORI PANIN SENIOR HIGH SCHOOL",
      "value": "OFORI PANIN SENIOR HIGH SCHOOL",
      "location": "KUKURANTUMI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "oforipaninshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "EAST AKIM MUNICIPAL",
      "label": "AKIM ASAFO SENIOR HIGH SCHOOL",
      "value": "AKIM ASAFO SENIOR HIGH SCHOOL",
      "location": "AKIM ASAFO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "akimasafoshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "EAST AKIM MUNICIPAL",
      "label": "ABUAKWA STATE COLLEGE",
      "value": "ABUAKWA STATE COLLEGE",
      "location": "KIBI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "abuakwastateshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "EAST AKIM MUNICIPAL",
      "label": "KIBI SENIOR HIGH/TECH SCHOOL",
      "value": "KIBI SENIOR HIGH/TECH SCHOOL",
      "location": "KIBI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "kibishst@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "EAST AKIM MUNICIPAL",
      "label": "APEDWA PRESBY SENIOR HIGH SCHOOL",
      "value": "APEDWA PRESBY SENIOR HIGH SCHOOL",
      "location": "APEDWA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "presbyshsapedwa@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "FANTEAKWA",
      "label": "PRESBY SENIOR HIGH SCHOOL, BEGORO",
      "value": "PRESBY SENIOR HIGH SCHOOL, BEGORO",
      "location": "BEGORO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "presbybegoroshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "FANTEAKWA",
      "label": "OSINO PRESBY SENIOR HIGH/TECH SCHOOL",
      "value": "OSINO PRESBY SENIOR HIGH/TECH SCHOOL",
      "location": "OSINO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "osinopresbyshst@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "FANTEAKWA",
      "label": "NEW NSUTAM SENIOR HIGH/TECH SCHOOL",
      "value": "NEW NSUTAM SENIOR HIGH/TECH SCHOOL",
      "location": "NEW NSUTAM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "newnsutamshst@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "KWAEBIBIREM",
      "label": "ASUOM SENIOR HIGH SCHOOL",
      "value": "ASUOM SENIOR HIGH SCHOOL",
      "location": "ASUOM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "asuomshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "KWAEBIBIREM",
      "label": "KADE SENIOR HIGH/TECH SCHOOL",
      "value": "KADE SENIOR HIGH/TECH SCHOOL",
      "location": "KADE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "kadeshst@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "KWAHU AFRAM PLAINS\r\nNORTH",
      "label": "AMANKWAKROM FISHERIES AGRIC. TECH. INST.",
      "value": "AMANKWAKROM FISHERIES AGRIC. TECH. INST.",
      "location": "AMANKWAKROM",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "amankwakromfisheriesagrictechinst@ges.gov.g"
    },
    {
      "region": "EASTERN",
      "district": "KWAHU AFRAM PLAINS\r\nNORTH",
      "label": "ST. MARY'S VOC./TECH. INST.",
      "value": "ST. MARY'S VOC./TECH. INST.",
      "location": "ADIEMBRA",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "stmarytechvocinst@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "KWAHU EAST",
      "label": "ST. DOMINIC'S SENIOR HIGH/TECH SCHOOL,\r\nPEPEASE",
      "value": "ST. DOMINIC'S SENIOR HIGH/TECH SCHOOL,\r\nPEPEASE",
      "location": "PEPEASE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "stdominicsshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "KWAHU EAST",
      "label": "ABETIFI TECH. INST.",
      "value": "ABETIFI TECH. INST.",
      "location": "ABETIFI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "abetifitechinst@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "KWAHU EAST",
      "label": "ABETIFI PRESBY SENIOR HIGH SCHOOL",
      "value": "ABETIFI PRESBY SENIOR HIGH SCHOOL",
      "location": "ABETIFI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "abetifipresbyshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "KWAHU EAST",
      "label": "KWAHU TAFO SENIOR HIGH SCHOOL",
      "value": "KWAHU TAFO SENIOR HIGH SCHOOL",
      "location": "KWAHU TAFO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "kwahutafoshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "KWAHU EAST",
      "label": "NKWATIA PRESBY SENIOR HIGH/COMM SCHOOL",
      "value": "NKWATIA PRESBY SENIOR HIGH/COMM SCHOOL",
      "location": "NKWATIA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "presbynkwatiacomshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "KWAHU EAST",
      "label": "ST. PETER'S SENIOR HIGH SCHOOL, NKWATIA",
      "value": "ST. PETER'S SENIOR HIGH SCHOOL, NKWATIA",
      "location": "NKWATIA",
      "gender": "MALE",
      "residency": "DAY/BOARDING",
      "email": "stpetersshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "KWAHU SOUTH",
      "label": "ST. PAUL'S SENIOR HIGH SCHOOL, ASAKRAKA\r\nKWAHU",
      "value": "ST. PAUL'S SENIOR HIGH SCHOOL, ASAKRAKA\r\nKWAHU",
      "location": "ASAKRAKA-\r\nKWAHU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "stpaulskwahushs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "KWAHU SOUTH",
      "label": "BEPONG SENIOR HIGH SCHOOL",
      "value": "BEPONG SENIOR HIGH SCHOOL",
      "location": "BEPONG",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "bepongshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "KWAHU SOUTH",
      "label": "MPRAESO SENIOR HIGH SCHOOL",
      "value": "MPRAESO SENIOR HIGH SCHOOL",
      "location": "MPRAESO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "mpraesoshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "KWAHU SOUTH",
      "label": "KWAHU RIDGE SENIOR HIGH SCHOOL",
      "value": "KWAHU RIDGE SENIOR HIGH SCHOOL",
      "location": "OBO-KWAHU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "kwahuridgeshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "KWAHU SOUTH",
      "label": "ST. JOSEPH'S TECH. INST.",
      "value": "ST. JOSEPH'S TECH. INST.",
      "location": "KWAHU-TAFO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "stjosephstechinstkwahu@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "KWAHU WEST MUNICIPAL",
      "label": "FODOA COMM. SENIOR HIGH SCHOOL",
      "value": "FODOA COMM. SENIOR HIGH SCHOOL",
      "location": "FODOA\r\nNKAWKAW",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "fodoacommshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "KWAHU WEST MUNICIPAL",
      "label": "NKAWKAW SENIOR HIGH SCHOOL",
      "value": "NKAWKAW SENIOR HIGH SCHOOL",
      "location": "NKAWKAW",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "nkawkawshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "LOWER MANYA KROBO",
      "label": "MANYA KROBO SENIOR HIGH SCHOOL",
      "value": "MANYA KROBO SENIOR HIGH SCHOOL",
      "location": "NEW NUASO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "manyakroboshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "LOWER MANYA KROBO",
      "label": "AKRO SENIOR HIGH/TECH SCHOOL",
      "value": "AKRO SENIOR HIGH/TECH SCHOOL",
      "location": "ODUMASE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "akroshst@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "LOWER MANYA KROBO",
      "label": "KROBO GIRLS SENIOR HIGH SCHOOL",
      "value": "KROBO GIRLS SENIOR HIGH SCHOOL",
      "location": "ODUMASE",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "krobogirlsshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "LOWER MANYA KROBO",
      "label": "AKUSE METHODIST SENIOR HIGH/TECH SCHOOL",
      "value": "AKUSE METHODIST SENIOR HIGH/TECH SCHOOL",
      "location": "AKUSE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "akusemethodistshst@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "NEW JUABEN",
      "label": "KOFORIDUA TECH. INST.",
      "value": "KOFORIDUA TECH. INST.",
      "location": "KOFORIDUA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "koforiduatechinst@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "NEW JUABEN",
      "label": "PENTECOST SENIOR HIGH SCHOOL,KOFORIDUA",
      "value": "PENTECOST SENIOR HIGH SCHOOL,KOFORIDUA",
      "location": "KOFORIDUA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "pentecostkoforiduashs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "NEW JUABEN MUNICIPAL",
      "label": "OYOKO METHODIST  SENIOR HIGH SCHOOL",
      "value": "OYOKO METHODIST  SENIOR HIGH SCHOOL",
      "location": "OYOKO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "oyokomethodistshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "NEW JUABEN MUNICIPAL",
      "label": "S.D.A SENIOR HIGH SCHOOL, KOFORIDUA",
      "value": "S.D.A SENIOR HIGH SCHOOL, KOFORIDUA",
      "location": "ASOKORE-\r\nKOFORIDUA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "sdakoforiduashs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "NEW JUABEN MUNICIPAL",
      "label": "GHANA SENIOR HIGH SCHOOL, KOFORIDUA",
      "value": "GHANA SENIOR HIGH SCHOOL, KOFORIDUA",
      "location": "KOFORIDUA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "ghanashskoforidua@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "NEW JUABEN MUNICIPAL",
      "label": "OTI BOATENG SENIOR HIGH SCHOOL",
      "value": "OTI BOATENG SENIOR HIGH SCHOOL",
      "location": "ADA/KOFORIDUA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "otiboatengshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "NEW JUABEN MUNICIPAL",
      "label": "POPE JOHN SENIOR HIGH & MIN. SEM. SCHOOL,\r\nKOFORIDUA",
      "value": "POPE JOHN SENIOR HIGH & MIN. SEM. SCHOOL,\r\nKOFORIDUA",
      "location": "KOFORIDUA",
      "gender": "MALE",
      "residency": "DAY/BOARDING",
      "email": "popejohnshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "NEW JUABEN MUNICIPAL",
      "label": "KOFORIDUA SENIOR HIGH/TECH SCHOOL",
      "value": "KOFORIDUA SENIOR HIGH/TECH SCHOOL",
      "location": "KOFORIDUA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "koforiduashst@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "NEW JUABEN MUNICIPAL",
      "label": "NEW JUABEN SENIOR HIGH/COMM SCHOOL",
      "value": "NEW JUABEN SENIOR HIGH/COMM SCHOOL",
      "location": "KOFORIDUA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "newjuabencomshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "NSAWAM ADOAGYIRI",
      "label": "ST. MARTIN'S SENIOR HIGH SCHOOL, NSAWAM",
      "value": "ST. MARTIN'S SENIOR HIGH SCHOOL, NSAWAM",
      "location": "NSAWAM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "stmartinsshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "NSAWAM ADOAGYIRI",
      "label": "NSAWAM SENIOR HIGH SCHOOL",
      "value": "NSAWAM SENIOR HIGH SCHOOL",
      "location": "NSAWAM",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "nsawamshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "SUHUM MUNICIPAL",
      "label": "PRESBY SENIOR HIGH SCHOOL, SUHUM",
      "value": "PRESBY SENIOR HIGH SCHOOL, SUHUM",
      "location": "SUHUM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "presbysuhumshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "SUHUM MUNICIPAL",
      "label": "SUHUM SENIOR HIGH/TECH SCHOOL",
      "value": "SUHUM SENIOR HIGH/TECH SCHOOL",
      "location": "SUHUM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "suhumshst@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "SUHUM MUNICIPAL",
      "label": "ISLAMIC GIRLS SENIOR HIGH SCHOOL,SUHUM",
      "value": "ISLAMIC GIRLS SENIOR HIGH SCHOOL,SUHUM",
      "location": "KORASE-SUHUM",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "islamicgirlsshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "UPPER MANYA KROBO",
      "label": "ASESEWA SENIOR HIGH SCHOOL",
      "value": "ASESEWA SENIOR HIGH SCHOOL",
      "location": "ASESEWA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "asesewashs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "UPPER WEST AKIM",
      "label": "ADEISO PRESBY SENIOR HIGH SCHOOL",
      "value": "ADEISO PRESBY SENIOR HIGH SCHOOL",
      "location": "ADEISO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "presbyadeisoshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "UPPER WEST AKIM",
      "label": "KWAOBAAH NYANOA COMM. SENIOR HIGH SCHOOL",
      "value": "KWAOBAAH NYANOA COMM. SENIOR HIGH SCHOOL",
      "location": "NYANOA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "kwaobaahnyanoacommshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "WEST AKIM MUNICIPAL",
      "label": "ASAMANKESE SENIOR HIGH SCHOOL",
      "value": "ASAMANKESE SENIOR HIGH SCHOOL",
      "location": "ASAMANKESE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "asamankeseshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "WEST AKIM MUNICIPAL",
      "label": "ST. THOMAS SENIOR HIGH/TECH SCHOOL",
      "value": "ST. THOMAS SENIOR HIGH/TECH SCHOOL",
      "location": "ASAMANKESE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "stthomasshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "YILO KROBO",
      "label": "KLO-AGOGO SENIOR HIGH SCHOOL",
      "value": "KLO-AGOGO SENIOR HIGH SCHOOL",
      "location": "KLO-AGOGO",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "kloagogoshs@ges.gov.gh"
    },
    {
      "region": "EASTERN",
      "district": "YILO KROBO",
      "label": "YILO KROBO SENIOR HIGH/COMM SCHOOL",
      "value": "YILO KROBO SENIOR HIGH/COMM SCHOOL",
      "location": "SOMANYA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "yilokroboshscomm@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "ABLEKUMA CENTRAL\r\nMUNICIPAL",
      "label": "KANESHIE SENIOR HIGH/TECH SCHOOL",
      "value": "KANESHIE SENIOR HIGH/TECH SCHOOL",
      "location": "KANESHIE",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "kaneshieshst@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "ABLEKUMA NORTH\r\nMUNICPAL",
      "label": "WESLEY GRAMMAR SENIOR HIGH SCHOOL",
      "value": "WESLEY GRAMMAR SENIOR HIGH SCHOOL",
      "location": "DANSOMAN",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "wesleygrammarshs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "ABLEKUMA WEST\r\nMUNICIPAL",
      "label": "ST. MARGARET MARY SENIOR HIGH/TECH SCHOOL",
      "value": "ST. MARGARET MARY SENIOR HIGH/TECH SCHOOL",
      "location": "DANSOMAN",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "stmargaretmaryshst@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "ABLEKUMA WEST\r\nMUNICIPAL",
      "label": "EBENEZER SENIOR HIGH SCHOOL",
      "value": "EBENEZER SENIOR HIGH SCHOOL",
      "location": "DANSOMAN",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "ebenezershs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "ACCRA METRO",
      "label": "ACCRA ACADEMY",
      "value": "ACCRA ACADEMY",
      "location": "KANESHIE",
      "gender": "MALE",
      "residency": "DAY/BOARDING",
      "email": "accraacademyshs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "ACCRA METRO",
      "label": "ACCRA WESLEY GIRLS SENIOR HIGH SCHOOL",
      "value": "ACCRA WESLEY GIRLS SENIOR HIGH SCHOOL",
      "location": "KANESHIE",
      "gender": "FEMALE",
      "residency": "DAY",
      "email": "accrawesleygirlshs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "ACCRA METRO",
      "label": "HOLY TRINITY SENIOR HIGH SCHOOL",
      "value": "HOLY TRINITY SENIOR HIGH SCHOOL",
      "location": "HIGH STREET-\r\nACCRA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "holytrinityshs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "ACCRA METRO",
      "label": "ST. MARY'S SENIOR HIGH SCHOOL, KORLE GONNO",
      "value": "ST. MARY'S SENIOR HIGH SCHOOL, KORLE GONNO",
      "location": "KORLE GONNO",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "stmarysshs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "ACCRA METRO",
      "label": "SACRED HEART TECH. INST.",
      "value": "SACRED HEART TECH. INST.",
      "location": "JAMES TOWN",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "sacredhearttechinst@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "ADA EAST DISTRICT",
      "label": "ADA TECH. INST.",
      "value": "ADA TECH. INST.",
      "location": "ADA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "adatechinst@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "ADA EAST DISTRICT",
      "label": "ADA SENIOR HIGH SCHOOL",
      "value": "ADA SENIOR HIGH SCHOOL",
      "location": "ADA-FOAH",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "adashs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "ADA WEST DISTRICT",
      "label": "ADA SENIOR HIGH/TECH SCHOOL",
      "value": "ADA SENIOR HIGH/TECH SCHOOL",
      "location": "SEGE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "adashst@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "ADENTAN MUNICIPAL",
      "label": "FRAFRAHA COMM. SENIOR HIGH SCHOOL",
      "value": "FRAFRAHA COMM. SENIOR HIGH SCHOOL",
      "location": "FRAFRAHA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "frafrahacomshs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "ASHIAMAN MUNICIPAL",
      "label": "ASHIAMAN SENIOR HIGH SCHOOL",
      "value": "ASHIAMAN SENIOR HIGH SCHOOL",
      "location": "ASHIAMAN",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "ashiamanshs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "ASHIAMAN MUNICIPAL",
      "label": "ASHIAMAN TECH/VOC. INST.",
      "value": "ASHIAMAN TECH/VOC. INST.",
      "location": "ASHIAMAN",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "ashiamantechvocinst@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "AYAWASO CENTRAL\r\nMUNICIPAL",
      "label": "ACCRA TECH. TRG. CENTRE",
      "value": "ACCRA TECH. TRG. CENTRE",
      "location": "KOKOMLEMLE",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "accratechtrgcentre@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "AYAWASO NORTH\r\nMUNICIPAL",
      "label": "ACCRA GIRLS SENIOR HIGH SCHOOL",
      "value": "ACCRA GIRLS SENIOR HIGH SCHOOL",
      "location": "MAMOBI",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "accragirlsshs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "GA CENTRAL MUNICIPAL",
      "label": "ODORGONNO SENIOR HIGH SCHOOL",
      "value": "ODORGONNO SENIOR HIGH SCHOOL",
      "location": "AWOSHIE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "odorgonnoshs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "GA EAST MUNICIPAL",
      "label": "KWABENYA COMM. SENIOR HIGH SCHOOL",
      "value": "KWABENYA COMM. SENIOR HIGH SCHOOL",
      "location": "KWABENYA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "kwabenyacommshs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "GA NORTH MUNICIPAL",
      "label": "ST. JOHN'S GRAMMAR SENIOR HIGH SCHOOL",
      "value": "ST. JOHN'S GRAMMAR SENIOR HIGH SCHOOL",
      "location": "ACHIMOTA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "stjohnsgrammarshs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "GA SOUTH MUNICIPAL",
      "label": "CHRISTIAN METHODIST SENIOR HIGH SCHOOL",
      "value": "CHRISTIAN METHODIST SENIOR HIGH SCHOOL",
      "location": "APLAKU (WEIJA)",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "christianmethodistshs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "GA SOUTH MUNICIPAL",
      "label": "NGLESHIE AMANFRO SENIOR HIGH SCHOOL",
      "value": "NGLESHIE AMANFRO SENIOR HIGH SCHOOL",
      "location": "NGLESHIE\r\nAMANFRO",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "ngleshieamanfroshs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "GA WEST MUNICIPAL",
      "label": "ADJEN KOTOKU SENIOR HIGH SCHOOL",
      "value": "ADJEN KOTOKU SENIOR HIGH SCHOOL",
      "location": "ADJEN KOTOKU",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "adjenkotokushs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "GA WEST MUNICIPAL",
      "label": "AMASAMAN SENIOR HIGH/TECH SCHOOL",
      "value": "AMASAMAN SENIOR HIGH/TECH SCHOOL",
      "location": "AMASAMAN",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "amasamanshst@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "KORLEY KLOTTEY\r\nMUNICIPAL",
      "label": "ACCRA SENIOR HIGH SCHOOL",
      "value": "ACCRA SENIOR HIGH SCHOOL",
      "location": "ASYLUM DOWN",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "accrashs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "KORLEY KLOTTEY\r\nMUNICIPAL",
      "label": "PRESBY SENIOR HIGH SCHOOL, OSU",
      "value": "PRESBY SENIOR HIGH SCHOOL, OSU",
      "location": "OSU",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "presbyshsosu@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "KORLEY KLOTTEY\r\nMUNICIPAL",
      "label": "KINBU SENIOR HIGH/TECH SCHOOL",
      "value": "KINBU SENIOR HIGH/TECH SCHOOL",
      "location": "TUDU - ACCRA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "kinbushst@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "KPONE KATAMANSO\r\nMUNICIPAL",
      "label": "KPONE COMM. SENIOR HIGH SCHOOL",
      "value": "KPONE COMM. SENIOR HIGH SCHOOL",
      "location": "KATAMANSO",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "kponecommshs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "KROWOR MUNICIPAL",
      "label": "O'REILLY SENIOR HIGH SCHOOL",
      "value": "O'REILLY SENIOR HIGH SCHOOL",
      "location": "OKPOI GOMME",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "oreillyshs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "KROWOR MUNICIPAL",
      "label": "NUNGUA SENIOR HIGH SCHOOL",
      "value": "NUNGUA SENIOR HIGH SCHOOL",
      "location": "NUNGUA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "nunguashs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "LA DADE-KOTOPON\r\nMUNICIPAL",
      "label": "FORCES SENIOR HIGH/TECH SCHOOL, BURMA CAMP",
      "value": "FORCES SENIOR HIGH/TECH SCHOOL, BURMA CAMP",
      "location": "BURMA CAMP",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "forcesshs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "LA DADE-KOTOPON\r\nMUNICIPAL",
      "label": "ST. THOMAS AQUINAS SENIOR HIGH SCHOOL,\r\nCANTOMENTS",
      "value": "ST. THOMAS AQUINAS SENIOR HIGH SCHOOL,\r\nCANTOMENTS",
      "location": "CANTONMENTS",
      "gender": "MALE",
      "residency": "DAY",
      "email": "stthomasaquinasshs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "LA DADE-KOTOPON\r\nMUNICIPAL",
      "label": "LABONE SENIOR HIGH SCHOOL",
      "value": "LABONE SENIOR HIGH SCHOOL",
      "location": "LABONE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "laboneshs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "LA DADE-KOTOPON\r\nMUNICIPAL",
      "label": "LA PRESBY SENIOR HIGH SCHOOL",
      "value": "LA PRESBY SENIOR HIGH SCHOOL",
      "location": "LA EMMAUS",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "presbylashs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "LA NKWANTANANG MADINA\r\nMUNICIPAL",
      "label": "WEST AFRICA SENIOR HIGH SCHOOL",
      "value": "WEST AFRICA SENIOR HIGH SCHOOL",
      "location": "ADENTA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "westafricashs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "LA NKWANTANANG MADINA\r\nMUNICIPAL",
      "label": "PRESBY BOYS SENIOR HIGH SCHOOL, LEGON",
      "value": "PRESBY BOYS SENIOR HIGH SCHOOL, LEGON",
      "location": "LEGON",
      "gender": "MALE",
      "residency": "DAY/BOARDING",
      "email": "presbyboyslegonshs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "LEDZOKUKU MUNICIPAL",
      "label": "PRESBY SENIOR HIGH SCHOOL, TESHIE",
      "value": "PRESBY SENIOR HIGH SCHOOL, TESHIE",
      "location": "TESHIE",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "presbyteshieshs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "LEDZOKUKU MUNICIPAL",
      "label": "TESHIE TECH. INSTITTUTE",
      "value": "TESHIE TECH. INSTITTUTE",
      "location": "TESHIE",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "teshietechinst@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "NINGO PRAMPRAM\r\nDISTRICT",
      "label": "PRAMPRAM SENIOR HIGH SCHOOL",
      "value": "PRAMPRAM SENIOR HIGH SCHOOL",
      "location": "PRAMPRAM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "prampramshs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "NINGO PRAMPRAM\r\nDISTRICT",
      "label": "NINGO SENIOR HIGH SCHOOL",
      "value": "NINGO SENIOR HIGH SCHOOL",
      "location": "OLD NINGO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "ningoshs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "OKAIKOI NORTH MUNICIPAL",
      "label": "ACHIMOTA SENIOR HIGH SCHOOL",
      "value": "ACHIMOTA SENIOR HIGH SCHOOL",
      "location": "ACHIMOTA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "achimotashs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "SHAI-OSUDOKU DISTRICT",
      "label": "OSUDOKU SENIOR HIGH/TECH SCHOOL",
      "value": "OSUDOKU SENIOR HIGH/TECH SCHOOL",
      "location": "ASUTSUARE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "osudokushst@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "SHAI-OSUDOKU DISTRICT",
      "label": "GHANATA SENIOR HIGH SCHOOL",
      "value": "GHANATA SENIOR HIGH SCHOOL",
      "location": "DODOWA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "ghanatashs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "TEMA METRO",
      "label": "TEMA METH. DAY SENIOR HIGH SCHOOL",
      "value": "TEMA METH. DAY SENIOR HIGH SCHOOL",
      "location": "TEMA COMM II",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "temamethdayshs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "TEMA METRO",
      "label": "TEMA TECH. INST.",
      "value": "TEMA TECH. INST.",
      "location": "TEMA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "tematechinst@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "TEMA METRO",
      "label": "PRESBY SENIOR HIGH SCHOOL, TEMA",
      "value": "PRESBY SENIOR HIGH SCHOOL, TEMA",
      "location": "TEMA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "presbyshstema@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "TEMA METRO",
      "label": "TEMA MANHEAN SENIOR HIGH/TECH SCHOOL",
      "value": "TEMA MANHEAN SENIOR HIGH/TECH SCHOOL",
      "location": "TEMA NEW\r\nTOWN",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "temamanheanshst@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "TEMA METRO",
      "label": "CHEMU SENIOR HIGH/TECH SCHOOL",
      "value": "CHEMU SENIOR HIGH/TECH SCHOOL",
      "location": "TEMA COMM 4",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "chemushst@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "TEMA METRO",
      "label": "OUR LADY OF MERCY SENIOR HIGH SCHOOL",
      "value": "OUR LADY OF MERCY SENIOR HIGH SCHOOL",
      "location": "TEMA COMM 4",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "ourladyofmercyshs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "TEMA WEST MUNICIPAL",
      "label": "TEMA SENIOR HIGH SCHOOL",
      "value": "TEMA SENIOR HIGH SCHOOL",
      "location": "TEMA COMM 5",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "temashs@ges.gov.gh"
    },
    {
      "region": "GREATER\r\nACCRA",
      "district": "TEMA WEST MUNICIPAL",
      "label": "LASHIBI COMMUNITY SENIOR HIGH SCHOOL",
      "value": "LASHIBI COMMUNITY SENIOR HIGH SCHOOL",
      "location": "LASHIBI-\r\nSAKUMONO",
      "gender": "FEMALE",
      "residency": "DAY",
      "email": "lashibicommunityshs@ges.gov.gh"
    },
    {
      "region": "NORTH EAST",
      "district": "BUNKRUPGU-YUNGUO",
      "label": "NAKPANDURI SENIOR HIGH SCHOOL",
      "value": "NAKPANDURI SENIOR HIGH SCHOOL",
      "location": "NAKPANDURI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "nakpandurishs@ges.gov.gh"
    },
    {
      "region": "NORTH EAST",
      "district": "BUNKRUPGU-YUNGUO",
      "label": "BUNKPURUGU SENIOR HIGH/TECH SCHOOL",
      "value": "BUNKPURUGU SENIOR HIGH/TECH SCHOOL",
      "location": "BUNKUPURUGU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "bunkpurugushst@ges.gov.gh"
    },
    {
      "region": "NORTH EAST",
      "district": "CHEREPONI",
      "label": "CHEREPONI SENIOR HIGH/TECH SCHOOL",
      "value": "CHEREPONI SENIOR HIGH/TECH SCHOOL",
      "location": "CHEREPONI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "chereponishst@ges.gov.gh"
    },
    {
      "region": "NORTH EAST",
      "district": "EAST MAMPRUSI",
      "label": "GAMBAGA GIRLS SENIOR HIGH SCHOOL",
      "value": "GAMBAGA GIRLS SENIOR HIGH SCHOOL",
      "location": "GAMBAGA",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "gambagagirlsshs@ges.gov.gh"
    },
    {
      "region": "NORTH EAST",
      "district": "EAST MAMPRUSI",
      "label": "NALERIGU SENIOR HIGH SCHOOL",
      "value": "NALERIGU SENIOR HIGH SCHOOL",
      "location": "NALERIGU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "nalerigushs@ges.gov.gh"
    },
    {
      "region": "NORTH EAST",
      "district": "EAST MAMPRUSI",
      "label": "SAKOGU SENIOR HIGH/TECH SCHOOL",
      "value": "SAKOGU SENIOR HIGH/TECH SCHOOL",
      "location": "SAKOGU",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "sakogushst@ges.gov.gh"
    },
    {
      "region": "NORTH EAST",
      "district": "MAMPRUGU MOADURI",
      "label": "YAGABA SENIOR HIGH SCHOOL",
      "value": "YAGABA SENIOR HIGH SCHOOL",
      "location": "YAGABA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "yagabashs@ges.gov.gh"
    },
    {
      "region": "NORTH EAST",
      "district": "WEST MAMPRUSI",
      "label": "WULUGU SENIOR HIGH SCHOOL",
      "value": "WULUGU SENIOR HIGH SCHOOL",
      "location": "WULUGU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "wulugushs@ges.gov.gh"
    },
    {
      "region": "NORTH EAST",
      "district": "WEST MAMPRUSI",
      "label": "WALEWALE SENIOR HIGH SCHOOL",
      "value": "WALEWALE SENIOR HIGH SCHOOL",
      "location": "WALEWELE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "walewaleshs@ges.gov.gh"
    },
    {
      "region": "NORTH EAST",
      "district": "WEST MAMPRUSI\r\nMUNICIPAL",
      "label": "JANGA SENIOR HIGH/TECH SCHOOL",
      "value": "JANGA SENIOR HIGH/TECH SCHOOL",
      "location": "MUNICIPAL\r\nASSEMBLY",
      "gender": "MALE",
      "residency": "DAY/BOARDING",
      "email": "jangashts@ges.gov.gh"
    },
    {
      "region": "NORTH EAST",
      "district": "WEST MAMPUSI",
      "label": "WALEWALETECH/ VOC INST.",
      "value": "WALEWALETECH/ VOC INST.",
      "location": "WALEWALE",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "walewaletechvocinst@ges.gov.gh"
    },
    {
      "region": "NORTH EAST\r\nREGION",
      "district": "EAST MAMPRUSI MUNICIPAL",
      "label": "LANGBINSI SENIOR HIGH TECHNICAL SCHOOL",
      "value": "LANGBINSI SENIOR HIGH TECHNICAL SCHOOL",
      "location": "LANGBINSI",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "langbinsishst@ges.gov.gh"
    },
    {
      "region": "NORTHERN",
      "district": "GUSHEGU",
      "label": "GUSHEGU SENIOR HIGH SCHOOL",
      "value": "GUSHEGU SENIOR HIGH SCHOOL",
      "location": "GUSHEGU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "gushegushs@ges.gov.gh"
    },
    {
      "region": "NORTHERN",
      "district": "KARAGA",
      "label": "KARAGA SENIOR HIGH SCHOOL",
      "value": "KARAGA SENIOR HIGH SCHOOL",
      "location": "KARAGA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "karagashs@ges.gov.gh"
    },
    {
      "region": "NORTHERN",
      "district": "KPANDI",
      "label": "KPANDAI SENIOR HIGH SCHOOL",
      "value": "KPANDAI SENIOR HIGH SCHOOL",
      "location": "KPANDAI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "kpandaishs@ges.gov.gh"
    },
    {
      "region": "NORTHERN",
      "district": "KUMBUNGU",
      "label": "KUMBUNGU SENIOR HIGH SCHOOL",
      "value": "KUMBUNGU SENIOR HIGH SCHOOL",
      "location": "KUMBUNGU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "kumbungushs@ges.gov.gh"
    },
    {
      "region": "NORTHERN",
      "district": "MION",
      "label": "SANG COMM. DAY SCHOOL",
      "value": "SANG COMM. DAY SCHOOL",
      "location": "SANG",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "sangcommdayshs@ges.gov.gh"
    },
    {
      "region": "NORTHERN",
      "district": "NANUMBA NORTH",
      "label": "BIMBILLA SENIOR HIGH SCHOOL",
      "value": "BIMBILLA SENIOR HIGH SCHOOL",
      "location": "BIMBILLA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "bimbillashs@ges.gov.gh"
    },
    {
      "region": "NORTHERN",
      "district": "NANUMBA SOUTH",
      "label": "WULENSI SENIOR HIGH SCHOOL",
      "value": "WULENSI SENIOR HIGH SCHOOL",
      "location": "WULENSI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "wulensishs@ges.gov.gh"
    },
    {
      "region": "NORTHERN",
      "district": "SABOBA",
      "label": "WAPULI COMM. SENIOR HIGH SCHOOL",
      "value": "WAPULI COMM. SENIOR HIGH SCHOOL",
      "location": "WAPULI",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "wapulicommshs@ges.gov.gh"
    },
    {
      "region": "NORTHERN",
      "district": "SABOBA",
      "label": "SABOBA E.P. SENIOR HIGH SCHOOL",
      "value": "SABOBA E.P. SENIOR HIGH SCHOOL",
      "location": "SABOBA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "sabobaepshs@ges.gov.gh"
    },
    {
      "region": "NORTHERN",
      "district": "SABOBA",
      "label": "ST. JOSEPH'S TECH. INST.",
      "value": "ST. JOSEPH'S TECH. INST.",
      "location": "SABOBA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "stjosephstechinstkwahu@ges.gov.gh"
    },
    {
      "region": "NORTHERN",
      "district": "SAGNERIGU",
      "label": "ISLAMIC SCIENCE SENIOR HIGH SCHOOL, TAMALE",
      "value": "ISLAMIC SCIENCE SENIOR HIGH SCHOOL, TAMALE",
      "location": "TAMALE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "islamicscienceshs@ges.gov.gh"
    },
    {
      "region": "NORTHERN",
      "district": "SAGNERIGU",
      "label": "NORTHERN SCHOOL OF BUSINESS",
      "value": "NORTHERN SCHOOL OF BUSINESS",
      "location": "TAMALE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "northernschoolofbusiness@ges.gov.gh"
    },
    {
      "region": "NORTHERN",
      "district": "SAGNERIGU",
      "label": "TAMALE TECHNICAL INSTITUTE",
      "value": "TAMALE TECHNICAL INSTITUTE",
      "location": "TAMALE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "tamaletechinst@ges.gov.gh"
    },
    {
      "region": "NORTHERN",
      "district": "SAGNERIGU",
      "label": "TAMALE SENIOR HIGH SCHOOL",
      "value": "TAMALE SENIOR HIGH SCHOOL",
      "location": "TAMALE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "tamaleshs@ges.gov.gh"
    },
    {
      "region": "NORTHERN",
      "district": "SAGNERIGU",
      "label": "KALPOHIN SENIOR HIGH SCHOOL",
      "value": "KALPOHIN SENIOR HIGH SCHOOL",
      "location": "TAMALE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "kalpohinshs@ges.gov.gh"
    },
    {
      "region": "NORTHERN",
      "district": "SAVELUGU-NANTON",
      "label": "PONG-TAMALE SENIOR HIGH SCHOOL",
      "value": "PONG-TAMALE SENIOR HIGH SCHOOL",
      "location": "PONG-TAMALE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "pongtamaleshs@ges.gov.gh"
    },
    {
      "region": "NORTHERN",
      "district": "SAVELUGU-NANTON",
      "label": "SAVELUGU SENIOR HIGH SCHOOL",
      "value": "SAVELUGU SENIOR HIGH SCHOOL",
      "location": "SAVELUGU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "savelugushs@ges.gov.gh"
    },
    {
      "region": "NORTHERN",
      "district": "TAMALE METRO",
      "label": "BUSINESS SENIOR HIGH SCHOOL, TAMALE",
      "value": "BUSINESS SENIOR HIGH SCHOOL, TAMALE",
      "location": "TAMALE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "businessshs@ges.gov.gh"
    },
    {
      "region": "NORTHERN",
      "district": "TAMALE METRO",
      "label": "GHANA SENIOR HIGH SCHOOL, TAMALE",
      "value": "GHANA SENIOR HIGH SCHOOL, TAMALE",
      "location": "TAMALE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "ghanatamaleshs@ges.gov.gh"
    },
    {
      "region": "NORTHERN",
      "district": "TAMALE METRO",
      "label": "DABOKPA VOC/TECH. INST.",
      "value": "DABOKPA VOC/TECH. INST.",
      "location": "TAMALE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "dabokpatechvocinst@ges.gov.gh"
    },
    {
      "region": "NORTHERN",
      "district": "TAMALE METRO",
      "label": "VITTING SENIOR HIGH/TECH.",
      "value": "VITTING SENIOR HIGH/TECH.",
      "location": "TAMALE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "vittingshst@ges.gov.gh"
    },
    {
      "region": "NORTHERN",
      "district": "TAMALE METRO",
      "label": "ST. CHARLES SENIOR HIGH SCHOOL, TAMALE",
      "value": "ST. CHARLES SENIOR HIGH SCHOOL, TAMALE",
      "location": "TAMALE",
      "gender": "MALE",
      "residency": "DAY/BOARDING",
      "email": "stcharlesshs@ges.gov.gh"
    },
    {
      "region": "NORTHERN",
      "district": "TAMALE METRO",
      "label": "ANBARIYA SENIOR HIGH SCHOOL",
      "value": "ANBARIYA SENIOR HIGH SCHOOL",
      "location": "TAMALE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "anbariyashs@ges.gov.gh"
    },
    {
      "region": "NORTHERN",
      "district": "TAMALE METRO",
      "label": "TAMALE GIRLS SENIOR HIGH SCHOOL",
      "value": "TAMALE GIRLS SENIOR HIGH SCHOOL",
      "location": "TAMALE",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "tamalegirlsshs@ges.gov.gh"
    },
    {
      "region": "NORTHERN",
      "district": "TAMALE METRO",
      "label": "PRESBY SENIOR HIGH SCHOOL, TAMALE",
      "value": "PRESBY SENIOR HIGH SCHOOL, TAMALE",
      "location": "TAMALE",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "presbytamaleshs@ges.gov.gh"
    },
    {
      "region": "NORTHERN",
      "district": "TATALE SANGULI",
      "label": "E. P. AGRIC SENIOR HIGH/TECH SCHOOL",
      "value": "E. P. AGRIC SENIOR HIGH/TECH SCHOOL",
      "location": "TATALE",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "epagricshst@ges.gov.gh"
    },
    {
      "region": "NORTHERN",
      "district": "TOLON",
      "label": "KASULIYILI SENIOR HIGH SCHOOL",
      "value": "KASULIYILI SENIOR HIGH SCHOOL",
      "location": "KASULIYILI",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "kasuliyilishs@ges.gov.gh"
    },
    {
      "region": "NORTHERN",
      "district": "TOLON",
      "label": "TOLON SENIOR HIGH SCHOOL",
      "value": "TOLON SENIOR HIGH SCHOOL",
      "location": "TOLON",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "tolonshs@ges.gov.gh"
    },
    {
      "region": "NORTHERN",
      "district": "YENDI MUNICIPAL",
      "label": "DAGBON STATE SENIOR HIGH/TECH SCHOOL",
      "value": "DAGBON STATE SENIOR HIGH/TECH SCHOOL",
      "location": "YENDI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "dagbonstateshst@ges.gov.gh"
    },
    {
      "region": "NORTHERN",
      "district": "YENDI MUNICIPAL",
      "label": "YENDI SENIOR HIGH SCHOOL",
      "value": "YENDI SENIOR HIGH SCHOOL",
      "location": "YENDI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "yendishs@ges.gov.gh"
    },
    {
      "region": "NORTHERN",
      "district": "ZABZUGU",
      "label": "ZABZUGU SENIOR HIGH SCHOOL",
      "value": "ZABZUGU SENIOR HIGH SCHOOL",
      "location": "ZABZUGU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "zabzugushs@ges.gov.gh"
    },
    {
      "region": "OTI",
      "district": "BIAKOYE",
      "label": "WORAWORA SENIOR HIGH SCHOOL",
      "value": "WORAWORA SENIOR HIGH SCHOOL",
      "location": "WORAWORA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "woraworashs@ges.gov.gh"
    },
    {
      "region": "OTI",
      "district": "BIAKOYE",
      "label": "TAPAMAN SENIOR HIGH/TECH SCHOOL",
      "value": "TAPAMAN SENIOR HIGH/TECH SCHOOL",
      "location": "TAPA-\r\nAMANFROM",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "tapamanshst@ges.gov.gh"
    },
    {
      "region": "OTI",
      "district": "BIAKOYE",
      "label": "BOWIRI COMM. DAY SCHOOL",
      "value": "BOWIRI COMM. DAY SCHOOL",
      "location": "BOWIRI",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "bowiricommdayshs@ges.gov.gh"
    },
    {
      "region": "OTI",
      "district": "BIAKOYE",
      "label": "NKONYA SENIOR HIGH SCHOOL",
      "value": "NKONYA SENIOR HIGH SCHOOL",
      "location": "NKONYA-\r\nAHENKRO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "nkonyashs@ges.gov.gh"
    },
    {
      "region": "OTI",
      "district": "BIAKOYE",
      "label": "BIAKOYE COMM. DAY SCHOOL",
      "value": "BIAKOYE COMM. DAY SCHOOL",
      "location": "KWAMEKROM",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "biakoyecommdayshs@ges.gov.gh"
    },
    {
      "region": "OTI",
      "district": "JASIKAN",
      "label": "BUEMAN SENIOR HIGH SCHOOL",
      "value": "BUEMAN SENIOR HIGH SCHOOL",
      "location": "JASIKAN",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "buemanshs@ges.gov.gh"
    },
    {
      "region": "OTI",
      "district": "JASIKAN",
      "label": "OKADJAKROM SENIOR HIGH/TECH SCHOOL",
      "value": "OKADJAKROM SENIOR HIGH/TECH SCHOOL",
      "location": "OKADJAKROM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "okadjakromshst@ges.gov.gh"
    },
    {
      "region": "OTI",
      "district": "JASIKAN",
      "label": "BAGLO  RIDGE SENIOR HIGH/TECH SCHOOL",
      "value": "BAGLO  RIDGE SENIOR HIGH/TECH SCHOOL",
      "location": "BAGLO",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "bagloridgeshst@ges.gov.gh"
    },
    {
      "region": "OTI",
      "district": "JASIKAN",
      "label": "FR. DOGLI MEMORIAL VOC.TECH. INST.",
      "value": "FR. DOGLI MEMORIAL VOC.TECH. INST.",
      "location": "NEW AYOMA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "frdoglimemorialtechvocinst@ges.gov.gh"
    },
    {
      "region": "OTI",
      "district": "KADJEBI",
      "label": "AHAMANSU ISLAMIC SENIOR HIGH SCHOOL",
      "value": "AHAMANSU ISLAMIC SENIOR HIGH SCHOOL",
      "location": "AHAMANSU",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "ahamansuislamicshs@ges.gov.gh"
    },
    {
      "region": "OTI",
      "district": "KADJEBI",
      "label": "DODI-PAPASE SENIOR HIGH/TECH SCHOOL",
      "value": "DODI-PAPASE SENIOR HIGH/TECH SCHOOL",
      "location": "DODI PAPASE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "dodipapaseshst@ges.gov.gh"
    },
    {
      "region": "OTI",
      "district": "KADJEBI",
      "label": "KADJEBI-ASATO SENIOR HIGH SCHOOL",
      "value": "KADJEBI-ASATO SENIOR HIGH SCHOOL",
      "location": "KADJETO-ASATO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "kadjebiasatoshs@ges.gov.gh"
    },
    {
      "region": "OTI",
      "district": "KRACHI EAST",
      "label": "OTI SENIOR HIGH/TECH SCHOOL",
      "value": "OTI SENIOR HIGH/TECH SCHOOL",
      "location": "DAMBAI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "otishst@ges.gov.gh"
    },
    {
      "region": "OTI",
      "district": "KRACHI EAST",
      "label": "YABRAM COMM. DAY SCHOOL",
      "value": "YABRAM COMM. DAY SCHOOL",
      "location": "DAMBAI",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "yabramcommdayshs@ges.gov.gh"
    },
    {
      "region": "OTI",
      "district": "KRACHI EAST",
      "label": "ASUKAWKAW SENIOR HIGH SCHOOL",
      "value": "ASUKAWKAW SENIOR HIGH SCHOOL",
      "location": "ASUKAWKAW",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "asukawkawshs@ges.gov.gh"
    },
    {
      "region": "OTI",
      "district": "KRACHI NCHUMURU",
      "label": "NCHUMURUMAN COMM. DAY SENIOR HIGH SCHOOL",
      "value": "NCHUMURUMAN COMM. DAY SENIOR HIGH SCHOOL",
      "location": "[NEAR] TEGBI",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "nchumurumancommdayshs@ges.gov.gh"
    },
    {
      "region": "OTI",
      "district": "KRACHI WEST",
      "label": "KETE KRACHI SENIOR HIGH/TECH SCHOOL",
      "value": "KETE KRACHI SENIOR HIGH/TECH SCHOOL",
      "location": "KETE-KRACHI",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "ketekrachishst@ges.gov.gh"
    },
    {
      "region": "OTI",
      "district": "KRACHI WEST",
      "label": "KRACHI SENIOR HIGH SCHOOL",
      "value": "KRACHI SENIOR HIGH SCHOOL",
      "location": "KETE-KRACHI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "krachishs@ges.gov.gh"
    },
    {
      "region": "OTI",
      "district": "NKWANTA NORTH",
      "label": "KPASSA SENIOR HIGH/TECH SCHOOL",
      "value": "KPASSA SENIOR HIGH/TECH SCHOOL",
      "location": "KPASSA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "kpassashst@ges.gov.gh"
    },
    {
      "region": "OTI",
      "district": "NKWANTA SOUTH",
      "label": "NKWANTA SENIOR HIGH SCHOOL",
      "value": "NKWANTA SENIOR HIGH SCHOOL",
      "location": "NKWANTA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "nkwantashs@ges.gov.gh"
    },
    {
      "region": "OTI",
      "district": "NKWANTA SOUTH",
      "label": "NKWANTA COMM SENIOR HIGH SCHOOL",
      "value": "NKWANTA COMM SENIOR HIGH SCHOOL",
      "location": "NKWANTA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "nkwantacommshs@ges.gov.gh"
    },
    {
      "region": "OTI",
      "district": "NKWANTA SOUTH",
      "label": "KYABOBO GIRLS SENIOR HIGH SCHOOL",
      "value": "KYABOBO GIRLS SENIOR HIGH SCHOOL",
      "location": "NKWANTA",
      "gender": "FEMALE",
      "residency": "DAY",
      "email": "kyabobogirlsshs@ges.gov.gh"
    },
    {
      "region": "OTI",
      "district": "NKWANTA SOUTH",
      "label": "NTRUBOMAN SENIOR HIGH SCHOOL",
      "value": "NTRUBOMAN SENIOR HIGH SCHOOL",
      "location": "BREWANIASE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "ntrubomanshs@ges.gov.gh"
    },
    {
      "region": "SAVANNAH",
      "district": "BOLE",
      "label": "BAMBOI COMM. SENIOR HIGH SCHOOL",
      "value": "BAMBOI COMM. SENIOR HIGH SCHOOL",
      "location": "BAMBOI",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "bamboicommshs@ges.gov.gh"
    },
    {
      "region": "SAVANNAH",
      "district": "BOLE",
      "label": "BOLE SENIOR HIGH SCHOOL",
      "value": "BOLE SENIOR HIGH SCHOOL",
      "location": "BOLE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "boleshs@ges.gov.gh"
    },
    {
      "region": "SAVANNAH",
      "district": "BOLE",
      "label": "ST. ANTHONY OF PADUA SENIOR HIGH/TECH\r\nSCHOOL",
      "value": "ST. ANTHONY OF PADUA SENIOR HIGH/TECH\r\nSCHOOL",
      "location": "BAMBOI",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "stanthonyofpaduashs@ges.gov.gh"
    },
    {
      "region": "SAVANNAH",
      "district": "CENTRAL GONJA",
      "label": "BUIPE SENIOR HIGH SCHOOL",
      "value": "BUIPE SENIOR HIGH SCHOOL",
      "location": "BUIPE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "buipeshs@ges.gov.gh"
    },
    {
      "region": "SAVANNAH",
      "district": "CENTRAL GONJA",
      "label": "BUIPE TECH/VOC INST.",
      "value": "BUIPE TECH/VOC INST.",
      "location": "BUIPE",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "buipetechvocinst@ges.gov.gh"
    },
    {
      "region": "SAVANNAH",
      "district": "CENTRAL GONJA",
      "label": "MPAHA COMMUNITY DAY SENIOR HIGH SCHOOL",
      "value": "MPAHA COMMUNITY DAY SENIOR HIGH SCHOOL",
      "location": "MPAHA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "mphashs@ges.gov.gh"
    },
    {
      "region": "SAVANNAH",
      "district": "EAST GONJA",
      "label": "SALAGA SENIOR HIGH SCHOOL",
      "value": "SALAGA SENIOR HIGH SCHOOL",
      "location": "SALAGA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "salagashs@ges.gov.gh"
    },
    {
      "region": "SAVANNAH",
      "district": "EAST GONJA",
      "label": "SALAGA T.I. AHMAD SENIOR HIGH SCHOOL",
      "value": "SALAGA T.I. AHMAD SENIOR HIGH SCHOOL",
      "location": "SALAGA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "salagatiahmadiyyashs@ges.gov.gh"
    },
    {
      "region": "SAVANNAH",
      "district": "NORTH GONJA",
      "label": "DABOYA COMM. DAY SCHOOL",
      "value": "DABOYA COMM. DAY SCHOOL",
      "location": "DABOYA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "daboyacommshs@ges.gov.gh"
    },
    {
      "region": "SAVANNAH",
      "district": "SAWLA-TUNA-KALBA",
      "label": "TUNA SENIOR HIGH/TECH SCHOOL",
      "value": "TUNA SENIOR HIGH/TECH SCHOOL",
      "location": "TUNA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "tunashst@ges.gov.gh"
    },
    {
      "region": "SAVANNAH",
      "district": "SAWLA-TUNA-KALBA",
      "label": "SAWLA SENIOR HIGH SCHOOL",
      "value": "SAWLA SENIOR HIGH SCHOOL",
      "location": "SAWLA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "sawlashs@ges.gov.gh"
    },
    {
      "region": "SAVANNAH",
      "district": "WEST GONJA",
      "label": "NDEWURA JAKPA SENIOR HIGH/TECH SCHOOL",
      "value": "NDEWURA JAKPA SENIOR HIGH/TECH SCHOOL",
      "location": "DAMONGO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "ndewurajakpashst@ges.gov.gh"
    },
    {
      "region": "SAVANNAH",
      "district": "WEST GONJA",
      "label": "DAMONGO SENIOR HIGH SCHOOL",
      "value": "DAMONGO SENIOR HIGH SCHOOL",
      "location": "DAMONGO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "damongoshs@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "BAWKU EAST",
      "label": "BAWKU TECH. INST.",
      "value": "BAWKU TECH. INST.",
      "location": "BAWKU KPALUGU",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "bawkutechinst@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "BAWKU MUNICIPAL",
      "label": "BAWKU SENIOR HIGH/TECH SCHOOL",
      "value": "BAWKU SENIOR HIGH/TECH SCHOOL",
      "location": "BAWKU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "bawkushst@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "BAWKU MUNICIPAL",
      "label": "BAWKU SENIOR HIGH SCHOOL",
      "value": "BAWKU SENIOR HIGH SCHOOL",
      "location": "BAWKU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "bawkushs@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "BAWKU WEST",
      "label": "ZEBILLA SENIOR HIGH/TECH SCHOOL",
      "value": "ZEBILLA SENIOR HIGH/TECH SCHOOL",
      "location": "ZEBILLA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "zebillashst@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "BAWKU WEST",
      "label": "KUSANABA SENIOR HIGH SCHOOL",
      "value": "KUSANABA SENIOR HIGH SCHOOL",
      "location": "KUSANABA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "kusanabashs@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "BINDURI",
      "label": "BINDURI COMM. SENIOR HIGH SCHOOL",
      "value": "BINDURI COMM. SENIOR HIGH SCHOOL",
      "location": "BINDURI",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "binduricommshs@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "BOLGA EAST",
      "label": "GAMBIGO COMM. DAY SENIOR HIGH SCHOOL",
      "value": "GAMBIGO COMM. DAY SENIOR HIGH SCHOOL",
      "location": "GAMBIGO",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "gambigocommshs@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "BOLGATANGA",
      "label": "BOLGA TECH. INST.",
      "value": "BOLGA TECH. INST.",
      "location": "BOLGATANGA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "bolgatechinst@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "BOLGATANGA MUNICIPAL",
      "label": "BOLGA GIRLS SENIOR HIGH SCHOOL",
      "value": "BOLGA GIRLS SENIOR HIGH SCHOOL",
      "location": "ZAARE-\r\nBOLGATANGA",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "bolgagirlsshs@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "BOLGATANGA MUNICIPAL",
      "label": "ZUARUNGU SENIOR HIGH SCHOOL",
      "value": "ZUARUNGU SENIOR HIGH SCHOOL",
      "location": "ZUARUNGU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "zuarungushs@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "BOLGATANGA MUNICIPAL",
      "label": "BOLGA SHERIGU COMM. SENIOR HIGH SCHOOL",
      "value": "BOLGA SHERIGU COMM. SENIOR HIGH SCHOOL",
      "location": "BOLGA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "bolgasherigucommshs@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "BOLGATANGA MUNICIPAL",
      "label": "ZAMSE SENIOR HIGH/TECH SCHOOL",
      "value": "ZAMSE SENIOR HIGH/TECH SCHOOL",
      "location": "BOLGATANGA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "zamseshst@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "BONGO",
      "label": "AZEEM-NAMOA SENIOR HIGH/TECH SCHOOL",
      "value": "AZEEM-NAMOA SENIOR HIGH/TECH SCHOOL",
      "location": "SAMBOLIGO\r\nAMANGA NABIISI",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "azeemnamoashst@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "BONGO",
      "label": "BONGO SENIOR HIGH SCHOOL",
      "value": "BONGO SENIOR HIGH SCHOOL",
      "location": "BONGO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "bongoshs@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "BONGO",
      "label": "ZORKOR SENIOR HIGH SCHOOL",
      "value": "ZORKOR SENIOR HIGH SCHOOL",
      "location": "ZORKOR",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "zorkorshs@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "BONGO",
      "label": "GOWRIE SENIOR HIGH/TECH SCHOOL",
      "value": "GOWRIE SENIOR HIGH/TECH SCHOOL",
      "location": "GOWRIE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "gowrieshst@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "BUILSA NORTH",
      "label": "SANDEMA SENIOR HIGH SCHOOL",
      "value": "SANDEMA SENIOR HIGH SCHOOL",
      "location": "SANDEMA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "sandemashs@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "BUILSA NORTH",
      "label": "SANDEMA SENIOR HIGH/TECH SCHOOL",
      "value": "SANDEMA SENIOR HIGH/TECH SCHOOL",
      "location": "DAMPONG",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "sandemashst@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "BUILSA NORTH",
      "label": "WIAGA COMM. SENIOR HIGH SCHOOL",
      "value": "WIAGA COMM. SENIOR HIGH SCHOOL",
      "location": "WIAGA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "wiagacommshs@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "BUILSA NORTH",
      "label": "KANJAGA COMM. SENIOR HIGH SCHOOL",
      "value": "KANJAGA COMM. SENIOR HIGH SCHOOL",
      "location": "KANJAGA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "kanjagacommshs@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "BUILSA SOUTH",
      "label": "FUMBISI SENIOR HIGH SCHOOL",
      "value": "FUMBISI SENIOR HIGH SCHOOL",
      "location": "FUMBISI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "fumbisishs@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "GARU TEMPANE",
      "label": "GARU COMM. DAY SENIOR HIGH SCHOOL",
      "value": "GARU COMM. DAY SENIOR HIGH SCHOOL",
      "location": "GARU",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "garucommshs@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "GARU TEMPANI",
      "label": "TEMPANE SENIOR HIGH SCHOOL",
      "value": "TEMPANE SENIOR HIGH SCHOOL",
      "location": "TEMPANE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "tempaneshs@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "KASENA-NANKANI",
      "label": "O.L.L. GIRLS SENIOR HIGH SCHOOL",
      "value": "O.L.L. GIRLS SENIOR HIGH SCHOOL",
      "location": "NAVRONGO",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "ourladyoflourdesgilrlsshs@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "KASENA-NANKANI\r\nMUNICIPAL",
      "label": "AWE SENIOR HIGH/TECH SCHOOL",
      "value": "AWE SENIOR HIGH/TECH SCHOOL",
      "location": "NAVRONGO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "aweshst@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "KASENA-NANKANI\r\nMUNICIPAL",
      "label": "ST. JOHN'S INTEGRATED SENIOR HIGH/TECH\r\nSCHOOL",
      "value": "ST. JOHN'S INTEGRATED SENIOR HIGH/TECH\r\nSCHOOL",
      "location": "TONO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "stjohnsintegratedshst@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "KASENA-NANKANI\r\nMUNICIPAL",
      "label": "NOTRE DAME SEM/ SENIOR HIGH SCHOOL\r\nNAVRONGO",
      "value": "NOTRE DAME SEM/ SENIOR HIGH SCHOOL\r\nNAVRONGO",
      "location": "NAVRONGO",
      "gender": "MALE",
      "residency": "DAY/BOARDING",
      "email": "notredamesemshs@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "KASENA-NANKANI\r\nMUNICIPAL",
      "label": "NAVRONGO SENIOR HIGH SCHOOL",
      "value": "NAVRONGO SENIOR HIGH SCHOOL",
      "location": "JANANIA-\r\nNAVRONGO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "navrongoshs@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "KASENA-NANKANI WEST",
      "label": "PAGA SENIOR HIGH SCHOOL",
      "value": "PAGA SENIOR HIGH SCHOOL",
      "location": "PAGA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "pagashs@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "KASENA-NANKANI WEST",
      "label": "SIRIGU INTEGRATED SENIOR HIGH SCHOOL",
      "value": "SIRIGU INTEGRATED SENIOR HIGH SCHOOL",
      "location": "SIRIGU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "sirigushs@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "KASENA-NANKANI WEST",
      "label": "NABANGO SENIOR HIGH SCHOOL",
      "value": "NABANGO SENIOR HIGH SCHOOL",
      "location": "NABANGO",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "nabangoshs@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "KASENA-NANKANI WEST",
      "label": "MIRIGU COMMUNITY DAY SENIOR HIGH SCHOOL",
      "value": "MIRIGU COMMUNITY DAY SENIOR HIGH SCHOOL",
      "location": "MIRIGU",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "mirigucommshs@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "KASENA-NANKANI WEST",
      "label": "CHIANA SENIOR HIGH SCHOOL",
      "value": "CHIANA SENIOR HIGH SCHOOL",
      "location": "CHIANA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "chianashs@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "KASSENA NANAKANA EAST",
      "label": "ST. BERNADETTES TECH/VOC. INST.",
      "value": "ST. BERNADETTES TECH/VOC. INST.",
      "location": "NAVRONGO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "stbernadettestechvocinst@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "NABDAM",
      "label": "KONGO SENIOR HIGH SCHOOL",
      "value": "KONGO SENIOR HIGH SCHOOL",
      "location": "KONGO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "kongoshs@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "TALENSI",
      "label": "TONGO SENIOR HIGH/TECH SCHOOL",
      "value": "TONGO SENIOR HIGH/TECH SCHOOL",
      "location": "TONGO",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "tongoshst@ges.gov.gh"
    },
    {
      "region": "UPPER EAST",
      "district": "TALENSI",
      "label": "BOLGATANGA SENIOR HIGH SCHOOL",
      "value": "BOLGATANGA SENIOR HIGH SCHOOL",
      "location": "BOLGATANGA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "bolgatangashs@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "BUSSIE-ISSA",
      "label": "DAFFIAMAH SENIOR HIGH SCHOOL",
      "value": "DAFFIAMAH SENIOR HIGH SCHOOL",
      "location": "DAFFIAMAH",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "daffiamahshs@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "JIRAPA",
      "label": "ULLO SENIOR HIGH SCHOOL",
      "value": "ULLO SENIOR HIGH SCHOOL",
      "location": "ULLO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "ulloshs@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "JIRAPA",
      "label": "HAN SENIOR HIGH SCHOOL",
      "value": "HAN SENIOR HIGH SCHOOL",
      "location": "JIRAPA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "hanshs@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "JIRAPA",
      "label": "JIRAPA SENIOR HIGH SCHOOL",
      "value": "JIRAPA SENIOR HIGH SCHOOL",
      "location": "JIRAPA-TOWN",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "jirapashs@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "JIRAPA",
      "label": "ST. FRANCIS GIRLS SENIOR HIGH SCHOOL, JIRAPA",
      "value": "ST. FRANCIS GIRLS SENIOR HIGH SCHOOL, JIRAPA",
      "location": "JIRAPA",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "stfrancisgirlsshs@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "LAMBUSIE-KARNI",
      "label": "PIINA SENIOR HIGH SCHOOL",
      "value": "PIINA SENIOR HIGH SCHOOL",
      "location": "PIINA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "piinashs@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "LAMBUSIE-KARNI",
      "label": "LAMBUSSIE COMM SENIOR HIGH SCHOOL",
      "value": "LAMBUSSIE COMM SENIOR HIGH SCHOOL",
      "location": "LAMBUSSIE",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "lambussiecommshs@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "LAWRA",
      "label": "EREMON SENIOR HIGH/TECH SCHOOL",
      "value": "EREMON SENIOR HIGH/TECH SCHOOL",
      "location": "EREMON",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "eremonshst@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "LAWRA",
      "label": "LAWRA SENIOR HIGH SCHOOL",
      "value": "LAWRA SENIOR HIGH SCHOOL",
      "location": "LAWRA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "lawrashs@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "LAWRA",
      "label": "BIRIFOH SENIOR HIGH SCHOOL",
      "value": "BIRIFOH SENIOR HIGH SCHOOL",
      "location": "LAWRA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "birifohshs@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "NADOWLI",
      "label": "QUEEN OF PEACE SENIOR HIGH SCHOOL, NADOWLI",
      "value": "QUEEN OF PEACE SENIOR HIGH SCHOOL, NADOWLI",
      "location": "NADOWLI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "queenofpeaceshs@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "NADOWLI",
      "label": "ST. AUGUSTINE SENIOR HIGH/TECH SCHOOL, SAAN\r\nCHARIKPONG",
      "value": "ST. AUGUSTINE SENIOR HIGH/TECH SCHOOL, SAAN\r\nCHARIKPONG",
      "location": "SAAN,\r\nCHARIKPONG",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "st.augustinecharikpongshst@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "NADOWLI",
      "label": "TAKPO SENIOR HIGH SCHOOL",
      "value": "TAKPO SENIOR HIGH SCHOOL",
      "location": "TAKPO",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "takposhst@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "NADOWLI",
      "label": "KALEO SENIOR HIGH/TECH SCHOOL",
      "value": "KALEO SENIOR HIGH/TECH SCHOOL",
      "location": "KALEO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "kaleoshst@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "NADOWLI KALEO",
      "label": "ST. BASILIDES VOC./TECH. INST.",
      "value": "ST. BASILIDES VOC./TECH. INST.",
      "location": "KALEO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "stbasilidestechvocinst@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "NADOWLI/KALEO",
      "label": "SOMBO SENIOR HIGH SCHOOL",
      "value": "SOMBO SENIOR HIGH SCHOOL",
      "location": "SOMBO",
      "gender": "FEMALE",
      "residency": "DAY",
      "email": "somboshs@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "NANDOM",
      "label": "NANDOM SENIOR HIGH SCHOOL",
      "value": "NANDOM SENIOR HIGH SCHOOL",
      "location": "NANDOM",
      "gender": "MALE",
      "residency": "DAY/BOARDING",
      "email": "nandomshs@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "NANDOM",
      "label": "HOLY FAMILY SENIOR HIGH SCHOOL",
      "value": "HOLY FAMILY SENIOR HIGH SCHOOL",
      "location": "HAMILE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "holyfamilyshs@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "NANDOM",
      "label": "ST.JOHN'S VOC. TECH. INSTITUTE",
      "value": "ST.JOHN'S VOC. TECH. INSTITUTE",
      "location": "NANDOM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "stjohnstechvocinst@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "NANDOM",
      "label": "KO SENIOR HIGH SCHOOL",
      "value": "KO SENIOR HIGH SCHOOL",
      "location": "KO-NANDOM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "koshs@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "SISALA EAST",
      "label": "TUMU SENIOR HIGH/TECH SCHOOL",
      "value": "TUMU SENIOR HIGH/TECH SCHOOL",
      "location": "TUMU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "tumushst@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "SISALA EAST",
      "label": "KANTON SENIOR HIGH SCHOOL",
      "value": "KANTON SENIOR HIGH SCHOOL",
      "location": "TUMU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "kantonshs@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "SISALA WEST",
      "label": "DR. HILA LIMAN SENIOR  HIGH SCHOOL",
      "value": "DR. HILA LIMAN SENIOR  HIGH SCHOOL",
      "location": "GWOLLU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "drhilalimanshs@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "WA EAST",
      "label": "LOGGU COMM. DAY SCHOOL",
      "value": "LOGGU COMM. DAY SCHOOL",
      "location": "LOGGU",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "loggucommshs@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "WA EAST",
      "label": "FUNSI SENIOR HIGH SCHOOL",
      "value": "FUNSI SENIOR HIGH SCHOOL",
      "location": "FUNSI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "funsishs@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "WA MUNICIPAL",
      "label": "T. I. AHMADIYYA SENIOR HIGH SCHOOL, WA",
      "value": "T. I. AHMADIYYA SENIOR HIGH SCHOOL, WA",
      "location": "WA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "tiahmadiyyawashs@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "WA MUNICIPAL",
      "label": "NORTHERN STAR SENIOR HIGH SCHOOL",
      "value": "NORTHERN STAR SENIOR HIGH SCHOOL",
      "location": "MANGU, WA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "northernstarshs@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "WA MUNICIPAL",
      "label": "JAMIAT AL-HIDAYA ISLAMIC GIRLS SENIOR HIGH\r\nSCHOOL",
      "value": "JAMIAT AL-HIDAYA ISLAMIC GIRLS SENIOR HIGH\r\nSCHOOL",
      "location": "WA",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "jamiatalhidayaislamicgirlsshs@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "WA MUNICIPAL",
      "label": "WA SENIOR HIGH/TECH SCHOOL",
      "value": "WA SENIOR HIGH/TECH SCHOOL",
      "location": "WA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "washst@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "WA MUNICIPAL",
      "label": "WA SENIOR HIGH SCHOOL",
      "value": "WA SENIOR HIGH SCHOOL",
      "location": "WA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "washs@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "WA MUNICIPAL",
      "label": "WA TECH. INST.",
      "value": "WA TECH. INST.",
      "location": "WA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "watechinst@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "WA MUNICIPAL",
      "label": "ISLAMIC SENIOR HIGH SCHOOL, WA",
      "value": "ISLAMIC SENIOR HIGH SCHOOL, WA",
      "location": "WA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "islamicwashs@ges.gov.gh"
    },
    {
      "region": "UPPER WEST",
      "district": "WA WEST",
      "label": "LASSIE-TUOLU SENIOR HIGH SCHOOL",
      "value": "LASSIE-TUOLU SENIOR HIGH SCHOOL",
      "location": "LASSIE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "lassietuolushs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "ADAKLU",
      "label": "ABUADI/TSREFE SENIOR HIGH SCHOOL",
      "value": "ABUADI/TSREFE SENIOR HIGH SCHOOL",
      "location": "ADAKLU -\r\nABUADI",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "abuaditsrefecomm.shs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "ADAKLU-ANYIGBE",
      "label": "ADAKLU SENIOR HIGH SCHOOL",
      "value": "ADAKLU SENIOR HIGH SCHOOL",
      "location": "ADAKLU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "adaklushs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "AFADZATO SOUTH",
      "label": "VE COMM. SENIOR HIGH SCHOOL",
      "value": "VE COMM. SENIOR HIGH SCHOOL",
      "location": "VE-KOLOENU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "vecommshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "AFADZATO SOUTH",
      "label": "LEKLEBI SENIOR HIGH SCHOOL",
      "value": "LEKLEBI SENIOR HIGH SCHOOL",
      "location": "LEKLEBI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "leklebishs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "AFADZATO SOUTH",
      "label": "JIM BOURTON MEM AGRIC. SENIOR HIGH SCHOOL",
      "value": "JIM BOURTON MEM AGRIC. SENIOR HIGH SCHOOL",
      "location": "LOGBA ADZOKOE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "jimbourtonmemagricshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "AFADZATO SOUTH",
      "label": "AGATE COMM. SENIOR HIGH SCHOOL",
      "value": "AGATE COMM. SENIOR HIGH SCHOOL",
      "location": "AGATE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "agatecommshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "AFADZTO SOUTH",
      "label": "ST. DANIEL COMBONI TECH/VOC INST.",
      "value": "ST. DANIEL COMBONI TECH/VOC INST.",
      "location": "LIATI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "stdanielcombonitechvocinst@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "AFADZTO SOUTH",
      "label": "HAVE TECH. INST.",
      "value": "HAVE TECH. INST.",
      "location": "HAVE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "havetechinst@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "AGORTIME ZIOPE",
      "label": "AGOTIME SENIOR HIGH SCHOOL",
      "value": "AGOTIME SENIOR HIGH SCHOOL",
      "location": "KPETOE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "agotimeshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "AGORTIME ZIOPE",
      "label": "ZIOPE SENIOR HIGH SCHOOL",
      "value": "ZIOPE SENIOR HIGH SCHOOL",
      "location": "ZIOPE",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "ziopeshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "AKATSI",
      "label": "AKATSI SENIOR HIGH/TECH SCHOOL",
      "value": "AKATSI SENIOR HIGH/TECH SCHOOL",
      "location": "AKATSI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "akatsishst@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "AKATSI NORTH",
      "label": "AVE SENIOR HIGH SCHOOL",
      "value": "AVE SENIOR HIGH SCHOOL",
      "location": "AVE-DAKPA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "aveshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "AKATSI SOUTH",
      "label": "AVENOR SENIOR HIGH SCHOOL",
      "value": "AVENOR SENIOR HIGH SCHOOL",
      "location": "AVENORKPEME",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "avenorshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "ANLO KETA MUNICIPAL",
      "label": "ABOR SENIOR HIGH SCHOOL",
      "value": "ABOR SENIOR HIGH SCHOOL",
      "location": "ABOR",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "aborshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "ANLO KETA MUNICIPAL",
      "label": "ANLO AFIADENYIGBA SENIOR HIGH SCHOOL",
      "value": "ANLO AFIADENYIGBA SENIOR HIGH SCHOOL",
      "location": "ANLO\r\nAFIADENYIGBA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "anloafiadenyigbashs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "ANLO KETA MUNICIPAL",
      "label": "TSIAME SENIOR HIGH SCHOOL",
      "value": "TSIAME SENIOR HIGH SCHOOL",
      "location": "TSIAME",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "tsiameshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "ANLO KETA MUNICIPAL",
      "label": "ANLO AWOMEFIA SENIOR HIGH SCHOOL",
      "value": "ANLO AWOMEFIA SENIOR HIGH SCHOOL",
      "location": "ANYAKO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "anloawomefiashs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "ANLO KETA MUNICIPAL",
      "label": "ATIAVI SENIOR HIGH/TECH SCHOOL",
      "value": "ATIAVI SENIOR HIGH/TECH SCHOOL",
      "location": "ATIAVI",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "atiavishst@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "ANLO KETA MUNICIPAL",
      "label": "KETA BUSINESS SENIOR HIGH SCHOOL",
      "value": "KETA BUSINESS SENIOR HIGH SCHOOL",
      "location": "KETA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "ketabusinessshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "ANLO KETA MUNICIPAL",
      "label": "ANLO SENIOR HIGH SCHOOL",
      "value": "ANLO SENIOR HIGH SCHOOL",
      "location": "ANLOGA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "anloshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "ANLO KETA MUNICIPAL",
      "label": "ZION SENIOR HIGH SCHOOL",
      "value": "ZION SENIOR HIGH SCHOOL",
      "location": "ANLOGA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "zionshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "ANLO KETA MUNICIPAL",
      "label": "VOLTA SENIOR HIGH SCHOOL",
      "value": "VOLTA SENIOR HIGH SCHOOL",
      "location": "DZITA-\r\nAGBLEDOMI",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "voltashs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "CENTRAL TONGU",
      "label": "MAFI-KUMASI SENIOR HIGH/TECH SCHOOL",
      "value": "MAFI-KUMASI SENIOR HIGH/TECH SCHOOL",
      "location": "MAFI-KUMASI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "mafikumasishst@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "CENTRAL TONGU",
      "label": "ADIDOME SENIOR HIGH SCHOOL",
      "value": "ADIDOME SENIOR HIGH SCHOOL",
      "location": "ADIDOME",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "adidomeshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "HO",
      "label": "VOLTA TECH INST",
      "value": "VOLTA TECH INST",
      "location": "MATSE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "voltatechinst@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "HO MUNICIPAL",
      "label": "SHIA SENIOR HIGHTECHNICAL SCHOOL",
      "value": "SHIA SENIOR HIGHTECHNICAL SCHOOL",
      "location": "SHIA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "shiashst@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "HO MUNICIPAL",
      "label": "TANYIGBE SENIOR HIGH SCHOOL",
      "value": "TANYIGBE SENIOR HIGH SCHOOL",
      "location": "TANYIGBE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "tanyigbeshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "HO MUNICIPAL",
      "label": "OLA GIRLS SENIOR HIGH SCHOOL, HO",
      "value": "OLA GIRLS SENIOR HIGH SCHOOL, HO",
      "location": "HO",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "olagirlsshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "HO MUNICIPAL",
      "label": "TAVIEFE COMM. SENIOR HIGH SCHOOL",
      "value": "TAVIEFE COMM. SENIOR HIGH SCHOOL",
      "location": "TAVIEFE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "taviefecommshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "HO MUNICIPAL",
      "label": "E.P.C. MAWUKO GIRLS SENIOR HIGH SCHOOL",
      "value": "E.P.C. MAWUKO GIRLS SENIOR HIGH SCHOOL",
      "location": "HO",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "epcmawukogirlsshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "HO MUNICIPAL",
      "label": "MAWULI SCHOOL, HO",
      "value": "MAWULI SCHOOL, HO",
      "location": "HO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "mawulischoolshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "HO MUNICIPAL",
      "label": "SOKODE SENIOR HIGH/TECH SCHOOL",
      "value": "SOKODE SENIOR HIGH/TECH SCHOOL",
      "location": "SOKODE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "sokodeshst@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "HO MUNICIPAL",
      "label": "AWUDOME SENIOR HIGH SCHOOL",
      "value": "AWUDOME SENIOR HIGH SCHOOL",
      "location": "TSITO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "awudomeshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "HO WEST",
      "label": "AMEDZOFE TECHNICAL INSTITUTE",
      "value": "AMEDZOFE TECHNICAL INSTITUTE",
      "location": "AMEDZOFE",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "amedzofetechinst@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "HO WEST",
      "label": "KPEDZE SENIOR HIGH SCHOOL",
      "value": "KPEDZE SENIOR HIGH SCHOOL",
      "location": "KPEDZE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "kpedzesehs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "HO WEST",
      "label": "AVATIME SENIOR HIGH SCHOOL",
      "value": "AVATIME SENIOR HIGH SCHOOL",
      "location": "VANE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "avatimeshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "HO WEST",
      "label": "AKOME SENIOR HIGH/TECH SCHOOL",
      "value": "AKOME SENIOR HIGH/TECH SCHOOL",
      "location": "AKOME",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "akomeshst@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "HO WEST",
      "label": "DZOLO SENIOR HIGH SCHOOL",
      "value": "DZOLO SENIOR HIGH SCHOOL",
      "location": "DZOLO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "dzoloshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "HO WEST",
      "label": "TSITO SENIOR HIGH/TECH SCHOOL",
      "value": "TSITO SENIOR HIGH/TECH SCHOOL",
      "location": "TSITO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "tsitoshst@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "HO WEST",
      "label": "ABUTIA SENIOR HIGH/TCHNICAL SCHOOL",
      "value": "ABUTIA SENIOR HIGH/TCHNICAL SCHOOL",
      "location": "ABUTIA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "abutiashst@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "HOHOE",
      "label": "E. P. TECHNICAL VOCATIONAL INSTITUTE\r\nALAVANYO",
      "value": "E. P. TECHNICAL VOCATIONAL INSTITUTE\r\nALAVANYO",
      "location": "FORSON DOTSE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "eptechvocinst@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "HOHOE MUNICIPAL",
      "label": "AKPAFU SENIOR HIGH/TECH SCHOOL",
      "value": "AKPAFU SENIOR HIGH/TECH SCHOOL",
      "location": "AKPAFU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "akpafushst@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "HOHOE MUNICIPAL",
      "label": "LIKPE SENIOR HIGH SCHOOL",
      "value": "LIKPE SENIOR HIGH SCHOOL",
      "location": "LIKPE-MATE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "likpeshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "HOHOE MUNICIPAL",
      "label": "E. P. SENIOR HIGH SCHOOL",
      "value": "E. P. SENIOR HIGH SCHOOL",
      "location": "HOHOE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "epshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "HOHOE MUNICIPAL",
      "label": "ST. MARY'S SEM.& SENIOR HIGH SCHOOL, LOLOBI",
      "value": "ST. MARY'S SEM.& SENIOR HIGH SCHOOL, LOLOBI",
      "location": "LOLOBI",
      "gender": "MALE",
      "residency": "DAY/BOARDING",
      "email": "stmaryssemshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "HOHOE MUNICIPAL",
      "label": "ALAVANYO SENIOR HIGH/TECH SCHOOL",
      "value": "ALAVANYO SENIOR HIGH/TECH SCHOOL",
      "location": "ALAVANYO-\r\nKPEME",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "alavanyoshst@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "HOHOE MUNICIPAL",
      "label": "AFADJATO SENIOR HIGH/TECH SCHOOL",
      "value": "AFADJATO SENIOR HIGH/TECH SCHOOL",
      "location": "GBLEDI-\r\nGBOGAME",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "afadjatoshst@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "KETA",
      "label": "KETA SENIOR HIGH/TECH SCHOOL",
      "value": "KETA SENIOR HIGH/TECH SCHOOL",
      "location": "KETA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "ketashst@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "KETA",
      "label": "ANLO TECH. INST.",
      "value": "ANLO TECH. INST.",
      "location": "ANLOGA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "anlotechinst@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "KETU NORTH",
      "label": "DZODZE PENYI SENIOR HIGH SCHOOL",
      "value": "DZODZE PENYI SENIOR HIGH SCHOOL",
      "location": "DZODZE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "dzodzepenyishs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "KETU NORTH",
      "label": "WOVENU SENIOR HIGH TECHNICAL SCHOOL",
      "value": "WOVENU SENIOR HIGH TECHNICAL SCHOOL",
      "location": "TADZEWU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "wovenushst@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "KETU NORTH",
      "label": "WETA SENIOR HIGH/TECH SCHOOL",
      "value": "WETA SENIOR HIGH/TECH SCHOOL",
      "location": "WETA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "wetashst@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "KETU NORTH",
      "label": "AFIFE SENIOR HIGH TECH SCHOOL",
      "value": "AFIFE SENIOR HIGH TECH SCHOOL",
      "location": "AFIFE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "afifeshst@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "KETU SOUTH",
      "label": "ST. PAUL'S SENIOR HIGH SCHOOL, DENU",
      "value": "ST. PAUL'S SENIOR HIGH SCHOOL, DENU",
      "location": "HATSUKOPE/\r\nDENU",
      "gender": "MALE",
      "residency": "DAY/BOARDING",
      "email": "stpaulsdenushs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "KETU SOUTH",
      "label": "THREE TOWN SENIOR HIGH SCHOOL",
      "value": "THREE TOWN SENIOR HIGH SCHOOL",
      "location": "DENU",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "threetownshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "KETU SOUTH",
      "label": "SOME SENIOR HIGH SCHOOL",
      "value": "SOME SENIOR HIGH SCHOOL",
      "location": "AGBOZUME",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "someshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "KETU SOUTH",
      "label": "KLIKOR SENIOR HIGH/TECH SCHOOL",
      "value": "KLIKOR SENIOR HIGH/TECH SCHOOL",
      "location": "KLIKOR",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "klikorshst@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "KPANDO",
      "label": "BISHOP HERMAN COLLEGE",
      "value": "BISHOP HERMAN COLLEGE",
      "location": "KPANDO",
      "gender": "MALE",
      "residency": "DAY/BOARDING",
      "email": "bishophermancollegeshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "KPANDO",
      "label": "KPANDO SENIOR HIGH SCHOOL",
      "value": "KPANDO SENIOR HIGH SCHOOL",
      "location": "KPANDO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "kpandoshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "KPANDO",
      "label": "KPANDO TECH. INST.",
      "value": "KPANDO TECH. INST.",
      "location": "KPANDO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "kpandotechinst@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "KPANDO",
      "label": "C.Y.O.VOC. TECH. INST.",
      "value": "C.Y.O.VOC. TECH. INST.",
      "location": "SOVIE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "cyotechvocinst@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "NORTH DAYI",
      "label": "ANFOEGA SENIOR HIGH SCHOOL",
      "value": "ANFOEGA SENIOR HIGH SCHOOL",
      "location": "ANFOEGA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "anfoegashs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "NORTH DAYI",
      "label": "VAKPO SENIOR HIGH/TECH SCHOOL",
      "value": "VAKPO SENIOR HIGH/TECH SCHOOL",
      "location": "VAKPO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "vakposhst@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "NORTH DAYI",
      "label": "VAKPO SENIOR HIGH SCHOOL",
      "value": "VAKPO SENIOR HIGH SCHOOL",
      "location": "VAKPO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "vakposhs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "NORTH TONGU",
      "label": "DOFOR SENIOR HIGH SCHOOL",
      "value": "DOFOR SENIOR HIGH SCHOOL",
      "location": "JUAPONG",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "doforshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "NORTH TONGU",
      "label": "MEPE ST. KIZITO SENIOR HIGH/TECH SCHOOL",
      "value": "MEPE ST. KIZITO SENIOR HIGH/TECH SCHOOL",
      "location": "MEPE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "mepestkizitoshst@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "NORTH TONGU",
      "label": "BATTOR SENIOR HIGH SCHOOL",
      "value": "BATTOR SENIOR HIGH SCHOOL",
      "location": "BATTOR",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "battorshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "NORTH TONGU",
      "label": "VOLO COMM. SENIOR HIGH SCHOOL",
      "value": "VOLO COMM. SENIOR HIGH SCHOOL",
      "location": "VOLO",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "volocommshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "NORTH TONGU",
      "label": "AVEYIME BATTOR SENIOR HIGH/TECH SCHOOL",
      "value": "AVEYIME BATTOR SENIOR HIGH/TECH SCHOOL",
      "location": "AVEYIME-\r\nBATTOR",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "aveyimebattorshst@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "SOUTH DAYI",
      "label": "KPEVE SENIOR HIGH SCHOOL",
      "value": "KPEVE SENIOR HIGH SCHOOL",
      "location": "KPEVE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "kpeveshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "SOUTH DAYI",
      "label": "TONGOR SENIOR HIGH TECH SCHOOL",
      "value": "TONGOR SENIOR HIGH TECH SCHOOL",
      "location": "KPEVE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "tongorshst@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "SOUTH DAYI",
      "label": "PEKI SENIOR HIGH SCHOOL",
      "value": "PEKI SENIOR HIGH SCHOOL",
      "location": "PEKI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "pekishs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "SOUTH DAYI",
      "label": "PEKI SENIOR HIGH/TECHNICAL SCHOOL",
      "value": "PEKI SENIOR HIGH/TECHNICAL SCHOOL",
      "location": "PEKI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "pekishst@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "SOUTH TONGU",
      "label": "ST. CATHERINE GIRLS SENIOR HIGH SCHOOL",
      "value": "ST. CATHERINE GIRLS SENIOR HIGH SCHOOL",
      "location": "AGBAKOPE",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "stcatherinegirlsshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "SOUTH TONGU",
      "label": "COMBONI TECH/VOC INST.",
      "value": "COMBONI TECH/VOC INST.",
      "location": "SOGAKOPE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "combonitechvocinst@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "SOUTH TONGU",
      "label": "SOGAKOPE SENIOR HIGH SCHOOL",
      "value": "SOGAKOPE SENIOR HIGH SCHOOL",
      "location": "SOGAKOPE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "sogakopeshs@ges.gov.gh"
    },
    {
      "region": "VOLTA",
      "district": "SOUTH TONGU",
      "label": "DABALA SENIOR HIGH/TECH.",
      "value": "DABALA SENIOR HIGH/TECH.",
      "location": "DABALA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "dabalashst@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "WASSA AMENFI WEST",
      "label": "ASANKRANGWA SENIOR HIGH/TECH SCHOOL",
      "value": "ASANKRANGWA SENIOR HIGH/TECH SCHOOL",
      "location": "ASANKRAGWA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "asankrangwashst@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "AHANTA WEST",
      "label": "ST. MARY'S BOYS' SENIOR HIGH SCHOOL, APOWA",
      "value": "ST. MARY'S BOYS' SENIOR HIGH SCHOOL, APOWA",
      "location": "APOWA",
      "gender": "MALE",
      "residency": "DAY/BOARDING",
      "email": "stmarysboysshs@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "AHANTA WEST",
      "label": "BAIDOO BONSOE SENIOR HIGH/TECH SCHOOL",
      "value": "BAIDOO BONSOE SENIOR HIGH/TECH SCHOOL",
      "location": "AGONA\r\nNKWANTA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "baidoobonsoshst@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "AHANTA WEST",
      "label": "SANKOR COMM. DAY SENIOR HIGH SCHOOL",
      "value": "SANKOR COMM. DAY SENIOR HIGH SCHOOL",
      "location": "SANKOR",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "sankorcommshs@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "ELLEMBELE",
      "label": "BONZO-KAKU SENIOR HIGH SCHOOL",
      "value": "BONZO-KAKU SENIOR HIGH SCHOOL",
      "location": "AWIEBO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "bonzokakushs@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "ELLEMBELE",
      "label": "UTHMAN BIN AFAM SENIOR HIGH SCHOOL",
      "value": "UTHMAN BIN AFAM SENIOR HIGH SCHOOL",
      "location": "KAMGBUNLI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "uthmanbinafamshs@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "ELLEMBELE",
      "label": "NKROFUL AGRIC. SENIOR HIGH SCHOOL",
      "value": "NKROFUL AGRIC. SENIOR HIGH SCHOOL",
      "location": "NKROFUL",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "nkrofulagricshs@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "ELLEMBELE",
      "label": "ESIAMA SENIOR HIGH/TECH SCHOOL",
      "value": "ESIAMA SENIOR HIGH/TECH SCHOOL",
      "location": "ESIAMA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "esiamashst@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "ELLEMBELLE",
      "label": "KIKAM TECH. INST.",
      "value": "KIKAM TECH. INST.",
      "location": "KIKAM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "kikamtechinst@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "JOMORO",
      "label": "HALF ASSINI SENIOR HIGH SCHOOL",
      "value": "HALF ASSINI SENIOR HIGH SCHOOL",
      "location": "HALF ASSINI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "halfassinishs@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "JOMORO",
      "label": "ANNOR ADJAYE SENIOR HIGH SCHOOL",
      "value": "ANNOR ADJAYE SENIOR HIGH SCHOOL",
      "location": "EZILIBO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "annoradjayeshs@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "MPOHOR",
      "label": "MPOHOR SENIOR HIGH SCHOOL",
      "value": "MPOHOR SENIOR HIGH SCHOOL",
      "location": "MPOHOR",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "mpohorshs@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "MPOHOR WASSA EAST",
      "label": "DABOASE SENIOR HIGH/TECH SCHOOL",
      "value": "DABOASE SENIOR HIGH/TECH SCHOOL",
      "location": "DABOASE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "daboaseshst@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "NZEMA EAST MUNICIPAL",
      "label": "GWIRAMAN COMM.SENIOR HIGH SCHOOL",
      "value": "GWIRAMAN COMM.SENIOR HIGH SCHOOL",
      "location": "BAMIANKO",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "gwiramancommshs@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "NZEMA EAST MUNICIPAL",
      "label": "NSEIN SENIOR HIGH SCHOOL",
      "value": "NSEIN SENIOR HIGH SCHOOL",
      "location": "NSEIN",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "nseinshs@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "NZEMA EAST MUNICIPAL",
      "label": "AXIM GIRLS SENIOR HIGH SCHOOL",
      "value": "AXIM GIRLS SENIOR HIGH SCHOOL",
      "location": "AXIM",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "aximgirlsshs@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "PRESTEA HUNI VALLEY",
      "label": "ST. AUGUSTINE'S SENIOR HIGH SCHOOL, BOGOSO",
      "value": "ST. AUGUSTINE'S SENIOR HIGH SCHOOL, BOGOSO",
      "location": "BOGOSO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "staugustinesbogososhs@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "PRESTEA HUNI VALLEY",
      "label": "HUNI VALLEY SENIOR HIGH SCHOOL",
      "value": "HUNI VALLEY SENIOR HIGH SCHOOL",
      "location": "HUNI VALLLEY",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "hunivalleyshs@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "PRESTEA HUNI VALLEY",
      "label": "PRESTEA SENIOR HIGH/TECH SCHOOL",
      "value": "PRESTEA SENIOR HIGH/TECH SCHOOL",
      "location": "PRESTEA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "presteashst@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "SEKONDI TAKORADI METRO",
      "label": "SEKONDI COLLEGE",
      "value": "SEKONDI COLLEGE",
      "location": "SEKONDI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "sekondicollege@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "SEKONDI TAKORADI METRO",
      "label": "DIABENE SENIOR HIGH/TECH SCHOOL",
      "value": "DIABENE SENIOR HIGH/TECH SCHOOL",
      "location": "DIABENE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "diabeneshst@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "SEKONDI TAKORADI METRO",
      "label": "ARCHBISHOP PORTER GIRLS SENIOR HIGH SCHOOL",
      "value": "ARCHBISHOP PORTER GIRLS SENIOR HIGH SCHOOL",
      "location": "SEKONDI",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "archbishopportergirlshs@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "SEKONDI TAKORADI METRO",
      "label": "ADIEMBRA SENIOR HIGH SCHOOL",
      "value": "ADIEMBRA SENIOR HIGH SCHOOL",
      "location": "DIABENE",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "adiembrashs@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "SEKONDI TAKORADI METRO",
      "label": "METHODIST SENIOR HIGH SCHOOL, SEKONDI",
      "value": "METHODIST SENIOR HIGH SCHOOL, SEKONDI",
      "location": "SEKONDI",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "methodistsekondishs@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "SEKONDI TAKORADI METRO",
      "label": "ST. JOHN'S SENIOR HIGH SCHOOL, SEKONDI",
      "value": "ST. JOHN'S SENIOR HIGH SCHOOL, SEKONDI",
      "location": "SEKONDI",
      "gender": "MALE",
      "residency": "DAY/BOARDING",
      "email": "stjohnsshs@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "SEKONDI TAKORADI METRO",
      "label": "FIJAI SENIOR HIGH SCHOOL",
      "value": "FIJAI SENIOR HIGH SCHOOL",
      "location": "SEKONDI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "fijaishs@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "SEKONDI TAKORADI METRO",
      "label": "TAKORADI SENIOR HIGH SCHOOL",
      "value": "TAKORADI SENIOR HIGH SCHOOL",
      "location": "TANOKROM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "takoradishs@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "SEKONDI TAKORADI METRO",
      "label": "GHANA SENIOR HIGH/TECH SCHOOL",
      "value": "GHANA SENIOR HIGH/TECH SCHOOL",
      "location": "TAKORADI",
      "gender": "MALE",
      "residency": "DAY/BOARDING",
      "email": "ghanashst@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "SEKONDI TAKORADI METRO",
      "label": "BOMPEH SENIOR HIGH./TECH SCHOOL",
      "value": "BOMPEH SENIOR HIGH./TECH SCHOOL",
      "location": "TAKORADI",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "bompehshst@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "SEKONDI-TAKORADI",
      "label": "TAKORADI TECH. INST.",
      "value": "TAKORADI TECH. INST.",
      "location": "TAKORADI",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "takoraditechinst@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "SHAMA",
      "label": "SHAMA SENIOR HIGH SCHOOL",
      "value": "SHAMA SENIOR HIGH SCHOOL",
      "location": "SHAMA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "shamashs@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "SHAMA AHANTA EAST",
      "label": "AHANTAMAN GIRLS' SENIOR HIGH SCHOOL",
      "value": "AHANTAMAN GIRLS' SENIOR HIGH SCHOOL",
      "location": "SEKONDI-KETAN",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "ahantamangirlsshs@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "TARKWA-NSUAEM\r\nMUNICIPAL",
      "label": "TARKWA SENIOR HIGH SCHOOL",
      "value": "TARKWA SENIOR HIGH SCHOOL",
      "location": "TARKWA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "tarkwashs@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "TARKWA-NSUAEM\r\nMUNICIPAL",
      "label": "FIASEMAN SENIOR HIGH SCHOOL",
      "value": "FIASEMAN SENIOR HIGH SCHOOL",
      "location": "BANKYIM\r\nTARKWA*",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "fiasemanshs@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "TARKWA-NSUAEM\r\nMUNICIPAL",
      "label": "BENSO SENIOR HIGH/TECH SCHOOL",
      "value": "BENSO SENIOR HIGH/TECH SCHOOL",
      "location": "BENSO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "bensoshst@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "WASSA AMENFI EAST",
      "label": "AMENFIMAN SENIOR HIGH SCHOOL",
      "value": "AMENFIMAN SENIOR HIGH SCHOOL",
      "location": "WASA\r\nAKROPONG",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "amenfimanshs@ges.gov.gh"
    },
    {
      "region": "WESTERN",
      "district": "WASSA AMENFI WEST",
      "label": "ASANKRANGWA SENIOR HIGH SCHOOL",
      "value": "ASANKRANGWA SENIOR HIGH SCHOOL",
      "location": "ASANKRAGWA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "asankrangwashs@ges.gov.gh"
    },
    {
      "region": "WESTERN\r\nNORTH",
      "district": "AOWIN SUAMAN",
      "label": "NANA BRENTU SENIOR HIGH/TECH SCHOOL",
      "value": "NANA BRENTU SENIOR HIGH/TECH SCHOOL",
      "location": "ENCHI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "nanabrentushst@ges.gov.gh"
    },
    {
      "region": "WESTERN\r\nNORTH",
      "district": "BIA WEST",
      "label": "BIA SENIOR HIGH/TECH SCHOOL",
      "value": "BIA SENIOR HIGH/TECH SCHOOL",
      "location": "DEBISO ESSIAM",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "biashst@ges.gov.gh"
    },
    {
      "region": "WESTERN\r\nNORTH",
      "district": "BIA WEST",
      "label": "ADJOAFUA COMM. SENIOR HIGH SCHOOL",
      "value": "ADJOAFUA COMM. SENIOR HIGH SCHOOL",
      "location": "ADJOAFUA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "adjoafuacommshs@ges.gov.gh"
    },
    {
      "region": "WESTERN\r\nNORTH",
      "district": "BIBIANI/ANHWIASO/\r\nBEKWAI",
      "label": "BIBIANI SENIOR HIGH/TECH SCHOOL",
      "value": "BIBIANI SENIOR HIGH/TECH SCHOOL",
      "location": "BIBIANI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "bibianishst@ges.gov.gh"
    },
    {
      "region": "WESTERN\r\nNORTH",
      "district": "BIBIANI/ANHWIASO/\r\nBEKWAI",
      "label": "QUEENS GIRLS' SENIOR HIGH SCHOOL, SEFWI\r\nAWHIASO",
      "value": "QUEENS GIRLS' SENIOR HIGH SCHOOL, SEFWI\r\nAWHIASO",
      "location": "SEFWI\r\nANWHIASO",
      "gender": "FEMALE",
      "residency": "DAY/BOARDING",
      "email": "queensgirlsshs@ges.gov.gh"
    },
    {
      "region": "WESTERN\r\nNORTH",
      "district": "BIBIANI/ANHWIASO/\r\nBEKWAI",
      "label": "CHIRANO COMM. DAY SENIOR HIGH SCHOOL",
      "value": "CHIRANO COMM. DAY SENIOR HIGH SCHOOL",
      "location": "CHIRANO",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "chiranocommshs@ges.gov.gh"
    },
    {
      "region": "WESTERN\r\nNORTH",
      "district": "BIBIANI/ANHWIASO/\r\nBEKWAI",
      "label": "SEFWI BEKWAI SENIOR HIGH SCHOOL",
      "value": "SEFWI BEKWAI SENIOR HIGH SCHOOL",
      "location": "SEFWI BEKWAI",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "sefwibekwaishs@ges.gov.gh"
    },
    {
      "region": "WESTERN\r\nNORTH",
      "district": "BODI",
      "label": "BODI SENIOR HIGH SCHOOL",
      "value": "BODI SENIOR HIGH SCHOOL",
      "location": "BODI",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "bodishs@ges.gov.gh"
    },
    {
      "region": "WESTERN\r\nNORTH",
      "district": "JUABESO",
      "label": "JUABOSO SENIOR HIGH SCHOOL",
      "value": "JUABOSO SENIOR HIGH SCHOOL",
      "location": "JUABOSO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "juabososhs@ges.gov.gh"
    },
    {
      "region": "WESTERN\r\nNORTH",
      "district": "SEFWI AKONTOMBRA",
      "label": "NSAWORA EDUMAFA COMM. SENIOR HIGH SCHOOL",
      "value": "NSAWORA EDUMAFA COMM. SENIOR HIGH SCHOOL",
      "location": "NSAWORA\r\nEDUMAFA",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "nsaworaedumafacommshs@ges.gov.gh"
    },
    {
      "region": "WESTERN\r\nNORTH",
      "district": "SEFWI AKONTOMBRA",
      "label": "AKONTOMBRA SENIOR HIGH SCHOOL",
      "value": "AKONTOMBRA SENIOR HIGH SCHOOL",
      "location": "AKONTOMBRA",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "akontombrashs@ges.gov.gh"
    },
    {
      "region": "WESTERN\r\nNORTH",
      "district": "SEFWI WIAWSO",
      "label": "ASAWINSO SENIOR HIGH SCHOOL",
      "value": "ASAWINSO SENIOR HIGH SCHOOL",
      "location": "ASAWINSO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "asawinsoshs@ges.gov.gh"
    },
    {
      "region": "WESTERN\r\nNORTH",
      "district": "SEFWI WIAWSO",
      "label": "SEFWI-WIAWSO SENIOR HIGH SCHOOL",
      "value": "SEFWI-WIAWSO SENIOR HIGH SCHOOL",
      "location": "SEFWI-WIAWSO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "sefwiwiawsoshs@ges.gov.gh"
    },
    {
      "region": "WESTERN\r\nNORTH",
      "district": "SEFWI WIAWSO",
      "label": "SEFWI-WIAWSO SENIOR HIGH/TECH SCHOOL",
      "value": "SEFWI-WIAWSO SENIOR HIGH/TECH SCHOOL",
      "location": "SEFWI-WIAWSO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "sefwiwiawsoshst@ges.gov.gh"
    },
    {
      "region": "WESTERN\r\nNORTH",
      "district": "SEFWI WIAWSO",
      "label": "ST. JOSEPH SENIOR HIGH SCHOOL, SEFWI WIAWSO",
      "value": "ST. JOSEPH SENIOR HIGH SCHOOL, SEFWI WIAWSO",
      "location": "SEFWI WIAWSO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "stjosephshs@ges.gov.gh"
    },
    {
      "region": "WESTERN\r\nNORTH",
      "district": "SUAMAN",
      "label": "DADIESO SENIOR HIGH SCHOOL",
      "value": "DADIESO SENIOR HIGH SCHOOL",
      "location": "DADIESO",
      "gender": "MIXED",
      "residency": "DAY/BOARDING",
      "email": "dadiesoshs@ges.gov.gh"
    },
    {
      "region": "WESTERN\r\nNORTH",
      "district": "WASSA AMENFI CENTRAL",
      "label": "MANSO-AMENFI COMM. DAY SENIOR HIGH SCHOOL",
      "value": "MANSO-AMENFI COMM. DAY SENIOR HIGH SCHOOL",
      "location": "MANSO-AMENFI",
      "gender": "MIXED",
      "residency": "DAY",
      "email": "mansoamenficommshs@ges.gov.gh"
    },
    {
      "region": "OTHER",
      "district": "OTHER",
      "label": "OTHER",
      "value": "Other",
      "location": "OTHER",
      "gender": "OTHER",
      "residency": "OTHER",
      "email": "info@ges.gov.gh"
    }
  ],
  shs_courseItems: [
    { value: "General Science", label: "General Science" },
    { value: "Business", label: "Business" },
    { value: "General Arts", label: "General Arts" },
    { value: "Visual Arts", label: "Visual Arts" },
    { value: "Home Economics", label: "Home Economics" },
    { value: "General Agriculture", label: "General Agriculture" },
    { value: "Technical", label: "Technical" },
    { value: "Vocational", label: "Vocational" }
  ],
  exam_sitting_monthItems: [
    { value: "May/June", label: "May/June" },
    { value: "Nov/Dec", label: "Nov/Dec" },
  ],
  shs_qualificationItems: [
    { value: "wassce", description: "West African Senior School Certificate Examination", label: "WASSCE" },
    { value: "novdec", description: "West African Senior School Certificate Examination", label: "WASSCE (NOV/DEC)" },
    { value: "sssce", description: "Senior Secondary School Certificate Examination", label: "SSSCE" },
    { value: "sssce private", description: "Senior Secondary School Certificate Examination", label: "SSSCE (NOV/DEC)" },
    { value: "nabptex", description: "National Board for Professional and Technician Examinations", label: "CTVET/NABPTEX" },
    { value: "abce", description: "Advanced Business Certificate Examination", label: "ABCE" },
    { value: "abce private", description: "Advanced Business Certificate Examination", label: "ABCE (NOV/DEC)" },
    { value: "gbce", description: "General Business Certificate Examination", label: "GBCE" },
    { value: "gbce private", description: "General Business Certificate Examination", label: "GBCE (NOV/DEC)" },
    { value: "oalevel", description: "O-level and A-Level Examination", label: "O/A LEVEL" },
    { value: "other", description: "Exams type not listed (Foreign Student)", label: "OTHER" }
  ],
  tertiary_list: [
    {
      "value": "UNIVERSITY OF GHANA, LEGON",
      "label": "UNIVERSITY OF GHANA, LEGON"
    },
    {
      "value": "KWAME NKRUMAH UNIVERSITY OF SCIENCE AND TECHNOLOGY",
      "label": "KWAME NKRUMAH UNIVERSITY OF SCIENCE AND TECHNOLOGY"
    },
    {
      "value": "UNIVERSITY OF CAPE COAST",
      "label": "UNIVERSITY OF CAPE COAST"
    },
    {
      "value": "UNIVERSITY OF EDUCATION, WINNEBA",
      "label": "UNIVERSITY OF EDUCATION, WINNEBA"
    },
    {
      "value": "UNIVERSITY FOR DEVELOPMENT STUDIES",
      "label": "UNIVERSITY FOR DEVELOPMENT STUDIES"
    },
    {
      "value": "UNIVERSITY OF PROFESSIONAL STUDIES",
      "label": "UNIVERSITY OF PROFESSIONAL STUDIES"
    },
    {
      "value": "UNIVERSITY OF MINES AND TECHNOLOGY",
      "label": "UNIVERSITY OF MINES AND TECHNOLOGY"
    },
    {
      "value": "UNIVERSITY OF HEALTH AND ALLIED SCIENCES",
      "label": "UNIVERSITY OF HEALTH AND ALLIED SCIENCES"
    },
    {
      "value": "UNIVERSITY OF ENERGY AND NATURAL RESOURCES",
      "label": "UNIVERSITY OF ENERGY AND NATURAL RESOURCES"
    },
    {
      "value": "UNIVERSITY OF ENVIRONMENT AND SUSTAINABLE DEVELOPMENT",
      "label": "UNIVERSITY OF ENVIRONMENT AND SUSTAINABLE DEVELOPMENT"
    },
    {
      "value": "GHANA INSTITUTE OF MANAGEMENT AND PUBLIC ADMINISTRATION",
      "label": "GHANA INSTITUTE OF MANAGEMENT AND PUBLIC ADMINISTRATION"
    },
    {
      "value": "GHANA COMMUNICATION TECHNOLOGY UNIVERSITY",
      "label": "GHANA COMMUNICATION TECHNOLOGY UNIVERSITY"
    },
    {
      "value": "ACCRA TECHNICAL UNIVERSITY",
      "label": "ACCRA TECHNICAL UNIVERSITY"
    },
    {
      "value": "BOLGATANGA TECHNICAL UNIVERSITY",
      "label": "BOLGATANGA TECHNICAL UNIVERSITY"
    },
    {
      "value": "CAPE COAST TECHNICAL UNIVERSITY",
      "label": "CAPE COAST TECHNICAL UNIVERSITY"
    },
    {
      "value": "KUMASI TECHNICAL UNIVERSITY",
      "label": "KUMASI TECHNICAL UNIVERSITY"
    },
    {
      "value": "KOFORIDUA TECHNICAL UNIVERSITY",
      "label": "KOFORIDUA TECHNICAL UNIVERSITY"
    },
    {
      "value": "TAMALE TECHNICAL UNIVERSITY",
      "label": "TAMALE TECHNICAL UNIVERSITY"
    },
    {
      "value": "HO TECHNICAL UNIVERSITY",
      "label": "HO TECHNICAL UNIVERSITY"
    },
    {
      "value": "TAKORADI TECHNICAL UNIVERSITY",
      "label": "TAKORADI TECHNICAL UNIVERSITY"
    },
    {
      "value": "SUNYANI TECHNICAL UNIVERSITY",
      "label": "SUNYANI TECHNICAL UNIVERSITY"
    },
    {
      "value": "WA TECHNICAL UNIVERSITY",
      "label": "WA TECHNICAL UNIVERSITY"
    },
    {
      "value": "OTHER",
      "label": "OTHER"
    }
  ],
  tertiary_reason_for_leavingItems: [
    { value: "Completed", label: "Completed" },
    { value: "Withdrawal", label: "Withdrawal" },
    { value: "Other", label: "Other" }
  ],
  tertiary_degree_list: [
    { "value": "BSc", "label": "BSc" },
    { "value": "BA", "label": "BA" },
    { "value": "MSc", "label": "MSc" },
    { "value": "MA", "label": "MA" },
    { "value": "BFA", "label": "BFA" },
    { "value": "BMus", "label": "BMus" },
    { "value": "BSc Admin", "label": "BSc Admin" },
    { "value": "MBA", "label": "MBA" },
    { "value": "MPhil", "label": "MPhil" },
    { "value": "BEd", "label": "BEd" },
    { "value": "PGDE", "label": "PGDE" },
    { "value": "B.Com", "label": "B.Com" },
    { "value": "Bachelor of Divinity", "label": "Bachelor of Divinity" },
    { "value": "Post Graduate Diploma", "label": "Post Graduate Diploma" },
    { "value": "HND", "label": "HND" },
    { "value": "MPA", "label": "MPA" },
    { "value": "MPH", "label": "MPH" },
    { "value": "Diploma", "label": "Diploma" },
    { "value": "B.Pharm", "label": "B.Pharm" },
    { "value": "D.Pharm", "label": "D.Pharm" },
    { "value": "LLB", "label": "LLB" },
    { "value": "LLM", "label": "LLM" },
    { "value": "MBChB", "label": "MBChB" },
    { "value": "Other", "label": "Other" }
  ],
  tertiary_degree_classItems: [
    { value: "Distinction", label: "Distinction" },
    { value: "Pass with Distinction", label: "Pass with Distinction" },
    { value: "First Class", label: "First Class" },
    { value: "Second Class Upper", label: "Second Class Upper" },
    { value: "Second Class Lower", label: "Second Class Lower" },
    { value: "Third Class", label: "Third Class" },
    { value: "Merit", label: "Merit" },
    { value: "Pass", label: "Pass" },
    { value: "Fail", label: "Fail" },
    { value: "Other", label: "Other" },
  ],
  entryLevelItems: [
    { value: "Degree/Diploma", label: "Degree/Diploma" },
    { value: "Topup", label: "Topup" },
    { value: "Mature", label: "Mature" },
    { value: "Masters", label: "Master's" },
    { value: "PhD", label: "PhD" },
  ],
  admissionLetterKeys: [
    { key: "{applicationRef}", description: "Application ID" },
    { key: "{nameOfInstitution}", description: "Name of Institution" },
    { key: "{addressOfInstitution}", description: "Address of Institution" },
    { key: "{nameOfApplicant}", description: "Applicant's Name" },
    { key: "{programmeTitle}", description: "Programme Offer" },
    { key: "{academicYear}", description: "Academic Year" },
    { key: "{academicMonth}", description: "Academic Month" },
    { key: "{programmeDuration}", description: "Programme Duration" },
    { key: "{awardingInstitution}", description: "Awarding Institution" },
    { key: "{programmeYears}", description: "Programme Duration" },
    { key: "{startingLevel}", description: "Starting Level" },
    { key: "{session}", description: "Session" },
    { key: "{addressOfApplicant}", description: "Applicant Address" },
    { key: "{emailOfApplicant}", description: "Applicant Email Address" },
    { key: "{dateOfSubmission}", description: "Date of Application Submission" },
    { key: "{institutionWebsite}", description: "Institution Website" },
    { key: "{primaryPhoneNumber}", description: "Primary Phone Number" },
    { key: "{telephone}", description: "Institution Telephone" },
    { key: "{signature}", description: "Signature of Admissions Officer" },
    { key: "{name}", description: "Name of Admissions Officer" },
    { key: "{date}", description: "Date of Issuing Letter" },
    { key: "{programmeFeesCurrency}", description: "Fees Currency" },
    { key: "{programmeFeesAmount}", description: "Fees Amount" },
    { key: "{feesChargesCurrency}", description: "Charges Currency" },
    { key: "{feesChargesAmount}", description: " Charges Amount" },
  ],
  workingExperienceDurationItems: [
    { value: "None", label: "None" },
    { value: "Less than a year", label: "Less than a year" },
    { value: "1 year", label: "1 year" },
    { value: "2 years", label: "2 years" },
    { value: "3 years", label: "3 years" },
    { value: "More than 3 years", label: "More than 3 years" },
  ],

  exportFormats: {
    print: {
      label: 'Print',
      icon: 'pi pi-print',
      type: 'print',
      ext: 'print',
    },
    pdf: {
      label: 'Pdf',

      icon: 'pi pi-file-pdf',
      type: 'pdf',
      ext: 'pdf',
    },
    excel: {
      label: 'Excel',
      icon: 'pi pi-file-excel',
      type: 'excel',
      ext: 'xlsx',
    },
    csv: {
      label: 'Csv',
      icon: 'pi pi-table',
      type: 'csv',
      ext: 'csv',
    },
  },

}