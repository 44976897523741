<template>
    <template v-for="(filter, key) in filters" :key="key" class="container flex flex-wrap ">
        <div v-if="filterHasValue(filter)" :class="tagClass" class="">
            {{ filter.tag || key }}

            <Chip v-if="isMultipleFilter(filter)" v-for="val in filter.value" :key="`filtertag-${val}`" :class="chipClass"
            :removable="canRemove(filter.tag || key)" @remove="removeFilter(filter, val)" :label="getFilterLabel(filter, val)" />

            <Chip v-else :class="chipClass" :removable="canRemove(filter.tag || key)" @remove="removeFilter(filter)" :label="getFilterLabel(filter)" />
        </div>
    </template>
   
</template>

<script setup>
const props = defineProps({
    controller: {
        type: Object,
        default: () => { }
    },
    chipClass: {
        type: String,
        default: 'bg-primary-100 font-medium text-black',
    },
    tagClass: {
        type: String,
        default: 'px-3 py-2  m-1 mb-3 card',
    }
});
function isMultipleFilter(filter) {
    return (filter.valueType === 'multiple' || filter.valueType === 'multiple-date');
}

function canRemove(tag){
    const tags = ['Payments From','Payments To']
    if(tags.includes(tag)){
        return false;
    }else {
        return true;
    }
}

const { filterHasValue, getFilterLabel, removeFilter, filters } = props.controller
</script>