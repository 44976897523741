import { defineStore } from 'pinia';
import { ApiService } from 'src/services/api';
import { reactive } from 'vue';

export const useCohortStore = defineStore('cohort', () => {
	const state = reactive({
		currentApplicationYear: null,
		currentApplicationMonth: null,
		pgcurrentApplicationYear: null,
		pgcurrentApplicationMonth: null,
		currentAcademicYear: null,
		currentAcademicSemester: null,
    learningInstitution: null,
    pglearningInstitution: null
	});

	async function getCurrentAcademicYear() {
		try {
			let response = await ApiService.get(
				'components_data/active_academic_year'
			);
			let data = response.data;
			if (data) {
				state.currentAcademicYear = data.name;
				state.currentAcademicSemester = data.semester;
			}
		} catch (err) {
			throw err;
		}
	}

	async function getCurrentAdmissionCohort() {
		try {
			let response = await ApiService.get('components_data/active_appconfig');
			let data = response.data;
			if (data) {
				state.currentApplicationYear = data.academic_year;
				state.currentApplicationMonth = data.academic_month;
        state.learningInstitution = data.learning_institution
			}
		} catch (err) {
			throw err;
		}
	}

	async function getpgCurrentAdmissionCohort() {
		try {
			let response = await ApiService.get('components_data/pgactive_appconfig');
			let data = response.data;
			if (data) {
				state.pgcurrentApplicationYear = data.academic_year;
				state.pgcurrentApplicationMonth = data.academic_month;
        state.pglearningInstitution = data.learning_institution
			}
		} catch (err) {
			throw err;
		}
	}

	return {
		state,
		getCurrentAcademicYear,
		getCurrentAdmissionCohort,
		getpgCurrentAdmissionCohort,
	};
});
