<template>
    <div :class="containerClass" @click="onWrapperClick">
        <template v-if="auth.isLoggedIn">
            <div class="layout-topbar bg-primary">
                <div class="layout-toggle-menu">
                    <Button class="p-button-sm" @click="onMenuToggle">
                        <i class="pi pi-bars"></i>
                        <div class="ml-2 text-md font-medium">Menu</div>
                    </Button>
                </div>
                <div class="layout-topbar-menu flex-grow-1 justify-content-between">
                    <div class="flex">
                        <template v-for="(item, index) of navbarTopLeftItems" :key="`navtopleftmenu-${index}`">
                            <router-link :to="item.to">
                                <Button :label="item.label" :icon="item.icon" class="p-button-sm text-white" />
                            </router-link>
                        </template>
                    </div>

                    <div class="flex justify-content-end">
                        <template v-for="(item, index) of navbarTopRightItems" :key="`navtopleftmenu-${index}`">
                            <router-link :to="item.to">
                                <Button :label="item.label" :icon="item.icon" class="p-button-sm text-white" />
                            </router-link>
                        </template>

                        <Button @click="startLogout()" label="Sign Out" class="ml-1 px-4 p-button-sm"
                            icon="pi pi-power-off"></Button>
                    </div>

                    <!-- <SplitButton class="layout-menu-user-button" :model="appBarActionMenu"></SplitButton> -->
                </div>
            </div>
            <div class="layout-sidebar   " @click="onSidebarClick">
                <div class="text-center p-2">
                    <img src="images/logo.png" alt="logo" class="my-1" />
                </div>
                <AppMenu :model="navbarSideLeftItems" @menuitem-click="onMenuItemClick" />
            </div>
        </template>
        <template v-else>
            <div class="layout-topbar bg-primary text-white shadow-7"></div>
        </template>

        <div class="layout-main-container" :style="!auth.isLoggedIn
            ? `background-image:linear-gradient(357deg, rgb(255, 254, 254), rgb(255 255 255 / 55%)), url('images/bg.png'); background-size: cover;`
            : ``">
            <div class="layout-main">
                <router-view />
            </div>
            <div class="layout-footer gap-3 flex justify-content-center align-items-center">
                <div class="mt-5">
                    <!-- <div class="flex text-sm align-items-center justify-content-center">
                        <div class=" text-center mr-3">
                            <a target="_blank" href="https://apply.gctu.edu.gh/">
                                <div class="">
                                    <div class="font-medium text-md">Online Apply</div>
                                </div>
                            </a>
                        </div>
                        <div class=" text-center mr-3">
                            <a target="_blank" href="https://gctuasset.afrifanom.com/">
                                <div class="">
                                    <div class="font-medium text-md text-primary-500">Asset Management</div>
                                </div>
                            </a>
                        </div>
                        <div class=" text-center mr-3">
                            <a target="_blank" href="https://gctuhr.afrifanom.com/">
                                <div class="">
                                    <div class="font-medium text-md text-primary-500">HRIS</div>
                                </div>
                            </a>
                        </div>
                        <div class=" text-center mr-3">
                            <a target="_blank" href="https://gctuhostel.afrifanom.com/">
                                <div class="">
                                    <div class="font-medium text-md text-primary-500">Hostel Management</div>
                                </div>
                            </a>
                        </div>
                    </div> -->
                    <div class="mt-2">&copy; 2022 {{ $appName }}. All Rights Reserved</div>
                </div>
            </div>

        </div>
        <!-- App right drawer -->
        <Sidebar :showCloseIcon="rightDrawer.closeBtn" position="right" v-model:visible="rightDrawer.showDrawer"
            :style="{ width: rightDrawer.width }">
            <component v-if="rightDrawer.showDrawer" is-sub-page :is="drawerComponentFile" :api-path="rightDrawer.pageUrl"
                :page-data="rightDrawer.pageData" />
        </Sidebar>

        <transition name="layout-mask">
            <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
        </transition>

        <!-- Page display dialog -->
        <Dialog class="card pt-5 px-0" :breakpoints="{ '960px': '50vw', '640px': '95vw' }"
            :style="{ width: pageDialog.width, maxWidth: pageDialog.maxWidth }" :modal="!pageDialog.seamless"
            :maximized="pageDialog.maximized" :dismissableMask="!pageDialog.persistent" :position="pageDialog.position"
            v-model:visible="pageDialog.showDialog" :showHeader="false">
            <Button v-if="pageDialog.closeBtn" @click="pageDialog.showDialog = false"
                style="position:absolute;right:0px;top:0px" icon="pi pi-times"
                class="p-button-rounded p-button-text p-button-plain" />

            <component v-if="pageDialog.showDialog" is-sub-page :is="dialogComponentFile" :api-path="pageDialog.pageUrl"
                :page-data="pageDialog.pageData" />
        </Dialog>

        <!-- image preview dialog-->
        <Dialog class="card p-0" header="..." :showHeader="true" :breakpoints="{ '960px': '40vw', '640px': '95vw' }"
            style="width:auto" v-model:visible="imageDialog.showDialog" dismissableMask modal>
            <div class="text-center">
                <Carousel v-if="imageDialog.images.length > 1" :value="imageDialog.images" :circular="false"
                    :page="imageDialog.currentSlide">
                    <template #item="{ data }">
                        <div class="text-center">
                            <NiceImage style="max-width:100%;max-height:70vh" :src="data" />
                        </div>
                    </template>
                </Carousel>
                <NiceImage v-else style="max-width:100%;max-height:70vh" :src="imageDialog.images[0]" />
            </div>
        </Dialog>

        <!-- pdf preview dialog-->
        <Dialog header="&nbsp;" style="width: 50vw" v-model:visible="pdfViewerDialog.showDialog" dismissableMask modal>
            <div>
                <div class="text-center mb-5">
                    <Button class="p-button-primary p-button-outlined" icon="pi pi-refresh" label="Rotate Document"
                        v-if="!pdfViewerDialogMessage" @click="pdfViewerRotation()" />
                </div>
                <div class="text-center text-2xl my-5" v-if="pdfViewerDialogMessage">
                    {{ pdfViewerDialogMessage }}
                </div>
                <vue-pdf-embed :source="pdfViewerDialog.pdfFile" :rotation="pdfViewerDialogRotate"
                    @loading-failed="pdfViewerMessage('Unable to load document')"
                    @loaded="pdfViewerMessage('Loading document ... 0%')"
                    @rendering-failed="pdfViewerMessage('Unable to load document')" @rendered="pdfViewerMessage('')"
                    @progress="pdfViewerMessage('Loading document ... 50%')" />
                <div class="text-center mt-3">
                    <a style="text-decoration: none" class="btn font-bold text-green-500" target="_blank"
                        :href="$utils.setFileUrl(pdfViewerDialog.pdfFile)">Click to download document
                    </a>
                </div>
            </div>
        </Dialog>

        <!-- request error dialog -->
        <Dialog class="card p-0" modal v-model:visible="errorDialog" :breakpoints="{ '960px': '50vw', '640px': '95vw' }"
            style="width:30vw;" position="top">
            <template #header>
                <div class="align-items-center">
                    <div class="mr-2">
                        <Avatar size="large" class="bg-pink-100 text-pink-600" icon="pi pi-exclamation-triangle" />
                    </div>
                </div>
            </template>
            <div>
                <div class="text-lg text-pink-600 font-medium" v-for="(msg, index) in pageErrors" :key="index">
                    {{ msg }}
                </div>
            </div>

        </Dialog>

        <!-- app confirm page dialog -->
        <ConfirmDialog></ConfirmDialog>

        <!-- app flash message toast -->
        <Toast position="top-center" />

        <!-- app fullscreen loading dialog -->
        <Dialog class="card m-0" :showHeader="false" modal v-model:visible="fullScreenLoading"
            :breakpoints="{ '960px': '50vw', '640px': '95vw' }" style="width:20vw;" position="center">
            <ProgressBar mode="indeterminate" style="height: 6px"></ProgressBar>
            <!-- <div class="mt-2 text-center text-500">{{ fullScreenLoadingMsg }}</div> -->
        </Dialog>
    </div>
</template>
<script setup>
import AppMenu from 'src/components/AppMenu.vue';
import { useApp } from 'src/composables/app';
import { useAuth } from 'src/composables/auth';
import { computed, defineAsyncComponent, ref, toRefs } from "vue";
import VuePdfEmbed from "vue-pdf-embed";
import { useRoute, useRouter } from 'vue-router';


import { useAppStore } from 'src/store/app';

const app = useApp();
const auth = useAuth();
const store = useAppStore();
//const route = useRoute();
//const router = useRouter();

const { pageDialog, imageDialog, pdfViewerDialog, rightDrawer, pageErrors, fullScreenLoading, fullScreenLoadingMsg } = toRefs(store);
const dialogComponentFile = computed(() => {
    if (pageDialog.value.showDialog && pageDialog.value.pageComponent) {
        return defineAsyncComponent(() => import(`src/pages/${pageDialog.value.pageComponent}.vue`));
    }
    return null;
});

const drawerComponentFile = computed(() => {
    if (rightDrawer.value.showDrawer && rightDrawer.value.pageComponent) {
        return defineAsyncComponent(() => import(`src/pages/${rightDrawer.value.pageComponent}.vue`));
    }
    return null;
});

const errorDialog = computed({
    get() {
        return pageErrors.value.length > 0;
    },
    set(newValue) {
        pageErrors.value = [];
    }
});
const layoutMode = ref('static');
const menuClick = ref(false);
const staticMenuInactive = ref(false);
const overlayMenuActive = ref(false);
const mobileMenuActive = ref(false);
const pdfViewerDialogMessage = ref('Loading');
const pdfViewerDialogRotate = ref(0);

const containerClass = computed(() => {
    if (!auth.isLoggedIn) {
        staticMenuInactive.value = true;
        mobileMenuActive.value = false;
    }

    return ['layout-wrapper', {
        'layout-overlay': layoutMode.value === 'overlay',
        'layout-static': layoutMode.value === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive.value && layoutMode.value === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive.value && layoutMode.value === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive.value,
        'p-input-filled': false,
    }];
});
const navbarSideLeftItems = app.menus.navbarSideLeft.filter((menu) => auth.canView(menu.to || menu.target));
const navbarTopLeftItems = app.menus.navbarTopLeft.filter((menu) => auth.canView(menu.to));
const navbarTopRightItems = app.menus.navbarTopRight.filter((menu) => auth.canView(menu.to));
function onMenuItemClick(event) {
    if (event.item && !event.item.items) {
        overlayMenuActive.value = false;
        mobileMenuActive.value = false;
    }
}

function onWrapperClick() {
    // if (!menuClick.value) {
    //     overlayMenuActive.value = false;
    //     mobileMenuActive.value = false;
    // }
    // menuClick.value = false;
}

function onMenuToggle() {
    menuClick.value = true;
    if (app.isDesktop()) {
        if (layoutMode.value === 'overlay') {
            if (mobileMenuActive.value === true) {
                overlayMenuActive.value = true;
            }
            overlayMenuActive.value = !overlayMenuActive.value;
            mobileMenuActive.value = false;
        }
        else if (layoutMode.value === 'static') {
            staticMenuInactive.value = !staticMenuInactive.value;
        }
    }
    else {
        mobileMenuActive.value = !mobileMenuActive.value;
    }
}

function pdfViewerMessage(message) {
    return (pdfViewerDialogMessage.value = message);
}
function pdfViewerRotation(number) {
    if (pdfViewerDialogRotate.value >= 270) {
        return pdfViewerDialogRotate.value = 0;
    }
    return (pdfViewerDialogRotate.value = pdfViewerDialogRotate.value + 90);
}

const appBarActionMenu = [
    {
        to: '/account',
        label: "My Account",
        icon: 'pi pi-user',
    },
    {
        label: "Logout",
        icon: 'pi pi-window-minimize',
        command: (event) => {
            startLogout();
        },
    },
];
function startLogout() {
    auth.logout();
    location.href = "/"; //reload page and navigated to index page
}

</script>