<template>
	<span>
		<slot :dialog="dialog" :response="response"></slot>
		<Dialog :header="header" v-model:visible="dialog" position="top" :breakpoints="{ '960px': '50vw', '640px': '95vw' }"
			style="width:40vw">
			<div>
				<Uploader @uploadError="importFail" @uploadComplete="importComplete" mode="basic" :label="label"
					:upload-path="uploadPath" accept=".xlsx" field-name="import" v-model="response"
					:showUploadedFiles="false" />
			</div>
			<div class="text-center" v-if="templateUrl">
				<a target="_blank" :href="templateUrl">
					<Button label="Please click here to download Template for upload" class="p-button-md p-button-link text-blue px-4" icon="pi pi-link"></Button>
				</a>
			</div>
		</Dialog>
	</span>
</template>

<script>
export default {
	name: "ImportData",
	props: {
		uploadPath: {
			type: String,
			default: "filehelper/uploadfile",
		},
		label: {
			type: String,
			default: "Choose file to import",
		},
		header: {
			type: String,
			default: "Import Data",
		},
		templateUrl: [String]
	},
	data: () => ({
		dialog: false,
		response: "",
	}),
	methods: {
		openDialog() {
			this.dialog = true;
		},
		importFail(val) {
			this.$toast.add({ severity: "error", summary: "Import Data Failed", detail: val, life: 3000 });
		},
		importComplete(result) {
			this.dialog = false;
			this.$emit('importComplete', result);
		}
	}
};
</script>